.custom-filter-wrapper {
  min-width: 354px;
  height: 465px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.08);
  background-color: #ffffff;
  position: relative;
  overflow: auto;
  .inner-wrapper {
    margin: 37px 24px 0px;
    .year-section {
      height: 72px;
      width: 199px;
      margin-bottom: 24px;
      .section-items-row {
        display: flex;
        justify-content: space-between;
      }
    }
    .month-section {
      height: 240px;
    }
    .section-label {
      margin-bottom: 16px;
      font-size: 14px;
      font-weight: 500;
      line-height: 1.14;
      letter-spacing: 0.4px;
      color: #000000;
      text-transform: uppercase;
    }
    .section-item {
      &.MuiButton-root {
        width: 91px;
        height: 40px;
        border-radius: 8px;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0.4px;
      }
    }
    .section-items-wrapper {
      display: flex;
      height: 208px;
      flex-direction: column;
      justify-content: space-between;
      .section-items-row {
        display: flex;
        justify-content: space-between;
      }
    }
  }
  .clear-all-button {
    position: absolute;
    right: 23px;
    top: 32px;
    &.MuiButton-root {
      padding: 0px;
      text-transform: none;
      .MuiButton-label {
        font-size: 14px;
        letter-spacing: 0.5px;
      }
    }
  }
  .apply-button {
    width: 100%;
    height: 60px;
    position: absolute;
    bottom: 0;
    background-color: #fff;
    border-top: 1px solid #e9e9e9;
    &.MuiButtonBase-root {
      border-radius: 0;
      .MuiButton-label {
        letter-spacing: 0.5px;
      }
    }
  }
}
