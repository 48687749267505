// Common Classes
.marketing_calendar_page {
  .page_title_container {
    .right_container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .filter_icon_container {
        margin-right: 16px;
        &:last-child {
          margin-right: 0px;
        }

        &.disabled {
          background-color: #d8d8dc;
        }
      }
    }
  }
  //Marketing Calendar Dashboard Page
  .marketing-calendar-dashboard {
    .page-subheader {
      width: 100%;
      height: 50px;
      display: flex;
      align-items: center;
      border-bottom: solid 1px #dddddd;

      .inner-wrapper {
        justify-content: center;
        position: relative;
        .left-panel,
        .right-panel {
          display: flex;
          align-items: center;
          position: absolute;
          left: 0px;
        }
        .right-panel {
          right: 0px;
        }
        .page-title {
          font-size: 18px;
          font-weight: 700;
        }
        .dropdown-wrapper {
          display: flex;
          align-items: center;
          position: relative;
          .label-text {
            font-size: 14px;
            color: #666666;
          }
          .dropdown-input {
            height: 30px;
            width: 150px;
            border: 1px solid #ddd;
            border-radius: 4px;
            margin: 0px 10px;
            padding: 2px 10px;
            padding-right: 40px;
            position: relative;
            display: flex;
            align-items: center;
            cursor: pointer;
            .caret-icon {
              position: absolute;
              right: 10px;
            }
          }
          .dropdown-list-wrapper {
            width: 89%;
            background-color: #fff;
            position: absolute;
            top: 30px;
            left: 8px;
            background-color: #fff;
            border: 1px solid #ccc;
            border: 1px solid rgba(0, 0, 0, 0.15);
            border-radius: 4px;
            // box-shadow: 0 6px 12px rgba(0 0 0 / 18%);
            background-clip: padding-box;
            padding: 10px 15px;
            z-index: 2;
            .list-item {
              width: 100%;
              height: 30px;
              line-height: 30px;
              font-size: 12px;
              font-weight: 400;
              color: #333333;
              cursor: pointer;
            }
          }
        }
      }
    }
  }

  // Calendar Custom View with Table View
  .calendar-main-view-container {
    padding-right: 56px;
    .table-wrapper {
      width: 100%;
      overflow: hidden;
      .header-row,
      .category-row {
        height: 60px;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.203562);
        // display: flex;
        &.holiday-row {
          height: 60px;
          // box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
        }
        &.holiday-row-max-height {
          height: 85px;
          // box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
        }
        .col,
        .category-col {
          // display: flex;
          // flex-grow: 1;
          text-align: center;
          // align-items: center;
          // justify-content: center;
          height: 100%;
          background-color: #ffffff;
          color: #000000;
          font-size: 14px;
          font-weight: 500;
          // text-transform: uppercase;
          vertical-align: middle;
          // border-right: 1px solid #B6B6B6;
          // border-bottom: 1px solid #B6B6B6;
          border: 1px solid #b6b6b6;

          &:first-child {
            border-left: none;
            // border: none;
          }
          &:last-child {
            border-right: none;
          }
          &.holiday-col {
            position: relative;
            background-color: #fff;
            height: 60px;
            border-bottom: 1px solid #ababab;
            box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
            font-size: 12px;
            color: #646f79;
            font-weight: 700;
            padding: 0 10px;
            &:first-child {
              background-color: #f6f6fa;
            }
          }
        }
        .category-col {
          // flex-grow: unset;
          // width: 100px;
          // background: rgba(21, 147, 231, 0.1);
          background-color: #f6f6fa;
          font-size: 12px;
          font-weight: 300;
          z-index: 2;
          &:first-child {
            text-transform: uppercase;
          }

          .category-details {
            text-align: center;
            margin: auto;
            .category-icon {
              height: 44px;
              width: 40px;
              margin: auto;
              margin-bottom: 8px;
              img {
                height: 100%;
                width: 100%;
                object-fit: contain;
              }
            }
            .name {
              font-size: 12px;
              color: #646f79;
              font-weight: 700;
              // text-transform: uppercase;
            }
            // Corporate View Brand Style
            &.brand-details {
              text-align: center;
              margin: auto;
              .brand-icon {
                max-height: 90px;
                width: 95%;
                margin: auto;
                margin-bottom: 8px;
                img {
                  height: 100%;
                  width: 100%;
                  object-fit: contain;
                }
              }
              .name {
                font-size: 18px;
                font-weight: 500;

                color: #00436d;
                text-transform: uppercase;
                @include media_query(L) {
                  font-size: 16px;
                }
                @include media_query(M) {
                  font-size: 12px;
                }
              }
            }
          }
        }
      }
      .header-row {
        text-transform: uppercase;
      }
      .header-row {
        .col,
        .category-col {
          border-top: 0px;
          font-size: 16px;
          font-weight: 500;
          height: 60px;
          @include media_query(M) {
            font-size: 12px;
          }
        }

        .category-col {
          background-color: #dbecf8;
          color: #00436d;
          font-weight: 500;
          border: none;
          box-shadow: 0px 4px 4px 1px rgba(0, 0, 0, 0.15);
        }
      }
      .body-wrapper {
        overflow: hidden;
        .category-row {
          height: 205px;
          // .holiday-line-container {
          //   // width: 100vw;
          //   width: 100%;
          //   height: 1px;
          //   background-color: #50a9b5;
          //   position: absolute;
          //   top: 20px;
          //   left: 0px;
          //   right: 0px;
          //   // z-index: 999;
          //   .holiday-capsule {
          //     position: absolute;
          //     top: -10px;
          //     padding: 2px 10px;
          //     background: #eaf6f8;
          //     border: 1px solid #50a9b5;
          //     color: #787878;
          //     font-size: 11px;
          //     font-weight: 500;
          //     border-radius: 40px;
          //     word-break: break-all;
          //   }
          // }
          .col,
          .category-col {
            background-color: #fff;
            position: relative;
            height: 205px;
            .expand-icon-area {
              position: absolute;
              right: 10px;
              bottom: 10px;
              cursor: pointer;
              // .expand-icon {
              //   height: 14px;
              //   width: 14px;
              // }
              .expand-icon {
                height: 14px;
                width: 14px;
                background-repeat: no-repeat;
                background-size: contain;
                display: inline-block;
                margin-left: 15px;
                cursor: pointer;
                background-image: url('/icons/icon-expand.svg');
                margin: 0px 10px;
              }
              .align-middle {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                margin: auto;
              }
            }
          }
          .category-col {
            background-color: #f6f6fa;
            color: #00436d;
            font-weight: 500;
            text-transform: uppercase;
          }
        }
      }
    }
    .error_state_container {
      position: absolute;
    }
    @include media_query(M) {
      padding-right: 0px;
    }
  }

  // Program Details Card with variation
  .program-details-card {
    // width: 325px;
    height: 130px;
    padding: 0px 10px;
    // padding-bottom: 0px;
    background: #ffffff;
    border-radius: 4px 8px 8px 4px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
    position: absolute;
    top: 10px;
    border-left: 3px solid #50a9b5;
    margin-bottom: 10px;
    cursor: pointer;
    z-index: 1;
    &.active {
      border-left: 5px solid #62b536;
    }
    &.in-active {
      border-left: 5px solid #f7a2a4;
    }
    // The program is going to start in future (Current + . . . )
    &.future {
      border-left: 5px solid #fbba50;
    }
    &.first-row-card {
      .middle-section {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding-bottom: 20px;
      }
    }

    .content-wrapper {
      height: 100%;
      display: flex;
      position: relative;
      .notification-area {
        position: absolute;
        top: 10px;
        right: 0px;
        cursor: pointer;
        .notification-icon {
          width: 13px;
          height: 15px;
          background-image: url('/icons/notification-icon.svg');
          position: relative;
          .count {
            width: 6px;
            height: 6px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            background-color: #00436d;
            position: absolute;
            right: 0px;
            top: 1px;
            color: #fff;
            font-size: 3px;
            text-align: center;
          }
        }
      }

      .img-area {
        width: 50px;
        height: 50px;
        margin-right: 15px;
        img {
          height: 100%;
          width: 100%;
        }
      }
      .info-area {
        width: calc(100% - 75px);
        text-align: left;
        .dot {
          width: 2px;
          height: 2px;
          background-color: #646f79;
          margin: 0px 5px;
        }
        .prog-id-status {
          display: flex;
          align-items: center;
          font-size: 12px;
          color: #646f79;
          margin-bottom: 2px;
          text-transform: uppercase;
          .status {
            text-transform: capitalize;
          }
        }
        .prog-name {
          font-size: 15px;
          font-weight: bold;
          // color: #646f79;
          color: #212121;
          margin-bottom: 4px;
          max-height: 45px;
          overflow-y: hidden;
          text-transform: capitalize;
          @include media_query(M) {
            max-height: none;
            font-size: 12px;
            margin-bottom: 0px;
          }
        }
        .region-holiday-wrapper {
          display: flex;
          align-items: center;
          font-size: 12px;
          max-height: 14px;
          overflow: hidden;
          .region-names {
            color: #0a7908;
            text-transform: uppercase;
          }
          .holiday {
            color: #646f79;
            font-weight: bold;
            text-transform: capitalize;
          }
        }
        .region-holiday-wrapper {
          display: flex;
          align-items: center;
          font-size: 10px;
          max-height: 14px;
          overflow: hidden;
          .region-names-container {
            display: flex;
            align-items: center;

            text-transform: uppercase;
            .region {
              &.in-active {
                color: #fb4e4e;
                text-decoration: line-through;
              }
              &.active {
                color: #0a7908;
              }
            }
            .seperator_line_icon {
              width: 1px;
              height: 10px;
              margin: 0px 4px;
              background-image: url('/icons/icon-seperator-line.svg');
              color: #646f79;
              // padding: 5px;
            }
          }
          .holiday {
            color: #646f79;
            font-weight: bold;
            text-transform: capitalize;
          }
        }
      }
      .sticky-bottom-details {
        width: 100%;
        position: absolute;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-top: 1px solid #ddd;
        padding: 4px;
        text-transform: initial;
        .item-details {
          display: flex;
          .lbl,
          .value {
            font-size: 12px;
            color: #9e9e9e;
          }
          .value {
            font-weight: 700;
          }
        }
      }
    }
    // Medium Info Card for Image and Less Text
    &.medium {
      height: 80px;
      padding-top: 0px;
      top: 10px;
      .content-wrapper {
        align-items: center;
        .notification-area,
        .sticky-bottom-details {
          display: none;
        }
        .info-area {
          .prog-id-status {
            display: none;
          }
        }
      }
      &:nth-child(even) {
        top: 100px;
      }
    }
    // Small Info Card For Text Only
    &.small {
      // width: auto;
      height: 30px;
      padding-top: 0px;
      // position: relative;
      .content-wrapper {
        align-items: center;
        .img-area,
        .notification-area,
        .sticky-bottom-details {
          display: none;
        }
        .info-area {
          width: 100%;
          .prog-name {
            max-width: 130px;
          }
        }
      }
      &:nth-child(2) {
        top: 55px;
      }
      &:nth-child(3) {
        top: 100px;
      }
      &:nth-child(4) {
        top: 145px;
      }
    }
    @include media_query(M) {
      height: auto;
      padding: 8px 15px;
      background: rgba(0, 67, 109, 0.1);
      border: 0.5px solid #00436d;
      box-sizing: border-box;
      border-radius: 6px;
      font-size: 12px;
      line-height: 14px;
      text-align: center;
      text-transform: capitalize;

      color: #00436d;
      .content-wrapper {
        .middle-section {
          padding-bottom: 0px;
          .img-area {
            display: none;
          }
          .info-area {
            width: 100%;
            .prog-id-status {
              display: none;
            }
            .region-holiday-wrapper {
              display: none;
            }
          }
        }
        .sticky-bottom-details {
          display: none;
        }
      }
      &.medium {
        height: auto;
        padding: 8px 15px;
        .content-wrapper {
          .img-area {
            display: none;
          }
          .info-area {
            width: 100%;
            .prog-id-status {
              display: none;
            }
            .region-holiday-wrapper {
              display: none;
            }
          }
        }
      }
      &.small {
        height: auto;
        padding: 8px 15px;
      }
    }
    &.align_middle {
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
    }
  }

  // Program Details Page
  .program-details-page-wrapper {
    .page-subheader {
      background-color: #fff;
      border-bottom: 1px solid #ddd;
      border-top-right-radius: 4px;
      border-top-left-radius: 4px;
    }
    .form_fields_container {
      border-radius: 4px;
      border-top: 0px;
      .form_header {
        padding: 20px 25px;
        .action_btn_wrapper {
          cursor: pointer;
          .add-attachment-text {
            font-size: 14px;
            font-weight: 500;
            color: #00436d;
            margin-left: 10px;
          }
        }
      }
      .tab-view-wrapper {
        margin-bottom: 10px;
      }
    }
    .white_box {
      padding: 0px;
      box-shadow: 0px -8px 16px rgba(42, 42, 68, 0.1);
      border-radius: 4px;
    }
    .program-details-area {
      .image-name-wrapper {
        display: flex;
        align-items: center;
        padding: 30px 40px;
        padding-bottom: 50px;
        .image-browse-area {
          width: 40%;
          padding-right: 45px;
          .upload-files-container {
            margin-bottom: 0px !important;
            padding: 0px 20px !important;
            .browseFileContainer {
            }
            .image-browse {
              height: 280px;
              width: 260px;
              margin: auto;
              position: relative;
              .image-browse-text {
                display: flex;
                align-items: center;
                height: 100%;
                .browse-txt {
                  padding-top: 40px;
                }
              }
              .browse-image-icon {
                position: absolute;
                left: 0;
                right: 0;
                top: 35%;
              }
            }
            .display-file-container {
              .image-wrapper {
                display: flex;
                width: 100%;
                &.first {
                  .thumbnail-img {
                    margin: 0px 10px 10px 0px;
                    img {
                      width: 100%;
                    }
                    &:nth-child(1) {
                      width: 50%;
                      height: 100%;
                    }
                    &:nth-child(2) {
                      width: 50%;
                      height: 100%;
                    }
                  }
                }
                &.second {
                  display: block;
                }
              }
            }
          }
        }
        .info-area {
          width: 60%;
          .prog-info-wrapper {
            margin-bottom: 15px;
            .prog-name {
              font-weight: bold;
              font-size: 18px;
              color: #333333;
              margin-bottom: 8px;
            }
            .prog-description {
              font-weight: normal;
              font-size: 14px;
              color: #333333;
            }
          }
          .release-help-text {
            color: #00436d;
            font-weight: bold;
            font-size: 14px;
            cursor: pointer;
          }
          .associated-region-wrapper {
            margin-bottom: 20px;
            .label-text {
              font-size: 14px;
              font-weight: 700;
              color: #343434;
              margin-bottom: 8px;

              display: flex;
              align-items: center;
              .region-text {
                font-weight: bold;
                margin-left: 10px;
                display: flex;
                align-items: center;
              }
            }
            .region-box-wrapper {
              display: flex;
              .region-box {
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 14px;
                color: #343434;
                text-transform: uppercase;
                border: 0.5px solid #b4b4b4;
                box-sizing: border-box;
                border-radius: 8px;
                margin-right: 10px;
                padding: 5px 10px;
              }
            }
            .action-btn-wrapper {
              display: flex;
              .btn {
              }
            }
          }
          .region-fields-wrapper {
            margin-bottom: 20px;
            padding-left: 0px;
            .form-group {
              // margin-bottom: 0px;
              padding-left: 0px;
            }
          }
        }
      }
      .details-fields-wrapper {
        display: flex;
        flex-grow: 1;
        padding: 25px;
        .field-column {
          width: 100%;
          padding: 0px 15px;
          border-right: 1px dashed #dddddd;
          &:first-child {
            padding-left: 0px;
          }
          &:last-child {
            border: none;
            padding-right: 0px;
          }
        }
        .field-wrapper {
          width: 100%;
          display: flex;
          font-size: 14px;
          margin-bottom: 8px;

          .lbl {
            width: 50%;
            color: #999999;
            // margin-right: 30px;
          }
          .value {
            width: 50%;
            color: #333333;
          }
        }
      }
      .comments-wrapper,
      .attachments-wrapper {
        padding: 25px;
      }
      .comments-wrapper {
        .repair-text-comment {
          // Logged In user Comment View
          .loggedin-user-comment {
            background: #f9fbff;
            // margin-left: -20px;
            // margin-right: -20px;
            // padding: 10px 25px;
            // border-left: none;
          }
        }
      }
      .attachments-wrapper {
        .upload-files-container {
          padding: 0px !important;
          .display-file-container {
            .image-wrapper {
              display: flex;
              overflow-x: auto;
              .thumbnail-img {
                min-width: 75px;
                width: 75px;
                height: 75px;
                margin-right: 10px;
                &:last-child {
                  margin-right: 0px;
                }
                img {
                  height: 100%;
                  width: 100%;
                }
              }
            }
          }
        }
      }
    }
    .region-container {
      margin-bottom: 0px !important;
      padding-bottom: 0px !important;
    }
    .more-param-section {
      background-color: #f6f9ff;
      border-top: 1px solid #00436d;
      padding: 35px 20px;
      margin-top: -3px;
      position: relative;
      height: 350px;
      .notch-icon {
        background-image: url('/icons/notch-blue.svg');
        width: 42px;
        height: 20px;
        background-repeat: no-repeat;
        background-size: contain;
        cursor: pointer;
        position: absolute;
        top: -19px;
      }
    }
  }

  // Expanded Calendar Page
  .expanded-view-calendar {
    .page-subheader {
      width: 100%;
      height: 70px;
      background: #ffffff;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.203562);
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      z-index: 2;
      .inner-wrapper {
        .left-panel {
          .page-title {
            .left-chevron-icon {
              margin-right: 20px;
            }
            &.expanded-hedaer-text {
              display: flex;
              align-items: center;
              color: #00436d;
              font-weight: 600;
              font-size: 14px;
              text-transform: uppercase;
            }
          }
        }
        .right-panel {
          .action-items-container {
            .export-btn {
              height: 30px;
              // width: 90px;
              border: 1px solid #ddd;
              border-radius: 4px;
              margin: 0px 10px;
              padding: 2px 10px;
              position: relative;
              display: flex;
              align-items: center;
              cursor: pointer;
              .export-icon {
                margin-left: 10px;
              }
            }
          }
        }
      }
    }
    .calendar-view-page-container {
      padding-top: 70px;
    }
  }

  // Monthly View Container
  .monthly_view_wrapper {
    padding-right: 56px;
    background-color: #fff;
    .pd_main {
      @include media_query(M) {
        padding: 0px;
      }
    }
    .program_list_wrapper {
      &.view_all_wrapper {
        max-height: 455px;
        height: 455px;
        background-color: #fff;
        overflow-y: auto;
      }
      .program_header {
        padding: 15px 30px;
        width: 100%;
        height: 55px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: #c3ebe5;
        font-size: 20px;
        color: #00436d;
        @include media_query(L) {
          padding: 15px 25px;
          font-size: 16px;
          font-weight: 600;
        }
        @include media_query(M) {
          padding: 15px 25px;
          font-size: 16px;
          font-weight: 600;
        }
      }
      .card_wrapper {
        height: 320px;
        max-height: 320px;
        overflow: auto;
        background-color: #fff;
        &.large_height {
          height: 400px;
          max-height: 400px;
        }
        .card {
          padding: 16px 25px;
          .inner_wrapper {
            display: flex;
            align-items: center;
            .img_area {
              height: 55px;
              width: 55px;
              margin-right: 30px;
              border-radius: 8px;
              img {
                height: 100%;
                width: 100%;
                border-radius: 8px;
              }
              @include media_query(L) {
                margin-right: 10px;
              }
            }
            .info_area {
              width: calc(100% - 85px);
              .title {
                font-size: 16px;
                color: #3b3d3f;
                // height: 50px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                @include media_query(M) {
                  font-size: 14px;
                  // height: 45px;
                }
              }
              .date {
                font-size: 12px;
                color: #646f79;
                display: flex;
                align-items: center;
                justify-content: space-between;
                @include media_query(M2L) {
                  font-size: 10px;
                }
                @include media_query(M) {
                  font-size: 12px;
                }
              }
              @include media_query(L) {
                width: calc(100% - 65px);
              }
            }
          }
          &:nth-child(odd) {
            background-color: #fff;
          }
          &:nth-child(even) {
            background-color: #f4f7fc;
          }
          @include media_query(L) {
            padding: 15px;
          }
        }
      }
      .holiday_wrapper {
        // display: none;
        padding: 15px 25px;
        .holiday_pill {
          background-color: #fff;
          border: 1px solid #3f8cff;
          color: #3f8cff;
          font-weight: bold;
          font-size: 14px;
          padding: 10px;
          border-radius: 8px;
          margin-bottom: 10px;
          &:last-child {
            margin-bottom: 0px;
          }
          @include media_query(M) {
            margin-bottom: 4px;
          }
        }
        @include media_query(M) {
          display: block;
          padding: 0px;
        }
      }
      @include media_query(M) {
        margin: 0px 0px;
      }
    }
    @include media_query(M) {
      padding: 0px;
    }
  }
}

// Program Details Container
.program_details_container {
  .title_bar {
    // z-index: 99999;
    .action_btn_section {
      .pin_icon {
        height: 24px;
        width: 24px;
        background-image: url('/icons/icon-pin.svg');
        cursor: pointer;
        &.active {
          background-image: url('/icons/icon-pin-active.svg');
        }
      }
      .heart_icon {
        height: 22px;
        width: 25px;
        background-image: url('/icons/icon-heart-inactive.svg');
        cursor: pointer;
        &.active {
          background-image: url('/icons/icon-heart-active.svg');
        }
      }
    }
  }
  .page_inner_wrapper {
    &.location_page {
      position: relative;
      z-index: 0;
    }
    .section {
      .title {
        &.page_title {
          font-size: 20px;
          padding: 0 12px;
          padding-top: 40px;
        }
      }
      .white_box {
        border-radius: 8px;
        box-shadow: 0 1px 4px 0.5px rgba(0, 0, 0, 0.08);
        background-color: $white;
        padding: 24px;
        &.image_view_container {
          padding-right: 80px;
          .img_wrapper {
            display: flex;
            align-items: center;
            overflow-x: auto;
            .img_box {
              // min-width: 230px;
              // max-width: 230px;
              // height: 230px;
              min-width: 300px;
              max-width: 300px;
              height: 300px;
              border-radius: 8px;
              // margin-right: 22px;
              margin-right: 30px;
              margin-bottom: 10px;
              img {
                height: 100%;
                width: 100%;
                border-radius: 8px;
                // object-fit: cover;
                object-fit: contain;
              }
            }
            @include media_query(M) {
              display: none;
            }
          }
          @include media_query(M) {
            padding: 0px;
          }
        }

        &.details_info_container {
          padding: 24px 16px;
          .inner_wrapper {
            .fields_wrap_container {
              display: flex;
              flex-wrap: wrap;
              .field_wrapper {
                width: 25%;
                margin-bottom: 35px;
                padding: 0px 8px;
                word-break: break-word;
                &:last-child {
                  margin-bottom: 0px;
                }
                .lbl {
                  font-size: 14px;
                  font-weight: bold;
                  color: #9e9e9e;
                  margin-bottom: 5px;
                  @include media_query(M) {
                    font-size: 12px;
                  }
                }
                .value {
                  font-size: 16px;
                  color: #212121;
                  @include media_query(M) {
                    font-size: 14px;
                  }
                }
                .added_photos_container {
                  display: flex;
                  overflow-x: auto;
                  .image_container {
                    height: 125px;
                    width: 125px;
                    min-width: 125px;
                    margin-right: 18px;
                    .thumbnail_img {
                      width: 100%;
                      height: 100%;
                      object-fit: cover;
                    }
                    @include media_query(M) {
                      height: 95px;
                      width: 95px;
                      min-width: 95px;
                      margin-right: 10px;
                    }
                  }
                }
                &.full_width {
                  width: 100%;
                }
                .link_container {
                  display: flex;
                  align-items: center;
                  justify-content: flex-start;
                  .seperator {
                    margin: 0px 4px;
                    @include media_query(M) {
                      margin: 0px 2px;
                    }
                  }
                  .link {
                    font-size: 14px;
                    font-weight: 500;
                    color: $primary_main;
                    cursor: pointer;
                    &.disabled {
                      color: #666666;
                      cursor: default;
                    }
                    @include media_query(M) {
                      font-size: 12px;
                    }
                  }
                }
                @include media_query(M) {
                  width: 50%;
                  margin-bottom: 24px;
                }
              }
            }
          }
          @include media_query(M) {
            padding: 16px 8px;
          }
          &.basic_details {
            .inner_wrapper {
              .fields_wrap_container {
                .field_wrapper {
                  width: 20%;
                  &.full_width {
                    width: 100%;
                  }
                  @include media_query(M) {
                    width: 50%;
                    margin-bottom: 24px;
                  }
                }
              }
            }
          }
          &.sku_details {
            .inner_wrapper {
              .fields_wrap_container {
                .field_wrapper {
                  width: 20%;
                  &.full_width {
                    width: 100%;
                  }
                  @include media_query(M) {
                    width: 50%;
                    margin-bottom: 24px;
                  }
                }
              }
            }
          }
        }
        &.attachments_container {
          overflow-x: auto;
          .inner_wrapper {
            display: flex;
            align-items: center;
            .attachment_box {
              display: flex;
              min-width: 35%;
              margin-right: 24px;

              .content_wrapper {
                display: flex;
                align-items: center;
                .thumbnail_img_area {
                  width: 60px;
                  height: 60px;
                  // background: #c4c4c4;
                  border: 1px solid #4b4b4b;
                  padding: 8px;
                  border-radius: 6px;
                  margin-right: 24px;
                  &.file-img-area {
                    padding: 0px;
                    border: none;
                    border-radius: 6px;
                  }
                  .thumbnail-icon {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                  }
                  .file-img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    border-radius: 6px;
                  }
                }
                .info_area {
                  width: calc(100% - 90px);
                  color: #212121;
                  .name {
                    font-weight: bold;
                    font-size: 14px;
                  }
                  .size {
                    font-weight: 300;
                    font-size: 14px;
                  }
                }
              }
              @include media_query(M) {
                padding: 15px 0px;
                border-bottom: 1px solid rgba(33, 33, 33, 0.2);
                &:last-child {
                  border: none;
                }
              }
            }
            .empty_text_attachment {
              width: 100%;
              text-align: center;
            }
            @include media_query(M) {
              display: block;
            }
          }
          @include media_query(M) {
            padding: 0px;
            background-color: #f6f6fa;
            box-shadow: none;
          }
        }
      }
      &.image_section {
        .mobile_image_carousel_view {
          display: none;
          @include media_query(M) {
            height: 400px;
            display: initial;
            .slider_container {
              height: 340px;
              // background-color: #dbecf8;
              background-color: #fff;
              .img_box {
                height: 300px;
                width: 100%;
                img {
                  height: 100%;
                  width: 100%;
                  // object-fit: cover;
                  object-fit: contain;
                }
              }
            }
            .slick-dots {
              bottom: 15px;
            }
          }
          .info_container {
            font-weight: normal;
            font-size: 18px;
            color: #212121;
            margin: 16px 0px;
            .brand_name {
              font-weight: bold;
              font-size: 18px;
            }
          }
        }
        @include media_query(M) {
          padding-top: 40px;
        }
      }
    }
  }
  .saved_view_container {
    .saved_view_cards_container {
      @include media_query(M) {
        display: none;
      }
    }
    .mobile_saved_view_cards_container {
      display: none;

      @include media_query(M) {
        display: block;
        padding: 37px 24px 0;
      }
    }
  }
}

.marketing_calendar_card_wrapper {
  min-height: 265px;
  height: 265px;
  &.MuiPaper-elevation1 {
    box-shadow: 0 1px 4px 0.5px rgba(0, 0, 0, 0.08);
  }
  @include media_query(M) {
    height: 225px;
    min-height: 225px;
  }
}
// Home Card Marketing Calendar

.marketing_calendar_card_container {
  background: linear-gradient(
    180deg,
    rgba(26, 26, 26, 0) 0%,
    rgba(26, 26, 26, 0) 50%,
    rgba(26, 26, 26, 0.8) 75.6%
  );
  box-shadow: 0px 1px 4px 0.5px rgba(0, 0, 0, 0.08);
  min-height: 265px;
  height: 265px;
  position: relative;
  // background-image: url('/images/sample-thumbnail-home-img.svg');
  cursor: pointer;

  &.hasNoAction {
    cursor: not-allowed;
  }
  .thumbnail-bg-img {
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;

    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
  }
  .overlay {
    position: absolute;
    bottom: 0px;
    background: linear-gradient(
      180deg,
      rgba(26, 26, 26, 0) 0%,
      rgba(26, 26, 26, 0) 50%,
      rgba(26, 26, 26, 0.8) 75.6%
    );
    z-index: 0;
  }
  .overlay_text_container {
    font-size: 12px;
    color: #ffffff;
    padding: 20px 12px;
    .top_text {
      width: 100%;
      position: absolute;
      right: 0px;
      left: 0px;
      top: 0px;
      padding: 4px 12px;
      color: #000;
      text-transform: uppercase;
      background-color: rgba(255, 255, 255, 0.45);
      text-transform: uppercase;
      .heading_txt {
        font-size: 15px;
        font-weight: 500;
        color: #00436d;
        letter-spacing: 0.75px;
      }
    }
    .bottom_text_container {
      position: absolute;
      bottom: 50px;
      padding-right: 12px;
      .head_text {
        font-size: 18px;
        margin-bottom: 8px;
        max-height: 105px;
        overflow: hidden;
        &.error_message_bottom {
          margin-bottom: -30px;
          .error_message {
            font-weight: 600;
          }
        }
      }
      .info_details_container {
        display: flex;
        align-items: center;
        .seperator_line_icon {
          width: 2px;
          height: 15px;
          background-image: url('/icons/icon-seperator-grey.svg');
          margin: 0px 15px;
        }
        .info_box {
          display: flex;
          align-items: center;
          font-weight: 300;
          font-size: 12px;

          letter-spacing: 0.5px;

          color: #ffffff;
          .time_icon {
            width: 15px;
            height: 15px;
            background-image: url('/icons/icon-time-grey.svg');
            margin-right: 10px;
          }
          .location_icon {
            width: 12px;
            height: 15px;
            background-image: url('/icons/icon-location-grey.svg');
            margin-right: 10px;
          }
          .location_info {
            .region-names-container {
              display: flex;
              align-items: center;

              text-transform: uppercase;
              .region {
                &.in-active {
                  color: #fb4e4e;
                  text-decoration: line-through;
                }
                &.active {
                  color: #0a7908;
                }
              }
              .seperator_line_icon {
                margin: 0px 5px;
                background-color: #fff;
              }
            }
          }
        }
      }
    }
  }
  @include media_query(M) {
    height: 225px;
    min-height: 225px;
  }
}

//Common Filter
.calendar_filter_container {
  .custom-filter-wrapper {
    width: 390px;
    .inner-wrapper {
      margin: 37px 24px 0px;
      .field-section {
        width: 100%;
        margin-bottom: 24px;
        .section-label {
          font-weight: 500;
          font-size: 14px;
          margin-bottom: 16px;
        }
        .form_contorl {
          height: 50px;
        }
        .section-items-row {
          .section-item {
            width: 95px;
            padding: 0px 10px;
            margin-right: 10px;
            margin-bottom: 15px;
            font-size: 12px;
            // &:last-child {
            //   margin-bottom: 0px;
            // }
          }
        }
        &.custom-brand-dropdown {
          position: relative;
          .textfield_area {
            margin-bottom: 0px;
            height: 42px;
            position: relative;
            .input_field {
              height: 100%;
              border: 1px solid rgba(0, 0, 0, 0.23);
              border-radius: 4px;
              color: rgba(0, 0, 0, 0.87);
              position: relative;
              padding: 9.5px 8px;
              // padding-left: 6px;
              padding-right: 70px;
            }
            .action_icon_wrapper {
              height: 26px;

              display: flex;
              align-items: center;
              justify-content: flex-end;
              margin-right: 8px;
              position: absolute;
              right: 8px;
              bottom: 0;
              top: 0;
              margin: auto;
              .MuiIconButton-root {
                padding: 2px;
                .MuiSvgIcon-root {
                  color: rgba(0, 0, 0, 0.54);
                }
              }
              .close_button {
                padding: 5px;
              }
            }
          }
          .custom_dropdown_container {
            top: 85px;
          }
        }
      }
    }
    .apply-button {
      position: sticky;
      bottom: 0;
      &.disabled {
        pointer-events: none;
        opacity: 1;
        cursor: not-allowed;
        color: #568fa8;
      }
      &:hover {
        background-color: $white;
      }
    }
  }
  .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] {
    padding: 3px !important;
    font-size: 14px !important;
  }
  .MuiAutocomplete-popper {
    top: 0px !important;
    left: 0px !important;
  }
  .MuiAutocomplete-input {
    padding-right: 65px !important;
  }
}

.location_inputbox_container {
  width: 100%;
  min-height: 55px;
  height: auto;
  display: flex;
  align-items: center;
  background: #ffffff;
  box-shadow: 0px 1px 4px 0.5px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  position: relative;
  padding: 10px 20px;
  padding-right: 130px;
  .selected_text {
    font-size: 12px;
    text-transform: uppercase;
    color: #212121;
  }
  .change_btn {
    width: 110px;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 0px;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    background-color: #00436d;
    cursor: pointer;
    color: #fff;
    @include media_query(L) {
      height: 30px;
      top: -40px;
      border-radius: 8px;
      font-size: 14px;
    }
    @include media_query(M) {
      width: 80px;
      font-size: 12px;
    }
  }

  @include media_query(L) {
    min-height: 40px;
    height: auto;
    padding-right: 20px;
  }
  @include media_query(M) {
    min-height: 40px;
    height: auto;
    padding: 10px 8px;
  }
}

// Common Dropdown List UI
// For All Input height of 40px
.custom_search_dropdown_container {
  position: relative;
  margin-bottom: 24px;
  .input_box {
    height: 42px;
    display: flex;
    align-items: center;
    padding: 0px 15px;
    padding-right: 50px;
    border: 1px solid #dbdde0;
    box-sizing: border-box;
    border-radius: 8px;
    cursor: pointer;
    .text {
      font-size: 16px;

      color: #343434;
    }
    .chevron-down {
      width: 15px;
      height: 10px;
      background-image: url('/icons/icon-chevron-down.svg');
      margin-right: 15px;
    }
  }
  .common_dropdown_list_wrapper {
    width: 100%;
    position: absolute;
    top: 44px;
    background-color: #fff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    .search_container {
      position: relative;
      padding: 15px;

      .search_input {
        width: 100%;
        height: 40px;
        background: #ffffff;
        border: 1px solid #00436d;
        box-sizing: border-box;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
        border-radius: 8px;
        padding-left: 40px;
      }
      .search_icon {
        background-image: url('/icons/icon-search.svg');
        height: 20px;
        width: 20px;
        background-repeat: no-repeat;
        background-size: contain;
        position: absolute;
        margin-left: 25px;
        cursor: pointer;
      }
    }
    .list_wrapper {
      max-height: 200px;
      overflow-y: auto;
      // margin-top: -2px;

      .list_item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;
        color: #9b9b9b;
        height: 50px;
        padding: 20px 15px;
        cursor: pointer;
        .item_text {
          font-size: 14px;
          color: #9b9b9b;
        }
        &:hover {
          background: rgba(79, 97, 188, 0.1);
        }
        .action_text {
          font-size: 12px;
          color: #00436d;
          cursor: pointer;
        }
      }
    }
  }
}

// Working but need to removed
// .MuiAutocomplete-popper {
//   top: 0px !important;
//   left: 0px !important;
// }

.program_attachement_gallery {
  position: relative;
  // height: 100vh;

  .image_carousel_wrapper {
    height: 100%;
    .photo_gallery_wrapper {
      height: 100%;
      .carousel_main_container {
      }
      .thumbnail_container {
        position: fixed;
        bottom: 0px;
        left: 0px;
        right: 0px;
        .thumbnail_inner_wrapper {
          .thumbnail_img_card {
            min-width: 90px;
            width: 90px;
            height: 90px;
            &.thumbnail_icon_card {
              padding: 15px;
            }
          }
          .thumbnail_img {
            &.thumbnail_icon {
              object-fit: contain;
            }
          }
        }
      }
    }
  }
}

// Holiday Line
.holiday-line-container {
  // width: 100vw;
  width: 100%;
  height: 1px;
  background-color: #00436d;
  // background-color: #50a9b5;
  position: absolute;
  top: 25px;
  left: 0px;
  right: 0px;
  display: flex;
  align-items: center;
  justify-content: center;

  // z-index: 999;
  .holiday-capsule {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -10px;
    padding: 2px 10px;
    background: #eaf6f8;
    // background-color: #fff;
    border: 1px solid #00436d;
    // border: 1px solid #50a9b5;
    color: #787878;
    font-size: 11px;
    font-weight: 500;
    border-radius: 40px;
    word-break: break-all;

    .name {
      max-width: 70px;
      @include media_query(L) {
        max-width: 60px;
      }
      @include media_query(M) {
        display: none;
      }
    }
    .count {
      margin-left: 2px;
      cursor: pointer;
      @include media_query(M) {
        display: none;
      }
    }
    .holiday-icon {
      display: none;
      @include media_query(M) {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 13px;
        height: 11px;
        background-image: url('/icons/icon-holiday.svg');
      }
      &.active {
        background-image: url('/icons/icon-holiday-active.svg');
      }
    }
    @include media_query(M) {
      top: -12px;
      border-radius: 50%;
      width: 24px;
      height: 24px;
      // background: #00436d;
      padding: 0px;
      background: #fff;
      border: 1px solid #00436d;
      &.mob-active {
        background: #00436d;
      }
    }
  }
  .holiday_icon_container {
    position: relative;
  }
  &:nth-child(2) {
    top: 58px;
  }
  @include media_query(M) {
    background-color: #00436d;
  }
}

.export_details_icon {
  background-image: url('/icons/mobile-icons/icon-mob-export.svg');
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
  width: 24px;
  height: 24px;
  position: relative;
  margin-right: 24px;
  @include media_query(M) {
    background-image: url('/icons/mobile-icons/icon-mob-export.svg');
  }
}

// Expand Circle with count
.expand-circle {
  width: 35px;
  height: 35px;
  border: 2px solid #00436d;
  border-radius: 27px;
  cursor: pointer;
  .count {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 12px;
    color: #00436d;
    padding-top: 2px;
  }
}

//Mobile view popover
.calendar_holiday_container {
  background-color: transparent !important;
  .holiday_UI {
    min-height: 180px;
  }
  .holiday_list_container {
    width: 170px;
    min-height: 170px;
    max-height: 195px;
    overflow-y: auto;

    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    padding: 10px;
    .holiday_box {
      background: rgba(80, 169, 181, 0.1);
      border: 0.5px solid #50a9b5;
      border-radius: 4px;
      padding: 10px;
      margin-bottom: 8px;
      .name {
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        text-transform: capitalize;

        color: #00436d;
      }
      .duration {
        font-weight: 400;
        font-size: 10px;
        line-height: 14px;
        /* identical to box height */

        text-transform: capitalize;

        color: #00436d;
      }
      &:last-child {
        margin-bottom: 0px;
      }
    }
  }
}
