/** Metropolis **/

/* Metropolis-Regular */
@font-face {
  font-family: 'Metropolis';
  src: url('./metropolis/Metropolis-Regular.eot');
  src: url('./metropolis/Metropolis-Regular.woff2') format('woff2'),
    url('./metropolis/Metropolis-Regular.woff') format('woff'),
    url('./metropolis/Metropolis-Regular.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

/* Metropolis-Medium */
@font-face {
  font-family: 'Metropolis';
  src: url('./metropolis/Metropolis-Medium.eot');
  src: url('./metropolis/Metropolis-Medium.woff2') format('woff2'),
    url('./metropolis/Metropolis-Medium.woff') format('woff'),
    url('./metropolis/Metropolis-Medium.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

/* Metropolis-SemiBold */
@font-face {
  font-family: 'Metropolis';
  src: url('./metropolis/Metropolis-SemiBold.eot');
  src: url('./metropolis/Metropolis-SemiBold.woff2') format('woff2'),
    url('./metropolis/Metropolis-SemiBold.woff') format('woff'),
    url('./metropolis/Metropolis-SemiBold.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

// /* Metropolis-Light */
// @font-face {
//   font-family: 'Metropolis';
//   src: url('./metropolis/Metropolis-Light.eot');
//   src: url('./metropolis/Metropolis-Light.woff2') format('woff2'),
//     url('./metropolis/Metropolis-Light.woff') format('woff'),
//     url('./metropolis/Metropolis-Light.ttf') format('truetype');
//   font-weight: 300;
//   font-style: normal;
// }

/** HelveticaNeue Light **/
@font-face {
  font-family: 'HelveticaNeue';
  src: url('./helveticaNeue/helveticaneue-light.eot');
  src: url('./helveticaNeue/helveticaneue-light.woff2') format('woff2'),
    url('./helveticaNeue/helveticaneue-light.woff') format('woff'),
    url('./helveticaNeue/helveticaneue-light.ttf') format('truetype'),
    url('./helveticaNeue/helveticaneue-light.svg') format('svg'),
    url('./helveticaNeue/helveticaneue-light.eot') format('embedded-opentype');
  font-weight: 300;
  font-style: normal;
}

/** HelveticaNeue Medium **/
@font-face {
  font-family: 'HelveticaNeue';
  src: url('./helveticaNeue/helveticaneue-medium.eot');
  src: url('./helveticaNeue/helveticaneue-medium.woff2') format('woff2'),
    url('./helveticaNeue/helveticaneue-medium.woff') format('woff'),
    url('./helveticaNeue/helveticaneue-medium.ttf') format('truetype'),
    url('./helveticaNeue/helveticaneue-medium.svg') format('svg'),
    url('./helveticaNeue/helveticaneue-medium.eot') format('embedded-opentype');
  font-weight: 600;
  font-style: normal;
}

/** HelveticaNeue Bold **/
@font-face {
  font-family: 'HelveticaNeue';
  src: url('./helveticaNeue/helveticaneue-bold.eot');
  src: url('./helveticaNeue/helveticaneue-bold.woff2') format('woff2'),
    url('./helveticaNeue/helveticaneue-bold.woff') format('woff'),
    url('./helveticaNeue/helveticaneue-bold.ttf') format('truetype'),
    url('./helveticaNeue/helveticaneue-bold.svg') format('svg'),
    url('./helveticaNeue/helveticaneue-bold.eot') format('embedded-opentype');
  font-weight: 700;
  font-style: normal;
}
