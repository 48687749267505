.photo_gallery_wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px 70px;
  margin-bottom: 40px;
  .carousel_main_container {
    margin-bottom: 40px;
    .carousel_wrapper {
      .content_wrapper {
        width: 100%;
        // height: 275px;
        position: relative;
        .image_wrapper {
          width: 480px;
          height: 275px;
          margin: auto;
          .active_img {
            height: 100%;
            width: 100%;
            object-fit: contain;
          }
          @include media_query(M) {
            width: 90%;
            height: 200px;
          }
        }
        .icon {
          width: 27px;
          height: 27px;
          position: absolute;
          top: 45%;
          bottom: 0;
          cursor: pointer;
          &.chevron_left {
            left: -60px;
            @include media_query(M) {
              left: -10px;
            }
          }
          &.chevron_right {
            right: -60px;
            @include media_query(M) {
              right: -10px;
            }
          }
        }
      }
    }
  }
  .thumbnail_container {
    width: 100%;
    .thumbnail_inner_wrapper {
      width: 100%;
      display: flex;
      margin: auto;
      overflow-x: auto;
      overflow-y: hidden;
      scroll-behavior: smooth;
      .thumbnail_img_card {
        width: 125px;
        min-width: 125px;
        height: 125px;
        border-radius: 8px;
        margin: 0px 8px;
        opacity: 0.7;
        &.active {
          border: solid 2px $primary_main;
          opacity: 1;
        }
        &:first-child {
          margin-left: 0px;
        }
        &:last-child {
          margin-right: 0px;
        }
        .thumbnail_img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        @include media_query(M) {
          width: 85px;
          min-width: 85px;
          height: 85px;
          margin: 0px 6px;
        }
      }
    }
  }
  @include media_query(M) {
    padding: 0px 0px;
  }
}
