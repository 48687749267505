.help_container {
  .cards_main_container {
    margin: 0 -12px;
    .card_container {
      padding: 0 12px;
      margin-bottom: 24px;
      .media_card_wrapper .card_wrapper .card .card_img {
        height: 175px;
        // background-size: contain;
      }
      .media_card_wrapper .card_wrapper .card .card_img.tour_img {
        height: 242px;
      }
      .tour_txt {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        color: $white;
        font-size: 20px;
        font-weight: 500;
      }
      .media_card_wrapper .card_wrapper .card .card_content {
        padding: 12px 16px;
      }
    }
  }
  .footer_txt {
    font-size: 18px;
    font-weight: 500;
    color: #333;
    text-align: center;
    margin-top: 10px;
    .link {
      text-decoration: underline;
      color: #333;
    }
    @include media_query(M) {
      font-size: 14px;
      margin-bottom: 20px;
    }
  }
}

.help_dialog_area {
  .arrows {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    height: 585px;
    width: 600px;
    .left_gray_icon,
    .right_gray_icon {
      width: 95px;
      height: 95px;
      cursor: pointer;
      position: absolute;
      @include media_query(M) {
        width: 35px;
        height: 35px;
      }
    }
    .left_gray_icon {
      background-image: url('/icons/left-gray-icon.svg');
      left: -180px;
      @include media_query(M) {
        left: -45px;
      }
    }
    .right_gray_icon {
      background-image: url('/icons/right-gray-icon.svg');
      right: -180px;
      @include media_query(M) {
        right: -45px;
      }
    }
    @include media_query(M) {
      width: 300px;
    }
  }
  .dialog_title_wrapper {
    .title {
      font-size: 20px;
      font-weight: 500;
      text-align: center;
      @include media_query(M) {
        font-size: 16px;
        line-height: initial;
      }
    }
  }
  .inner_wrapper {
    padding: 24px;
    height: 470px;
    .slide_img_container {
      height: 255px;
      display: block;
      overflow: hidden;
      width: 100%;
      border-radius: 8px;
      margin-bottom: 20px;
      .slide_img {
        height: 100%;
        width: 100%;
        object-fit: contain;
        border-radius: 8px;
      }
    }
    &.slide_img_only {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      position: relative;
      .slide_img_container {
        height: 300px;
        margin-bottom: 0;
      }
    }
    .details {
      font-size: 18px;
      line-height: normal;
      .step_count {
        font-weight: 500;
        margin-bottom: 16px;
      }
      @include media_query(M) {
        font-size: 16px;
      }
    }
    @include media_query(M) {
      height: 450px;
    }
  }

  .onboarding_help {
    z-index: 10;
    cursor: pointer;
  }

  .MuiDialog-paper {
    width: 805px;
    height: 585px;
    border-radius: 8px;
    box-shadow: 0 1px 4px 0.5px rgba(0, 0, 0, 0.08);
    background-color: $white;
    .dialog_title_wrapper {
      position: relative;
      padding-right: 40px;
      .close_button {
        position: absolute;
        right: 0;
        top: 0;
        cursor: pointer;
      }
    }
    @include media_query(M) {
      width: 300px;
    }
  }
  .mb_stepper {
    position: absolute;
    bottom: 0;
    width: 100%;
    background: $white;
    .MuiMobileStepper-dots {
      display: none;
    }
  }
}
