// Declaring Media Queries...

@mixin media_query($point) {
  @if $point == XXL {
    @media only screen and (min-width: 1441px) and (max-width: 2732px) {
      @content;
      /* Anything you write inside the curly braces of the @include will be generated in place of the @content directive in our mixin. */
    }
  }

  /* Large Devices, Wide Screens 1200px */
  @else if $point == XL {
    @media only screen and (max-width: 1918px) {
      @content;
    }
  }

  /* Medium Devices, Desktops 992*/
  @else if $point == L {
    @media only screen and (max-width: 1280px) {
      @content;
    }
  }

  /* Large Devices, Tablets 1024*/
  @else if $point == M2L {
    @media only screen and (max-width: 1100px) {
      @content;
    }
  }
  /* Small Devices, Tablets 768*/
  @else if $point == M {
    @media only screen and (max-width: 960px) {
      @content;
    }
  }

  /* Small Devices */
  @else if $point == S {
    @media only screen and (max-width: 600px) {
      @content;
    }
  }

  /* Extra Small Devices, Phones */
  // @else if $point == XS {
  //   @media only screen and (min-width: 412px) {
  //     @content;
  //   }
  // }
}
