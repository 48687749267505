.cards_area {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  // flex-wrap: nowrap;
  // overflow: hidden;
  margin-left: -12px;
  margin-right: -12px;
  padding-bottom: 2px;
  .card_container {
    margin-left: 12px;
    margin-right: 12px;
    margin-bottom: 24px;
    // max-width: 235px;
    // min-width: 235px;
    width: calc(20% - 24px);
    min-width: calc(20% - 24px);
    // flex: 1 1 auto;
    display: inline-block;
    position: relative;
    padding: 1px;
    // min-width: calc(20% - 24px);
    // @include media_query(XL) {
    //   max-width: 245px;
    //   min-width: 245px;
    // }
    @include media_query(L) {
      // max-width: 208px;
      // min-width: 208px;
      width: calc(25% - 24px);
      min-width: calc(25% - 24px);
    }
    @include media_query(M) {
      width: calc(33.3% - 24px);
      min-width: calc(33.3% - 24px);
    }
    @include media_query(S) {
      margin-left: 8px;
      margin-right: 8px;
      width: calc(50% - 16px);
      min-width: calc(50% - 16px);
      // min-width: calc(50% - 16px);
      // max-width: calc(50% - 16px);
      margin-bottom: 16px;
    }

    .slick-slide {
      .image_container {
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }
  &.nowrap_cards {
    flex-wrap: nowrap;
    overflow: hidden;
  }

  @include media_query(M) {
    margin-left: -8px;
    margin-right: -8px;
  }
}
