.mobile_side_nav_container {
  .nav_bar_container {
    top: auto;
    bottom: 0;
    min-height: 52px;
    border-radius: 25px 25px 0px 0px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    transition: 0.5s;
    transition-timing-function: linear;
    .arrow_icon_container {
      position: absolute;
      top: -24px;
      left: 0px;
      right: 0px;
      text-align: center;
      .icon_arrow {
        cursor: pointer;
      }
    }
    .list_wrapper {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      .list_item {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        color: #668ea7;
        width: 20%;
        padding: 12px 5px;
        .icon {
          width: 20px;
          height: 20px;
          margin-bottom: 7px;
          &.home {
            width: 22px;
          }
        }
        .title {
          font-size: 10px;
          //color: #668ea7;
        }
        &.active {
          color: $white;
        }

        // Collapsed View Active State
        .active_border {
          height: 6px;
          width: 100%;
          position: absolute;
          bottom: 0px;
          background-color: $white;
          border-top-left-radius: 50px;
          border-top-right-radius: 50px;
        }
      }
    }
  }
  //   MUI Overrided classes
  .MuiIconButton-label {
    flex-direction: column;
  }
  .MuiToolbar-regular {
    min-height: 52px;
  }
}
