@import './common/all';
@import './components/all';

.tab_panel {
  display: flex;
  align-items: center;
  // margin-bottom: 24px;
  .MuiBox-root {
    padding: 0;
    width: 100%;
  }
}
.title.MuiTypography-body1 {
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.5px;
  color: #212121;
  margin-bottom: 8px;
  @include media_query(M) {
    font-size: 14px;
  }
}

// Image card with action items
.image_container {
  .close_button_container {
    height: 19px;
    width: 19px;
    position: absolute;
    top: 8px;
    right: 8px;
    cursor: pointer;
    overflow: hidden;
    .mui_icon_cancel {
      height: 19px;
      width: 19px;
    }
  }
}
