// This file used in Photo Gallery Details + Store Comments Image Gallery UI
.photo_gallery_details {
  .title_bar {
    top: 0px;
    padding-right: 20px;
    .back_btn_area {
      .inner_wrapper {
        .page_title {
          @include media_query(M) {
            max-width: 230px;
            display: block;
          }
        }
      }
    }
    @include media_query(M) {
      width: 100% !important;
    }
  }
  .image_carousel_wrapper {
    width: 100%;
    height: 100%;
    padding: 40px 0px;
    padding-bottom: 0px;
    // display: flex;
    position: relative;

    // Store Comments
    .uploaded_date_container {
      // width: 720px;
      padding: 0 30px;
      padding-left: 60px;
      margin: 0 auto;
      margin-bottom: 30px;

      .date_text {
        font-size: 16px;
        color: #212121;
      }
      @include media_query(M2L) {
        width: 520px;
      }
      @include media_query(M) {
        width: 100%;
        padding: 16px;
        margin-bottom: 0px;
      }
    }

    // Mobile visible Title bar content
    .mob_visible_title_container {
      display: none;
      @include media_query(M) {
        display: block;
        background-color: #fff;
        padding: 20px 16px;
        .title_inner_wrapper {
          .title_content {
            .album_info {
              display: flex;
              margin-bottom: 20px;
              .date_text {
                font-size: 14px;
                color: #616161;
              }
            }
            .textarea_autosize_label_wrapper {
              margin-bottom: 0px;
              .textarea_field {
                background-color: #fff;
                border: 1px solid #b5b5b5;
                margin-bottom: 0px;
              }
            }
            .page_title {
              font-size: 18px;
              color: $primary_dark;
              display: flex;
              align-items: center;
              cursor: pointer;
            }
          }
        }
      }
    }
    // Common component overrided style
    .photo_gallery_wrapper {
      padding: 0px;
      margin-bottom: 0px;
      width: inherit;
      .carousel_main_container {
        .carousel_wrapper {
          .content_wrapper {
            width: 100%;
            .image_wrapper {
              width: 720px;
              height: 470px;
              @include media_query(M2L) {
                width: 520px;
                height: 430px;
              }
              @include media_query(M) {
                width: 100%;
                height: 280px;
              }
            }
            .icon {
              .MuiSvgIcon-root {
                width: 42px;
                height: 42px;
                @include media_query(M) {
                  width: 30px;
                  height: 30px;
                }
              }
              @include media_query(M) {
                &.chevron_left {
                  left: 10px;
                }
                &.chevron_right {
                  right: 10px;
                }
              }
            }
          }
          @include media_query(M) {
            height: calc(100vh - 180px);
            display: flex;
            align-items: center;
          }
        }
        @include media_query(M) {
          // margin: 100px 0px;
          width: 100%;
        }
      }
      .thumbnail_container {
        background-color: #fff;
        padding: 20px 24px;
        .thumbnail_inner_wrapper {
          width: calc(100% - 15%);
          .thumbnail_img_card {
            min-width: 180px;
            height: 135px;
            .close_button_container {
              display: none;
            }
            @include media_query(M) {
              min-width: 125px;
              height: 95px;
            }
          }
          @include media_query(M) {
            width: 100%;
          }
        }

        @include media_query(M) {
          position: fixed;
          bottom: 0px;
        }
      }
    }
    @include media_query(M) {
      padding: 0px;
    }
  }
  @include media_query(M) {
    padding: 0px;
    width: 100% !important;
  }

  // Store Comments flow
  &.store_comments {
    .title_bar {
      .back_btn_area {
        .page_title {
          @include media_query(M) {
            display: none;
          }
        }
        @include media_query(M) {
          width: 10%;
        }
      }
      .action_btn_section {
        @include media_query(M) {
          width: 90%;
        }
      }
    }
    .photo_gallery_wrapper {
      .carousel_main_container {
        .carousel_wrapper {
          @include media_query(M) {
            height: calc(100vh - 355px);
          }
        }
      }
      .thumbnail_container {
        position: relative;
        .thumbnail_inner_wrapper {
          .thumbnail_img_card {
            .checkbox_wrapper {
              position: absolute;
              left: 8px;
              bottom: 8px;
              .MuiIconButton-label {
                background-color: #fff;
              }
              .MuiIconButton-root {
                padding: 0px;
              }
              .MuiFormControlLabel-root {
                margin: 0px;
              }
            }
          }
        }
        // Text used in Store Comments
        .select_text {
          position: absolute;
          right: 24px;
          top: 40%;
          font-size: 14px;
          font-weight: 500;
          color: #00436d;
          cursor: pointer;
          @include media_query(M) {
            top: 5px;
          }
        }
        @include media_query(M) {
          padding: 30px 16px;
        }
      }
    }
  }
}

.mob_fab_btn_remark {
  display: none;
  @include media_query(M) {
    display: block;
    // position: fixed;
    // bottom: 170px;
    right: 16px;
    position: absolute;
    bottom: 135px;
    .icon {
      &.remark_white {
        width: 20px;
        height: 20px;
        background-image: url('/icons/icon-remark-white.svg');
      }
    }
  }
}
