.sales_flash_card {
  &_title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.sales_flash_card_container {
  display: inline-block;
  // padding: 24px 30px;
  padding: 24px 10px;
  width: 100%;
  min-height: 265px;
  height: 265px;
  &.MuiPaper-elevation1 {
    box-shadow: 0 1px 4px 0.5px rgba(0, 0, 0, 0.08);
  }

  .card_content {
    display: flex;
    flex-direction: column;

    padding: 0;
    margin: 0;

    &:last-child {
      padding-bottom: 0;
    }
  }

  .details {
    display: flex;
    flex-direction: column;
    flex: 1;
    // margin-top: 24px;

    .title {
      font-size: 16px;
      font-weight: 500;
      text-align: center;
      line-height: 0.89;
      letter-spacing: 0.75px;
      margin-bottom: 15px;

      .inner_wrapper {
        padding: 4px 8px;
        border-radius: 4px;
        background-color: #eeeeee;
        width: 75px;
        height: 24px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        @include media_query(M) {
          font-size: 14px;
          // margin-bottom: 8px;
        }
      }
      @include media_query(M) {
        margin-bottom: 8px;
      }
    }
    .horizontal_list {
      .list_item_with_divider {
        padding: 5px 3px;
        &:first-child {
          padding-left: 0px;
        }
        &:last-child {
          padding-right: 0px;
        }
      }
    }
    &:not(:last-child) {
      margin-bottom: 24px;
      @include media_query(M) {
        font-size: 12px;
      }
    }
  }

  .subsection {
    display: flex;
    flex-direction: column;

    justify-content: center;
    .number_view {
      display: flex;
      flex-direction: row;

      justify-content: center;

      // font-size: 20px;
      font-size: 18px;
      font-weight: 500;
      letter-spacing: 0.25px;
      @include media_query(XL) {
        font-size: 15px;
      }
      @include media_query(M) {
        font-size: 14px;
      }
    }

    .value {
      // margin-right: 7px;
      margin-right: 4px;
    }

    .percentage_growth {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .arrow_icon {
      height: 20px;
      width: 19px;
      position: relative;
      top: 1px;
      margin-left: 0px;
      margin-right: -2px;
      @include media_query(M) {
        height: 14px;
        width: 14px;
        top: 0;
      }
    }

    .label_view {
      font-size: 11px;
      line-height: 1.33;
      letter-spacing: 0.4px;

      margin-top: 4px;
      // text-align: left;
      text-align: center;
    }
  }
  @include media_query(L) {
    padding: 24px 10px;
  }
  @include media_query(M) {
    padding: 16px 3px;
    height: 225px;
    min-height: 225px;
  }
}
