.horizontal_list {
  display: flex;
  flex-direction: row;

  .list_item_with_divider {
    display: flex;
    flex-direction: row;
    padding: 5px 3px;
    flex-grow: 1;
    max-width: none;
    flex-basis: 0;
    flex-shrink: 1;
    justify-content: center;
    position: relative;
  }

  .divider {
    display: flex;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 1px;
    height: 80%;
    background-image: linear-gradient(to top, #eeeeee, #bdbdbd, #eeeeee);
    .MuiDivider-light {
      background-color: transparent;
    }
  }
}
