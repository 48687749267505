// MUI Card with custom elements
// Overall Card padding is 16px coming from class-MuiCardContent-root
.media_card_wrapper {
  .repair_details_wrapper {
    padding: 0 !important;
  }
  .card_wrapper {
    // Default height for different cards
    &.small {
      .card {
        height: 220px;
      }
    }
    &.large {
      .card {
        height: 260px;
      }
    }

    .card {
      width: 100%;
      // max-width: 240px;
      height: 242px;
      margin: 0 auto;
      border-radius: 8px;
      box-shadow: 0 1px 4px 0.5px rgba(0, 0, 0, 0.08);

      .card_img {
        height: 145px;
        &.sm {
          height: 115px;
        }
        &.large {
          height: 160px;
        }
      }
      // Text Classes
      .primary_text {
        font-size: 14px;
        font-weight: 500;
        color: #00436d;
        @include media_query(M) {
          font-size: 12px;
        }
      }
      .secondary_text {
        font-size: 12px;
        color: #212121;
        &.light {
          font-weight: 100;
        }
        @include media_query(M) {
          font-size: 10px;
          margin-bottom: 12px;
        }
      }

      .flex_element_wrapper {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        .brand {
          max-width: 100px;
        }
      }

      // Status/Priority Color bar Classes
      &.status_color {
        border-bottom: 4px solid #fff;
        // Repair Status classes
        &.urgent {
          border-color: #ff5656;
        }
        &.high {
          border-color: #ff5c00;
        }
        &.medium {
          border-color: #50e3c2;
        }
        &.low {
          border-color: #2ebe34;
        }
        &.health_and_safety {
          border-color: #0096eb;
        }
        // VM Content Status classes
        &.active {
          border-color: #009b3e;
        }
        &.inactive {
          border-color: #f86868;
        }
      }

      // Photo Count
      .count_wrapper {
        display: flex;
        align-items: center;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.6);
        .MuiSvgIcon-root {
          width: 18px;
          height: 18px;
          margin-right: 8px;
        }
        @include media_query(M) {
          font-size: 12px;
        }
      }

      // Card Content with background color
      .bg_content_wrapper {
        background-color: #eaf2f7;
      }

      // Label and value pair
      .label_value_wrapper {
        display: flex;
        color: #333333;
        margin-bottom: 12px;
        &:last-child {
          margin-bottom: 0px;
        }
        .label_text {
          width: 45%;
          font-size: 14px;
          font-weight: 300;
        }
        .value_text {
          width: 55%;
          color: #212121;
          font-size: 14px;
          &.long_text {
            // max-height: 42px;
            max-height: 22px;
            overflow: hidden;
          }
        }
      }

      // MUI Overrided classes thorughout the card
      // .MuiPaper-rounded {
      //   border-radius: 8px;
      // }
      .MuiCard-root {
        height: 100%;
      }
      .MuiCardContent-root {
        @media only screen and (max-width: 960px) {
          padding: 8px;
        }
      }
      .MuiCardContent-root:last-child {
        padding-bottom: 0px;
      }

      // carousel dots
      // Custom dots-position for image card
      .slick-dots {
        // bottom: 8px;
        top: 115px;
        height: 20px;
        li {
          width: 14px;
          margin: 0px;
          button:before {
            opacity: 1;
            color: #e0e0e0;
          }
          &.slick-active {
            button:before {
              opacity: 1;
              color: #757575;
            }
          }
        }
      }

      // Repair Details Card

      &.repair_details {
        width: 100%;
        max-width: 100%;
        height: 255px;
        position: relative;

        // Repair Priority Card Label
        .priority_label {
          position: absolute;
          right: 0px;
          top: 16px;
          min-width: 60px;
          height: 20px;
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0px 8px;
          font-size: 10px;
          text-transform: uppercase;
          &.urgent {
            background-color: #fcd7d7;
            color: #ff5656;
          }
          &.high {
            background-color: #ffddbe;
            color: #ff5c00;
          }
          &.medium {
            background-color: #50e3c2;
            color: #ffffff;
          }
          &.low {
            background-color: #ddefbf;
            color: #017c1c;
          }
          &.health_and_safety {
            background-color: #d8f1ff;
            color: #0096eb;
          }
        }

        .inner_section {
          border-top: 0.5px solid #eeeeee;
          &:first-child {
            border: none;
          }
          &.repair-info {
            .ref_number {
              font-size: 12px;
              font-weight: bold;
              color: #9e9e9e;
              @include media_query(M) {
                font-size: 10px;
              }
            }
            .subject_flex_container {
              display: flex;
              align-items: center;
              justify-content: space-between;
              .subject_type_wrapper {
                display: flex;
                align-items: center;
                width: 100%;
              }
            }
            .subject,
            .type {
              font-size: 14px;
              color: #212121;
              @include media_query(M) {
                font-size: 12px;
              }
            }
            .subject {
              margin-right: 3px;
              max-width: 70%;
            }
            .date {
              font-size: 12px;
              font-weight: 300;
              color: #0c0c0c;
              @include media_query(M) {
                font-size: 10px;
              }
            }
          }
          &.airport_door_info {
            padding: 11px 16px;
            // border-top: 0.5px solid #eeeeee;
            // border-bottom: 0.5px solid #eeeeee;
            &.is_draft {
              padding: 20px 16px;
            }
            .info_wrapper {
              display: flex;
              align-items: center;
              margin-bottom: 12px;
              &:last-child {
                margin-bottom: 0px;
              }
              .name {
                width: 90%;
                font-size: 12px;
                color: #212121;
              }
              .icon {
                width: 18px;
                height: 18px;
                margin-right: 12px;
                &.airport_icon {
                  background-image: url('/icons/icon-airport.svg');
                }
                &.door_icon {
                  background-image: url('/icons/icon-door.svg');
                }
                .MuiSvgIcon-root {
                  width: 18px;
                  height: 18px;
                }
              }
            }
          }
          &.repair_status_stepper {
            padding-left: 0px;
            padding-right: 0px;
            // margin: 0px -8px;
            .active_step_date {
              font-size: 12px;
              font-weight: 300;
              color: #0c0c0c;
              @include media_query(M) {
                font-size: 10px;
              }
            }

            // MUI overrided classes
            // Stepper Main Class
            .MuiStepper-root {
              padding: 0px;
              position: relative;

              // CSS to manage last child label position
              .MuiStep-root {
                .MuiStepLabel-labelContainer {
                  .MuiStepLabel-label.MuiStepLabel-alternativeLabel {
                    margin-left: -20px;
                  }
                }
                &:first-child {
                  .MuiStepLabel-labelContainer {
                    .MuiStepLabel-label.MuiStepLabel-alternativeLabel {
                      margin-left: 0px;
                    }
                  }
                }
                &:last-child {
                  .MuiStepLabel-labelContainer {
                    .MuiStepLabel-label.MuiStepLabel-alternativeLabel {
                      right: 10px;
                    }
                  }
                }
              }
            }

            // Stepper Label:  of curent steeper
            // Label position is left align to the stepper root except last element
            .MuiStepLabel-labelContainer {
              .MuiStepLabel-label.MuiStepLabel-alternativeLabel {
                width: max-content;
                position: absolute;
                padding: 5px;
                background-color: rgba(168, 168, 168, 0.12);
                margin-top: 5px;
              }
              // Text
              .MuiTypography-body2 {
                font-size: 12px;
                text-align: center;
                color: #212121;
                @include media_query(M) {
                  font-size: 10px;
                }
              }
            }

            // Stepper Circle : Initial and after complete
            // Circle dimensions
            .MuiSvgIcon-root {
              width: 12px;
              height: 12px;
            }
            //Incompleted step style
            .MuiStepIcon-root {
              border-radius: 50%;
              border: solid 0.5px #757575;
              color: #ffffff;
              // Completed step style
              &.MuiStepIcon-completed {
                background-color: #017c1c;
                color: #017c1c;
                border: none;
              }
            }

            // Stepper Line: connecting line classes
            // Line Style
            .MuiStepConnector-lineHorizontal {
              border-top-style: dashed;
              border-top-width: 0.5px;
            }
            // Line position
            .MuiStepConnector-alternativeLabel {
              top: 6px;
              left: calc(-50% + 6px);
              right: calc(50% + 6px);
            }
            // Line after complete the step
            .MuiStep-completed {
              .MuiStepConnector-line {
                border-color: #00436d;
              }
            }
          }
        }
        @include media_query(M) {
          height: 225px;
        }
      }

      &.saved_view {
        width: 100%;
        height: 200px;
        max-width: 100%;
        position: relative;
        &.location {
          // height: 265px;
          height: 195px;
        }
        &:hover {
          cursor: pointer;
        }
        .action_icons_container {
          display: flex;
          align-items: center;
          justify-content: space-between;
          position: absolute;
          top: 16px;
          right: 16px;
          .icon {
            width: 18px;
            height: 18px;
            cursor: pointer;
            &.edit_icon {
              background-image: url('/icons/icon-edit-view.svg');
              margin-right: 10px;
            }
            &.cross_icon {
              background-image: url('/icons/icon-cross.svg');
            }
          }
        }
        .inner_section {
          &.location_region_info {
            background-color: rgba(19, 113, 171, 0.09);
            .info_wrapper {
              display: flex;
              align-items: center;
              margin-bottom: 12px;
              &:last-child {
                margin-bottom: 0px;
              }
              .name {
                width: 80%;
                font-size: 14px;
                font-weight: 500;
                color: #212121;

                @include media_query(L) {
                  padding-right: 30px;
                }
              }
              .icon {
                width: 18px;
                height: 18px;
                margin-right: 12px;
                &.region_icon {
                  background-image: url('/icons/icon-region.svg');
                  margin-right: 10px;
                }
                &.location_icon {
                  background-image: url('/icons/icon-location.svg');
                }
                &.brand_icon {
                  background-image: url('/icons/icon-brand.svg');
                }
                &.subregion_icon {
                  background-image: url('/icons/icon-subregion.svg');
                }
                .MuiSvgIcon-root {
                  width: 18px;
                  height: 18px;
                }
              }
            }
          }
          &.selected_fields_info {
            .label_value_wrapper {
              margin-bottom: 8px;
              .label_text {
                width: 35%;
              }
              .value_text {
                width: 65%;
              }
            }
          }
        }
        @include media_query(S) {
          width: 93%;
        }
        @include media_query(XS) {
          width: 90%;
        }
      }
      @include media_query(M) {
        height: 235px;
      }
    }
    .draft_label {
      background-color: #c9cffc;
      color: #3e3f47;
      border-radius: 4px;
      width: 40%;
      height: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0px 8px;
      font-size: 10px;
      text-transform: uppercase;

      &.draft_label_landing_page {
        margin: 10px 0px 0px 10px;
      }
    }

    .placeholder_label {
      color: grey;

      &.placeholder_landing_page {
        font-style: italic;
        font-size: 15px;
      }
    }
  }
  &.vm_card_wrapper {
    .card_img {
      border-bottom: 1px solid #e0e0e0;
      display: flex;
      align-items: center;
      justify-content: center;
      .brand_img {
        // width: 180px;
        // height: 60px;

        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  &.vm_details_card_wrapper {
    .card_wrapper {
      .card {
        height: 200px;
      }
      .content_inner_wrapper {
        display: none;
      }
      .thumbnail_img {
        height: 100%;
        width: 100%;
      }
    }
  }
}
