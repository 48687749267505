.coarchmarks_section {
  position: fixed;
  z-index: 1203;
  width: 100%;
  height: 100vh;
  color: $white;
  font-size: 14px;
  background-color: rgba(0, 35, 56, 0.85);
  .lbl {
    font-size: 28px;
    margin-bottom: 20px;
    text-align: center;
    font-weight: 600;
    @include media_query(M) {
      font-size: 22px;
    }
  }
  .txt_lg {
    font-size: 42px;
    font-weight: 500;
    line-height: 1.14;
    margin-bottom: 30px;
  }
  .sub_txt {
    font-size: 18px;
    margin-bottom: 40px;
  }
  .btn_area {
    button {
      @include media_query(M) {
        width: 145px;
      }
    }
  }
  &.welcome_coachmarks {
    .welcome_screen {
      width: 500px;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
      text-align: center;
      .lbl {
        font-weight: normal;
      }

      .btn_area {
        margin-bottom: 36px;
      }
      .skip_link {
        margin-bottom: 8px;
        a {
          text-decoration: underline;
          cursor: pointer;
          font-size: 14px;
        }
      }
      .info {
        @include media_query(M) {
          font-size: 12px;
        }
      }
      @include media_query(M) {
        width: 100%;
        padding: 0 16px;
      }
    }
  }
  &.header_coachmarks {
    position: fixed;
    z-index: 1201;
    padding-top: 260px;
    .inner_wrapper {
      text-align: center;
      .up_arrow {
        width: 40px;
        height: 125px;
        margin: 0 auto;
        margin-bottom: 30px;
        background-image: url('/coachmarks/up_arrow_white.svg');
        background-repeat: no-repeat;
        background-size: contain;
      }
      .lbl {
        margin-bottom: 60px;
      }
    }
  }
  &.header_tabs_coachmarks {
    position: fixed;
    z-index: 1202;
    top: 130px;
    .inner_wrapper {
      text-align: center;
      padding-top: 125px;
    }
    .lbl {
      margin-bottom: 60px;
    }
  }

  &.header_location_coachmarks {
    z-index: 1202;
    text-align: center;
    top: 80px;
    .inner_wrapper {
      padding-top: 60px;
      .up_arrow {
        width: 25px;
        height: 185px;
        margin: 0 auto;
        margin-bottom: 35px;
        background-image: url('/coachmarks/up_arrow_slim.svg');
        background-repeat: no-repeat;
        background-size: contain;
        @include media_query(M) {
          width: 13px;
          height: 95px;
        }
      }
      .lbl {
        margin-bottom: 60px;
        @include media_query(M) {
          display: none;
        }
      }
      .lbl_mb {
        display: none;
        @include media_query(M) {
          display: block;
        }
      }
      @include media_query(M) {
        padding: 0 16px;
        padding-top: 60px;
      }
    }
  }
  &.header_button_coachmarks {
    z-index: 1201;
    text-align: center;
    top: 200px;
    padding-top: 65px;

    .inner_wrapper {
      .arrow_section {
        width: 285px;
        height: 60px;
        display: inline-block;
        .txt {
          display: none;
          @include media_query(M) {
            display: block;
            font-weight: 500;
            font-size: 14px;
          }
        }
        @include media_query(M) {
          width: 112px;
        }
      }
      .up_arrow_curved_right,
      .up_arrow_curved_left,
      .up_arrow {
        width: 35px;
        height: 60px;
        margin: 0 auto;
        display: inline-block;
        margin-bottom: 35px;
        background-repeat: no-repeat;
        background-size: contain;
        @include media_query(M) {
          margin-bottom: 10px;
        }
      }
      .up_arrow_curved_right {
        background-image: url('/coachmarks/right_curved_up_arrow.svg');
      }
      .up_arrow_curved_left {
        background-image: url('/coachmarks/left_curved_up_arrow.svg');
      }
      .up_arrow {
        background-image: url('/coachmarks/up_arrow.svg');
        @include media_query(M) {
          width: 21px;
        }
      }
      .lbl {
        margin-bottom: 60px;
        @include media_query(M) {
          font-size: 22px;
          width: 300px;
          margin: 0 auto;
          margin-bottom: 60px;
          padding-top: 100px;
        }
      }
    }
    @include media_query(M) {
      top: 120px;
      padding: 0 16px;
      padding-top: 65px;
    }
  }
  &.side_nav_coachmarks {
    position: fixed;
    z-index: 1202;
    display: flex;
    align-items: center;
    right: 55px;
    .inner_wrapper {
      width: 525px;
      position: relative;
      left: 250px;
      text-align: center;
      .lbl {
        text-align: center;
        font-weight: 600;
        margin-bottom: 70px;
      }
      .right_arrow {
        width: 200px;
        height: 40px;
        background-image: url('/coachmarks/right_arrow_white.svg');
        background-repeat: no-repeat;
        background-size: contain;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        margin-top: 0;
        margin-right: -40%;
        @include media_query(L) {
          margin-right: -250px;
        }
      }
      @include media_query(L) {
        width: 315px;
        left: 110px;
      }
    }
    .nav_details {
      position: absolute;
      right: 85px;
      top: 120px;
      font-size: 20px;
      text-align: right;
      //new fix for coach mark bottom options
      bottom: 0px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      > ul {
        // margin-bottom: 50px;
      }
      ul {
        li {
          height: 56px;
          line-height: 56px;
        }
      }
    }
  }
  &.bottom_nav_coachmark_mb,
  &.bottom_nav_expanded_coachmark_mb {
    display: none;
    @include media_query(M) {
      position: fixed;
      z-index: 1200;
      display: flex;
      align-items: flex-end;
      padding-bottom: 100px;
      text-align: center;
      .inner_wrapper {
        width: 235px;
        margin: 0 auto;
        .btn_area {
          margin-bottom: 35px;
          button {
            width: 155px;
          }
        }
        .lbl {
          font-size: 22px;
          margin-bottom: 55px;
        }
        .up_arrow {
          width: 13px;
          height: 100px;
          margin: 0 auto;
          margin-bottom: 35px;
          background-image: url('/coachmarks/up_arrow_slim.svg');
          background-repeat: no-repeat;
          background-size: contain;
        }
      }
    }
  }
  &.bottom_nav_expanded_coachmark_mb {
    display: none;
    @include media_query(M) {
      padding-bottom: 150px;
      display: flex;
      .inner_wrapper {
        .down_arrow {
          width: 120px;
          height: 120px;
          margin: 0 auto;
          background-image: url('/coachmarks/down-arrow-white.svg');
        }
      }
    }
  }
  &.saved_view_coachmarks {
    z-index: 1202;
    top: 340px; // Updated by checking UI
    .inner_wrapper {
      width: 625px;
      margin: 0 auto;
      text-align: center;
      .lbl {
        text-align: center;
        font-weight: 600;
        margin-bottom: 70px;
      }

      @include media_query(M) {
        width: 100%;
        padding: 0 16px;
      }
    }
    @include media_query(M) {
      top: 360px;
    }
  }
  &.all_set_coachmarks {
    z-index: 1201;
    text-align: center;
    top: 120px;
    padding-top: 140px;
    .inner_wrapper {
      width: 888px;
      margin: 0 auto;
      .txt_lg {
        margin-bottom: 50px;
      }
      .thumb_icon {
        width: 70px;
        height: 70px;
        margin: 0 auto;
        margin-bottom: 50px;
        background-image: url('/coachmarks/thumb_icon.svg');
        background-repeat: no-repeat;
        background-size: contain;
      }
      .btn_area {
        margin-bottom: 50px;
        button {
          margin: 0 10px;
        }
      }
      .info {
        font-size: 18px;
        @include media_query(M) {
          font-size: 16px;
          line-height: 1.8;
        }
      }
      @include media_query(M) {
        width: 100%;
        padding: 0 16px;
      }
    }
    @include media_query(M) {
      z-index: 1202;
      top: 0;
      display: flex;
      align-items: center;
      padding-top: 0;
    }
  }
}

.coachmarks_area {
  .extra_overlay,
  .extra_overlay_left_patch {
    position: fixed;
    z-index: 1202;
    background-color: rgba(0, 35, 56, 0.85);
  }
  .extra_overlay {
    width: 100%;
    height: 170px;
    top: 0;
    &.extra_overlay_header_mb {
      @include media_query(M) {
        height: 150px;
      }
    }
    @include media_query(M) {
      height: 80px;
    }
  }

  .extra_overlay_left_patch {
    top: 170px;
    height: 40px;
    height: 30px;
    width: 255px;
    @include media_query(L) {
      display: none;
    }
  }
  .right_patch {
    position: fixed;
    z-index: 1217;
    top: 0;
    right: 0;
    width: 165px;
    height: 60px;
    background-color: rgba(0, 35, 56, 0.85);
    @include media_query(M) {
      height: 35px;
    }
  }
}

// Confirm with Varsha.
.transparent_coach_marks_overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1250;
  width: 100%;
  height: 340px;

  &.side_nav_overlay {
    height: 100vh;
    width: 55px;
    right: 0;
    left: auto;
    @include media_query(M) {
      top: auto;
      left: 0;
      width: 100%;
      height: 245px;
    }
  }
  @include media_query(M) {
    height: 200px;
  }
}
