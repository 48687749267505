.sales_insight_main_container {
  margin: 0 -12px;

  .card_container {
    padding: 0 12px;

    &.brand_mix_container {
      .white_box.box_lg {
        @media only screen and (max-width: 1024px) {
          height: 290px;
        }
        @include media_query(M) {
          height: auto;
        }
      }
      .white_box .brand-table-container {
        @include media_query(L) {
          overflow: auto;
        }
      }
    }
    &.retailer_mix {
      .white_box.box_md {
        @media only screen and (max-width: 1024px) {
          height: 480px;
        }
        @include media_query(M) {
          height: 375px;
        }
      }
    }
    @include media_query(M) {
      padding: 0;
    }
  }
  .white_box {
    position: relative;
    border-radius: 8px;
    box-shadow: 0 1px 4px 0.5px rgba(0, 0, 0, 0.08);
    background-color: $white;
    padding: 24px;
    margin-bottom: 24px;
    overflow: hidden;
    &.box_sm {
      height: 290px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &.box_lg {
      height: 480px;
      @include media_query(M) {
        height: auto;
      }
    }
    &.singles {
      height: 162px;
      @media only screen and (max-width: 1024px) {
        height: 258px;
      }
    }
    &.others {
      height: 258px;
    }
    &.box_md {
      height: 375px;
    }
    &.sales {
      @include media_query(M) {
        height: 400px;
      }
    }
    &.no_gutter {
      padding: 0;
    }

    // Brand Mix
    .brand-table-container {
      display: flex;
      height: 100%;
      overflow: hidden;
      .inner-wrapper {
        width: 100%;
        display: flex;
      }
      .tbl-width {
        min-width: 50%;
        width: 100%;
        @include media_query(L) {
          min-width: 70%;
        }
        @include media_query(S) {
          min-width: 100%;
        }
      }
      .tbl-wrapper {
        width: 100%;
        &.no-border {
          border-left: none;
        }
        .header-row {
          height: 54px;
          line-height: 54px;
          font-size: 16px;
          font-weight: 500;
          text-align: left;
          th {
            color: $primary_main;
            padding: 0 15px;
            &:nth-child(1) {
              width: 70%;
            }
            &:nth-child(2) {
              width: 30%;
              text-align: center;
            }
          }
          @include media_query(M) {
            height: 40px;
            line-height: 40px;
          }
        }

        .tbl-body {
          padding: 15px;
          .tbl-row {
            width: 100%;
            height: 43px;
            &:nth-child(odd) {
              background-color: #f0f0f0;
            }
            .tbl-cell {
              padding: 0px 15px;
              vertical-align: middle;
              &:nth-child(1) {
                width: 70%;
              }
              &:nth-child(2) {
                width: 30%;
              }
              .inner-wrapper {
                display: flex;
                align-items: center;
                .color-sqaure {
                  height: 10px;
                  width: 10px;
                  border-radius: 2px;
                  margin-right: 10px;
                }
                .brand-name {
                  // font-size: 14px;
                  font-size: 12px;
                  font-weight: normal;
                  letter-spacing: 0.07px;
                  color: #616161;
                }
                .sales-value {
                  font-size: 10px;
                  letter-spacing: 0.06px;
                  color: #616161;
                  display: flex;
                  align-items: center;
                  .first-value {
                    text-align: right;
                    min-width: 30px;
                    display: flex;
                    align-items: center;
                    .grey-text {
                      color: #9b9b9b;
                      font-size: 9px;
                    }
                  }
                  .second-value {
                    text-align: left;
                    display: flex;
                    align-items: center;
                  }
                  .seperator {
                    padding: 0px 5px;

                    // @include media_query(M) {
                    //   padding: 0px 15px;
                    // }
                  }
                  .percentage_growth {
                    .arrow_icon {
                      top: 2px;
                    }
                  }
                  .arrow-icon {
                    width: 9px;
                    height: 9px;
                    background-image: url('/icons/arrow-gray.svg');
                    background-repeat: no-repeat;
                    background-size: contain;
                    margin: 0px 4px;
                    &.down {
                      transform: rotate(180deg);
                    }
                  }
                }
              }
            }
            @include media_query(M) {
              height: 35px;
            }
          }
        }
        @include media_query(S) {
          border: none;
        }
      }
      @include media_query(S) {
        display: block;
      }
    }

    // Retailer Mix
    .retailer-mix-container {
      position: relative;
      .chartjs-render-monitor {
        height: 400px !important;
        left: 0px;
        right: 250;
        top: 80;
        @include media_query(M) {
          height: 390px !important;
          left: 105px;
        }
      }
      .retailer-graph-container {
        width: 180px;
        margin: 0px 10px;
        background-color: #ffff;
        .graph-height {
          height: 310px;
          border-bottom: 1px solid #e2e2e2;
        }
        .inner-wrapper {
          width: 60px;
          height: 100%;
          margin: 0 auto;
          .stack-box {
            width: 100%;
            height: 100%;
            position: relative;
            display: flex;
            align-items: center;
            &.one {
              background-color: #d8e360;
              height: 30%;
            }
            &.two {
              background-color: #ee4b84;
              height: 10%;
            }
            &.three {
              background-color: #ff8432;
              height: 20%;
            }
            &.four {
              background-color: #8662c5;
              height: 30%;
            }
            &.five {
              background-color: #15bfce;
              height: 10%;
            }
            .data-label-wrapper {
              min-width: 55px;
              position: absolute;
              font-size: 9px;
              letter-spacing: 0.06px;
              color: #a1a1a1;
              display: flex;
              align-items: center;
              &.left-label {
                left: -55px;
                justify-content: flex-end;
              }
              &.right-label {
                right: -55px;
                justify-content: flex-start;
              }
              .line {
                width: 15px;
                height: 1px;
                background-color: #d4d4d4;
              }
              .percent {
                margin: 0px 5px;
              }
            }
          }
        }
        .x-axis-total {
          font-size: 14px;
          color: #a1a1a1;
          text-align: center;
          margin-top: 12px;
          display: flex;
          align-items: center;
          justify-content: center;
          .light-text {
            font-size: 12px;
            color: #a1a1a1;
            margin: 0px 5px;
          }
        }
      }
    }

    // Franchise Sale
    .franchise_sale_container {
      height: 100%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      .chartjs-render-monitor {
        height: 175px !important;
      }
    }

    // Top SKUs
    .top_skus_container {
    }

    // legends container
    .panal-chart-button-container {
      display: flex;

      .panal-chart-button {
        width: 120px;
        border-radius: 4px;
        display: flex;
        margin-right: 5px;
        font-size: 10px;
        justify-content: left;
        align-items: center;
        cursor: pointer;
        text-align: left;
        &.sku_button {
          width: 100%;
        }
        &.sm {
          margin-right: 9px;
          @include media_query(M) {
            width: 70px;
          }
        }
        @include media_query(M) {
          margin-bottom: 10px;
          display: inline-flex;
        }

        @include media_query(S) {
          // height: 40px;
          width: 112px;
          margin-right: 5px;
          margin-bottom: 10px;
        }

        .grey-text {
          color: #9e9e9e;
          font-size: 10px;
          display: flex;
          flex-direction: row;
          align-items: center;
          .percentage_growth {
            display: flex;
            align-items: center;
          }
          .seperator {
            padding: 0px 4px;
          }
        }

        .circle {
          -moz-border-radius: 50px/50px;
          -webkit-border-radius: 50px 50px;
          border-radius: 50px/50px;
          border: solid 3px #202020;
          width: 12px;
          height: 12px;
          margin-right: 7px;
          margin-left: 10px;
        }

        .legend-text-wrap {
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          width: 65px;
          color: #666;
          font-size: 12px;
          &.lg {
            width: 125px;
            @include media_query(M) {
              width: 118px;
            }
          }
          &.sm {
            @include media_query(M) {
              width: 40px;
            }
          }
          @include media_query(S) {
            // width: 58px;
            width: 70px;
            font-size: 10px;
          }
        }
        .blue-sqaure {
          width: 12px;
          height: 12px;
          border-radius: 1px;
          background-color: #1a70c7;
          margin-right: 10px;
          margin-left: 8px;
        }
        .darkBlue-sqaure {
          width: 12px;
          height: 12px;
          border-radius: 1px;
          background-color: #373f51;
          margin-right: 7px;
          margin-left: 11px;
        }
        .sales-value {
          font-size: 10px;
          letter-spacing: 0.06px;
          color: #9b9b9b;
          display: flex;
          align-items: center;
          .first-value {
            display: flex;
            align-items: center;
            justify-content: flex-start;
          }
          .second-value {
            text-align: left;
            display: flex;
            align-items: center;
          }
          .seperator {
            padding: 0px 4px;
          }
          .percentage_growth {
            display: flex;
            align-items: center;
          }
        }
      }
      .panal-chart-button-active {
        background: #f0f1f9;
      }
      .arrow_icon {
        height: 12px;
        width: 12px;
        margin: 0px;
        top: 2px;
      }
      &.terminal-legends-container {
        padding: 25px 30px;
        .column {
          .inner-wrapper {
            display: flex;
            .panal-chart-button {
              margin-bottom: 20px;
              @include media_query(M) {
                margin-bottom: 10px;
              }
            }
          }
        }
        @include media_query(M) {
          display: grid !important;
          position: absolute;
          right: 0;
          top: 30px;
          grid-row-gap: 5px;
          /* left: 0; */
          bottom: 20px;
          margin: auto 24px auto auto;
          margin-right: 0px;
          padding: 0px;
          .MuiGrid-container {
            display: block;
          }
        }
      }
    }

    // Retailer Mix legends
    .retailer-legends-container {
      position: absolute;
      right: 0;
      top: 25px;
      grid-row-gap: 5px;
      left: 55%;
      // @include media_query(S) {
      //   display: block !important;
      //   position: relative;
      //   top: 0;
      //   left: 0;
      // }
      .column {
        .inner-wrapper {
          .panal-chart-button {
            border: 0 !important;
            width: 150px;
            margin-bottom: 17px;
            .legend-text-wrap {
              font-size: 12px;
              width: 120px;
              @include media_query(L) {
                width: 90px;
              }
              @include media_query(S) {
                width: 75px;
              }
            }
            .value-container {
              .sales-value {
                .arrow-icon {
                  width: 9px;
                  height: 9px;
                  background-image: url('/icons/arrow-gray.svg');
                  background-repeat: no-repeat;
                  background-size: contain;
                  margin: 0px 4px;
                  &.down {
                    transform: rotate(180deg);
                  }
                }
                .percentage_growth {
                  .arrow_icon {
                    top: 2px;
                  }
                }
              }
            }
            @include media_query(XL) {
              min-width: 140px;
            }
            @include media_query(L) {
              min-width: 120px;
            }
            @include media_query(S) {
              min-width: 130px;
            }
          }
        }
      }
      @include media_query(M) {
        left: 65%;
      }
    }

    // singles vs sets and trex vs others
    .chart-sm-container {
      height: 240px;
      position: relative;
      &.mix-charts {
        height: 420px;
        overflow: hidden;
        @include media_query(L) {
          min-height: 265px;
          height: auto;
        }
      }
      &.single-charts,
      &.trex-charts {
        height: 100%;
        // &.trex-charts {
        //   height: 100%;
        // }
        .single-sets-wrapper,
        .trex-others-wrapper {
          width: 100%;
          height: 100%;
          padding: 0px 10px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          .graph-seperator {
            opacity: 0.1;
            height: 180px;
            width: 1px;
            background-color: #000;
            position: absolute;
            bottom: 5px;
          }
          .total-text {
            font-size: 12px;
            color: #666666;
            margin-bottom: 12px;
            @include media_query(M) {
              margin-bottom: 8px;
            }
          }
          .capsule-bar {
            width: 92%;
            height: 26px;
            overflow: hidden;
            border-radius: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 5px;
            justify-content: center;
            .single,
            .sets {
              height: 100%;
              color: #fff;
              font-size: 10px;
              display: flex;
              align-items: center;
              background-color: #fbba50;
              padding: 0px 10px;
              border-radius: inherit;
              border-bottom-right-radius: 0px;
              border-top-right-radius: 0px;
            }
            .sets {
              background-color: #343f51;
              justify-content: flex-end;
              border-radius: inherit;
              border-bottom-left-radius: 0px;
              border-top-left-radius: 0px;
            }
            @include media_query(XL) {
              width: 100%;
            }
            @include media_query(M) {
              width: 100%;
            }
          }
        }
        .trex-others-wrapper {
          .capsule-bar {
            // width: 92.5%;
            width: 100%;
            border-radius: inherit;
            .left-section {
              width: 100%;
              height: 100%;
              display: flex;
              align-items: center;
              border-radius: 50px;
              position: relative;
              .single,
              .sets {
                height: 100%;
                border-radius: inherit;
                border-bottom-right-radius: 0px;
                border-top-right-radius: 0px;
                position: absolute;
                padding: 0 5px;
              }
              .sets {
                justify-content: flex-start;
              }
              .single {
                padding: 0;
                right: 0;
                z-index: 1;
              }
            }
            .right-section {
              width: 100%;
              height: 100%;
              display: flex;
              border-radius: 50px;
              position: relative;
              .single,
              .sets {
                border-radius: inherit;
                border-bottom-left-radius: 0px;
                border-top-left-radius: 0px;
                position: absolute;
              }
              .single {
                left: 0px;
                z-index: 1;
                justify-content: flex-end;
              }
            }
          }
          .graph-value-wrapper {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 25px;
            margin-top: 16px;
            .value {
              width: 50%;
              padding-left: 15px;
              .name {
                font-size: 12px;
                color: #616161;
              }
              .percent {
                font-size: 12px;
                color: #9e9e9e;
                display: flex;
                align-items: center;
                .percentage_growth {
                  margin-left: 3px;
                }
              }
            }
            @include media_query(M) {
              margin-top: 10px;
            }
          }
          .legends-wrapper {
            display: flex;
            align-items: center;
            width: 100%;
            .panal-chart-button-container {
              width: 100%;
            }
          }
        }
      }
      &.sales-chart {
        height: 490px;
        &.retailers {
          height: 490px;
          @include media_query(S) {
            height: auto;
          }
        }
      }
      @include media_query(M) {
        height: 300px;
      }

      @include media_query(S) {
        height: auto;
      }

      .chartjs-render-monitor {
        height: 200px !important;
        padding: 15px;

        @include media_query(M) {
          height: 240px !important;
        }

        @include media_query(S) {
          height: 180px !important;
          position: relative;
          top: 0;
          right: 0;
          left: 50px;
          padding: 0px;
        }
      }
    }

    // singles trex legend
    .single-trex-legends-container {
      display: inline-flex;
      justify-content: center;
      margin: 16px 0px;
      margin-bottom: 0px;

      &.trex-others {
        margin: 0px auto;
        &.right {
          margin-left: 15px;
        }
      }
      .column {
        .inner-wrapper {
          display: inline-flex;
          flex-wrap: wrap;
          .panal-chart-button {
            width: 135px;
            margin-bottom: 5px;
            .value-container {
              display: flex;
              align-items: flex-end;
              font-size: 8px;
              .seperator {
                color: #9b9b9b;
                padding: 0px 3px;
              }
            }
            @include media_query(M) {
              margin-top: 0px;
              width: 125px;
            }
          }
        }
      }
      // @include media_query(M) {
      //   margin-top: 0px !important;
      // }
    }
  }
  @include media_query(M) {
    margin: 0;
  }
}

.skubar-chart-container {
  display: grid !important;
  position: absolute;
  right: 0;
  top: 30px;
  grid-row-gap: 5px;
  left: 0;
  bottom: 20px;
  margin: auto;
  margin-right: 24px;
  width: 130px;
  &.sm {
    width: 95px;
    .panal-chart-button {
      width: 100%;
      .legend-text-wrap {
        @include media_query(M) {
          width: 60px;
        }
      }
    }
    @include media_query(M) {
      width: 80px;
    }
  }
  &.lg {
    width: 115px;
    .panal-chart-button {
      width: 100%;
      .legend-text-wrap {
        width: 98px !important;
        &.lg {
          width: 98px !important;
        }
        @include media_query(M) {
          width: 90px !important;
        }
      }
    }
    @include media_query(M) {
      width: 90px;
    }
  }
}

.country_mix_canvas {
  position: relative;
  canvas {
    height: 225px !important;
    position: relative;
    // left: 60px;
    @include media_query(M) {
      left: 0px;
    }
  }
}

.sales-legends-container {
  flex-direction: column;
  .sales_wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 20px;

    .panal-chart-button {
      border-radius: 5px;
      box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
      background-color: $white;
      padding: 4px 10px;
      margin-right: 9px;
      @include media_query(M) {
        width: 70px;
        padding: 0px;
        margin-right: 2px;
        .legend-text-wrap {
          width: 40px;
        }
      }
    }
    .custom_toggle_button {
      @include media_query(M) {
        position: absolute;
        bottom: 30px;
        left: 0px;
        right: 0px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    @include media_query(M) {
      justify-content: space-between;
      margin-bottom: 20px;
      margin-left: -10px;
      margin-right: -10px;
    }
  }
  canvas {
    height: 250px !important;
  }
}

.mob_mix_canvas {
  canvas {
    height: 190px !important;
    position: relative;
    right: 48px;
    @include media_query(XXL) {
      right: 38px;
    }
  }
}
.top_section {
  position: absolute;
  right: 0px;
  top: 0px;
  .search_section {
    // padding: 0;
    .search_icon {
      height: 20px;
      width: 20px;
    }
  }
}

.top_skus_popup {
  .MuiPopover-paper {
    width: 250px;
    min-height: 225px;
    overflow-y: hidden;
  }

  .inner_wrapper {
    .popup_header {
      // height: 45px;
      padding: 7px 11px;
      border-bottom: 0.5px solid #e8e8e8;

      .search_area {
        .MuiInputBase-root {
          width: 100%;
        }
        .MuiInputBase-input {
          width: 100%;
          padding: 10px;
          border: 1px solid #ccc;
          font-size: 12px;
          border-radius: 4px;
          padding: 6px 15px;
        }
      }
    }
    .details_container {
      position: relative;
      height: 100%;
      width: 100%;
      .small_loader {
        &.MuiCircularProgress-root {
          width: 32px !important;
          height: 32px !important;
          position: absolute;
          margin: auto;
          left: 0px;
          right: 0px;
          top: 0px;
          margin-top: 60px;
        }
      }
      .show_text {
        font-size: 12px;
        font-weight: 400;
        color: #a1a1a1;
        padding: 15px;
        padding-bottom: 0px;
        display: flex;
        align-items: center;
        &.back {
          width: auto;
          padding-left: 5px;
          cursor: pointer;
        }
      }
      .empty_text {
        display: flex;
        align-items: center;
        justify-items: center;
        height: 170px;
        padding: 30px 0px;
        font-size: 14px;
      }
      .list {
        padding: 15px 0px 15px 0px;
        list-style: none;
        overflow-y: auto;
        max-height: 150px;
        cursor: pointer;
        padding-top: 10px;
        li {
          margin-bottom: 3px;
          list-style: none;
          border-bottom: 1px solid #ddd;
          font-size: 12px;
          padding: 10px 15px;
          &:last-child {
            border-bottom: none;
          }
          .value {
            display: flex;
            align-items: center;
            .percentage_growth {
              display: flex;
              align-items: center;
              margin-left: 4px;
              .arrow_icon {
                width: 15px;
                height: 15px;
              }
            }
          }
        }
      }
    }
  }
}

//Common classes
// Percent Growth with color, arrow and navigation chevron
.percentage_growth {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  .arrow_icon {
    height: 15px;
    width: 15px;
    margin: 0px 3px;
    top: 1px;
    position: relative;
    &.small {
      height: 12px;
      width: 12px;
      margin: 0px;
    }
  }
  .right_chevron_icon {
    width: 23px;
    height: 23px;
    margin-left: 5px;
  }
}

.custom_toggle_button {
  margin-left: 15px;
  margin-bottom: 2px;
  .MuiToggleButtonGroup-root {
    border-radius: 40px;
    border: 1px solid #00436d;
    .MuiToggleButton-root {
      border-radius: 40px;
      padding: 2px 0px;
      font-size: 12px;
      color: #757575;
      width: 64px;
      .MuiToggleButton-label {
        .MuiTypography-body1 {
          font-size: 12px;
        }
      }
      // &:hover {
      //   background-color: #00436d;
      //   color: #fff;
      // }
      @include media_query(M) {
        width: 46px;
      }
    }
    .MuiToggleButton-root.Mui-selected {
      background-color: #00436d;
      color: #fff;
    }
    .MuiToggleButtonGroup-groupedHorizontal:not(:last-child) {
      border-radius: 40px;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    .MuiToggleButtonGroup-groupedHorizontal:not(:first-child) {
      border-radius: 40px;
      border-left: 1px solid transparent;
      margin-left: -1px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}

.empty_text {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 300px;
  &.large {
    height: 480px;
  }
  &.medium {
    height: 200px;
  }
  &.small {
    height: 100px;
  }
}
