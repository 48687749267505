.tabs_component.capsule_tabs {
  .icon_container {
    display: flex;
    margin-right: 14px;
  }

  // .content_container {
  //   margin: 0 8px;
  // }
  .left_icon_tab {
    &.MuiTab-root {
      .MuiTab-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        & > *:first-child {
          margin-bottom: 0;
        }
      }
    }
  }
  .tabs {
    border-radius: 40px;
    box-shadow: 0 1px 4px 0.5px rgba(0, 0, 0, 0.08);
    background-color: #fff;
    // margin-bottom: 15px;
    @include media_query(M) {
      min-height: 36px;
    }
  }

  .capsule_tab {
    font-weight: 300;
    text-transform: capitalize;
    font-size: 14px;
    padding: 0;
    &.Mui-selected {
      font-weight: 500;
      border-radius: 50px;
      // &:after {
      //   display: none;
      // }
    }
    &:after {
      content: ' ';
      margin: auto;
      width: 1px !important;
      height: 20px !important;
      background-image: linear-gradient(to top, #eeeeee, #bdbdbd, #eeeeee);
    }
    &:last-child {
      &:after {
        display: none;
      }
    }
    @include media_query(M) {
      font-size: 12px;
      min-height: 36px;
    }
  }
}

.tabs_component.segmented_tabs {
  .icon_container {
    display: flex;
    margin-right: 14px;
  }
  .tabs {
    border-radius: 0;
    box-shadow: none;
    background-color: transparent;
    margin-left: -8px;
    margin-right: -8px;
  }
  // .content_container {
  //   margin: 0 8px;
  // }
  .left_icon_tab {
    &.MuiTab-root {
      .MuiTab-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        & > *:first-child {
          margin-bottom: 0;
        }
      }
    }
  }
  .segment_tab {
    margin: 0px 8px;
    border-radius: 8px;
    border: 0.5px solid rgba(0, 0, 0, 0.15);
    line-height: 1.14;
    letter-spacing: 0.75px;
    text-align: center;
    font-size: 16px;

    padding: 6px 12px;

    &.Mui-selected {
      // color: #fff;
      font-weight: 500;
    }
    @include media_query(L) {
      font-size: 14px;
    }
    @include media_query(M) {
      min-height: 60px;
      letter-spacing: 0.15px;
      margin: 0px 6px;
      min-width: auto;
      font-size: 12px;
    }
  }
}

.tabs_component {
  .tabs {
    .Mui-selected {
      &:hover {
        background-color: #002338;
      }
    }
  }
}
