.repair-filter-wrapper {
  width: 415px;
  height: 465px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.08);
  background-color: $white;
  position: relative;
  overflow: auto;
  max-height: 400px;
  .inner-wrapper {
    margin: 37px 24px 0px;
    position: relative;
    // padding-bottom: 80px;
    .type-section {
      margin-bottom: 24px;
    }
    .status-section {
      margin-bottom: 17px;
      .status-section-items {
        margin: 0;
        padding: 0;
        list-style-type: none;
        li {
          position: relative;
          display: flex;
          height: 36px;
          width: 33.3%;
        }
        // li:nth-child(3n + 1) {
        //   width: 100px;
        // }
        // li:nth-child(3n + 2) {
        //   width: 124px;
        // }
        // li:nth-child(3n + 3) {
        //   width: 108px;
        // }
      }
      .centered {
        margin: auto;
        display: flex;
        flex-wrap: wrap;
      }
      .MuiFormControlLabel-root {
        margin-right: 0px;
      }
    }
    .priority-section {
      .priority-button {
        font-weight: 400;
        line-height: 1.43;
        border: 1px solid;
        cursor: pointer;
        height: 32px;
        display: inline-flex;
        font-size: 14px;
        box-sizing: border-box;
        align-items: center;
        border-radius: 16px;
        vertical-align: middle;
        justify-content: center;
        padding-left: 20px;
        padding-right: 20px;
        margin-right: 13px;
        margin-bottom: 11px;
      }
    }
    .section-label {
      margin-bottom: 16px;
      font-size: 14px;
      font-weight: 500;
      line-height: 1.14;
      letter-spacing: 0.4px;
      color: #000000;
      text-transform: uppercase;
    }
    .MuiFormControlLabel-root {
      margin-left: 0px;
      margin-right: 19px;
      .MuiCheckbox-root {
        color: $primary_main;
      }
      .MuiIconButton-root {
        padding: 0px;
        .MuiIconButton-label {
          width: 24px;
          height: 24px;
          padding: 3px;
          margin-right: 8px;
        }
      }
      .MuiIconButton-root:hover {
        background-color: $white;
      }
      .MuiTypography-body1 {
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.67;
        letter-spacing: 0.25px;
        text-align: left;
        color: #616161;
      }
    }
    .month_year_wrapper {
      margin-bottom: 40px;
      @include media_query(M) {
        margin-bottom: 10px;
      }
    }
    @include media_query(M) {
      padding-bottom: 70px;
    }
  }
  .clear-all-button {
    position: absolute;
    right: 23px;
    top: 32px;
    &.MuiButton-root {
      padding: 0px;
      text-transform: none;
      .MuiButton-label {
        font-size: 14px;
        letter-spacing: 0.5px;
      }
    }
  }
  .apply-button {
    width: 100%;
    height: 60px;
    // position: absolute;
    position: sticky;
    bottom: 0;
    border-top: 1px solid #e9e9e9;
    &.MuiButtonBase-root {
      border-radius: 0;
      .MuiButton-label {
        letter-spacing: 0.5px;
      }
    }
    &.MuiButton-textPrimary:hover {
      background-color: #ffffff;
    }
    @include media_query(M) {
      position: fixed;
      left: 0px;
      right: 0px;
    }
  }

  @include media_query(M) {
    max-height: 100%;
  }
}
