.ui-action-background {
  .ui-repair-action-bg {
    width: 100%;
    height: 350px;
    background-image: linear-gradient(to left, #00436d, #002338);
  }

  .radio_btn_wrapper {
    margin-bottom: 20px;
    .MuiSvgIcon-root {
      color: $primary_main;
      @include media_query(M) {
        width: 20px;
        height: 20px;
      }
    }
    .MuiFormControlLabel-label {
      color: #666;
      font-size: 16px;
      @include media_query(M) {
        font-size: 14px;
      }
    }
  }

  .ui-bottom-container {
    position: absolute;
    top: 0px;
    height: 100%;
    width: 100%;

    .ui-repair-action-container {
      width: 800px;
      min-height: 360px;
      margin: auto;
      border-radius: 8px;
      box-shadow: 0 -8px 16px 0 rgba(42, 42, 68, 0.1);
      background-color: #fff;
      padding: 25px 25px;
      position: relative;
      @include media_query(L) {
        width: 85%;
      }
    }

    .ui-repair-action-image {
      width: 275px;
      height: 95px;
      object-fit: contain;
      margin: 30px auto;
      display: grid;
      align-items: center;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        // padding: 30px;
      }
    }

    .ui-info-action-wrapper {
      width: 100%;
      height: 65px;
      min-height: 60px;
      border-radius: 7px;
      background-color: #f6f6f6;
      display: inline-flex;
      align-items: center;
      padding: 12px;

      .info-icon {
        background-image: url('/images/info_icon.svg');
        width: 25px;
        height: 25px;
        display: inline-block;
        background-size: 100%;
        background-repeat: no-repeat;
      }

      .info-text {
        margin-left: 13px;
        font-size: 14px;
        width: 100%;
      }
    }

    .ui-repair-action-title {
      font-size: 22px;
      color: #3d3d3d;
      margin-bottom: 24px;

      .span {
        color: #ff5368;
      }
      @include media_query(L) {
        font-size: 18px;
      }
    }
  }

  .button-container {
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    margin-top: 20px;
    padding-top: 20px;
  }
}

.footer {
  width: 100%;
  margin: auto;
  position: absolute;
  bottom: 0;
  font-size: 16px;
  color: #999;
  text-align: center;
  bottom: 10px;

  @include media_query(S) {
    position: relative;
    bottom: -10px;
    font-size: 12px;
  }

  .footer-wrapper {
    width: 800px;
    margin: auto;

    @include media_query(L) {
      width: 85%;
    }
  }
}

.response-text {
  font-size: 16px;
  width: 100%;
  text-align: center;
  margin-top: 42px;
}
