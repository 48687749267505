.custom_accordion_container {
  width: 100%;
  .heading_text_wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;
    .text {
      font-size: 18px;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.87);
      background-color: $white;
      padding: 0px 5px;
      padding-right: 20px;
      position: absolute;
      width: auto;
      @include media_query(M) {
        font-size: 12px;
        padding-right: 10px;
      }
    }
    .line {
      width: 100%;
      height: 0.3px;
      background-color: #ccc;
      // border: 1px solid #999999;
    }
  }
  .details_text_container {
    font-size: 14px;
    font-weight: normal;
    color: #212121;
    .question_answer_card {
      width: 100%;
      min-height: 80px;
      border-radius: 8px;
      background-color: #f5f5f5;
      box-shadow: none;
      margin-bottom: 12px;
    }
  }

  //MUI Overrided Classes for Accordion
  .MuiAccordion-root {
    box-shadow: none;
  }
  .MuiAccordion-root:before {
    display: none;
  }
  .MuiAccordionDetails-root {
    display: block;
  }
}
