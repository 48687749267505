.drawerContainer {
  overflow: hidden;
  overflow-y: auto;
  position: fixed;
  bottom: 0;

  .top-list-container {
    // margin-top: 23px;
    // margin-bottom: 20px; //needs to be changes as per zeplin
  }
  .MuiList-padding {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .MuiListItem-root {
    height: 56px;
    .MuiListItemIcon-root {
      width: 24px;
      height: 24px;
      min-width: 24px;
      img {
        width: 100%;
        height: 100%;
        object-fit: none;
      }
    }
    &.Mui-selected {
      background-color: #eaf2f7;
    }
  }

  .drawer_links {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 100%;
  }
}
.header_sm ~ .drawerContainer {
  top: 120px;
}
.header_lg ~ .drawerContainer {
  top: 200px;
}
