.custom_dialog_container {
  padding: 24px;

  // MUI Overrided classes of Dialog Box
  .MuiDialog-paper {
    min-width: 50%;
    border-radius: 8px;
    background-color: #f6f6fa !important;
    // Dialog Title
    .MuiDialogTitle-root {
      &.dialog_title_wrapper {
        .title_text {
          font-size: 20px;
          font-weight: 500;
          text-align: center;
          color: #212121;
          @include media_query(M) {
            font-size: 18px;
          }
        }

        .additional_label {
          position: absolute;
          left: 24px;
          top: 20px;
          min-width: 60px;
          height: 25px;
          border-radius: 4px;
          padding: 0 8px;
          font-size: 10px;
          text-transform: uppercase;
          background-color: #c9cffc;
          color: #3e3f47;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .sub_title {
          font-size: 14px;
          text-align: center;
          color: #212121;
          font-weight: 300;
        }
      }
      // Dialog Close Button
      .MuiIconButton-root {
        &.close_button,
        &.calendar_button {
          position: absolute;
          right: 0;
          top: 0;
          color: $primary_main;
        }
        &.calendar_button {
          right: 40px;
          @include media_query(M) {
            right: 30px;
          }
        }
        .MuiSvgIcon-root {
          &.close_button_icon {
            width: 24px;
            height: 24px;
          }
          &.calendar_button_icon {
            padding: 3px;
            width: 24px;
            height: 24px;
          }
        }
      }
    }

    // Dialog Content/ body [Scrollable]
    // .MuiDialogContent-root {
    .dialog_body_wrapper {
      text-align: center;
      padding: 0 24px;
      // MUI Input field
      // .MuiInputBase-root {
      //   // min-width: 325px;
      //   height: 55px;
      //   border-radius: 8px;
      //   font-size: 14px;
      //   color: rgba(102, 102, 102, 0.87);
      //   @include media_query(M) {
      //     height: 48px;
      //   }
      // }

      // MUI Auto complete Input
      .MuiAutocomplete-root {
        margin-bottom: 35px;
        // MUI Label when goes top of input
        .MuiInputLabel-formControl {
          background-color: $white;
          padding-right: 10px;
        }
        .MuiInputBase-root {
          width: 100%;
        }
        &:last-child {
          margin-bottom: 0px;
          min-width: 100%;
          height: 55px;
          border-radius: 8px;
          font-size: 14px;
          color: rgba(102, 102, 102, 0.87);
          @include media_query(M) {
            height: 48px;
          }
        }
        // Label inside Input field
        .MuiInputLabel-outlined {
          font-size: 16px;
          @include media_query(M) {
            font-size: 14px;
          }
        }

        // MUI Auto complete Input
        .MuiAutocomplete-root {
          position: relative;
          // MUI Label when goes top of input
          .MuiInputLabel-formControl {
            background-color: $white;
            padding-right: 10px;
          }
          .MuiInputBase-root {
            width: 100%;
          }
          &:last-child {
            margin-bottom: 0px;
          }
        }
      }
      @include media_query(M) {
        padding: 0 16px;
      }
    }
    // }

    // MUI Bottom Action Buttons [Fixed]
    // .MuiDialogActions-root {
    .dialog_action_btn_wrapper {
      justify-content: center;
      padding: 28px 0;
      .btn_area {
        .trotm_btn {
          margin: 0px 8px;
        }
      }
      @include media_query(M) {
        padding: 16px 0;
      }
    }
    // }

    // MUI Autocomplete-popper
    .MuiAutocomplete-popper {
      top: 10px !important;
      width: 100% !important;
    }
    @include media_query(M) {
      min-width: 100%;
    }
  }

  // Custom all Popups Content common and content specific classes
  .content_wrapper {
    &.info_container {
      padding-top: 24px;
      @include media_query(M) {
        padding-top: 16px;
      }
    }
    .icon_container {
      margin-bottom: 24px;
    }
    .primary_text {
      font-size: 18px;
      color: rgba(0, 0, 0, 0.87);
      @include media_query(M) {
        font-size: 14px;
      }
    }
    .secondary_text {
      font-size: 14px;
      color: #666666;
      @include media_query(M) {
        font-size: 12px;
      }
    }
    .info_text {
      font-size: 14px;
      color: #666666;
      margin-bottom: 12px;
      @include media_query(M) {
        font-size: 12px;
        margin-bottom: 8px;
      }
    }
    .sv_confirmation_container {
      // common classes
      .recipient_form {
        .MuiInputLabel-formControl {
          background-color: #f6f6fa;
          padding-right: 10px;
        }
        .MuiFormControl-fullWidth {
          width: auto;
        }
      }
      // First View of popup
      .info_message_view {
        .recipient_conatiner {
          margin: 35px 0px;
          margin-bottom: 0px;
          .textfield_area .input_field {
            width: 65%;
            margin: auto;
          }
          @include media_query(M) {
            margin: 20px 0px;
            margin-bottom: 0px;
          }
        }
      }
      // Second View of popup
      .selection_view {
        height: 300px;
        .list_selection_form {
          width: 80%;
          margin: auto;
          .field_wrapper {
            position: relative;
          }
        }
      }
    }
    &.form_fields_wrapper {
      background-color: $white;
      padding: 24px;
      &.no_padding {
        padding: 0px;
      }
      .add_store_visit_container,
      .add_repair_container,
      .new_repair_request_container {
        .repairs_form {
          &.list_selection_form {
            height: 300px;
          }
          .field_wrapper {
            margin-bottom: 35px;
            position: relative;
            .add_photo_container {
              .textarea_autosize_label_wrapper,
              .comment_container {
                text-align: left;
              }
            }
            .MuiInputBase-root {
              font-size: 16px;
            }
            &:last-child {
              margin-bottom: 0px;
            }
            .lbl {
              font-size: 16px;
              color: #666666;
              text-align: left;
              .asterisk {
                color: #f86868;
                font-size: 12px;
              }
              @include media_query(M) {
                font-size: 14px;
              }
            }
            &.type_tabs_container {
              .tabs_area .tabs {
                margin-bottom: 0px;
              }
              .tabs_component.segmented_tabs {
                .segment_tab {
                  text-transform: capitalize;
                  min-height: 56px;
                  @include media_query(M) {
                    min-height: 48px;
                  }
                }
              }

              .lbl_with_help {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;

                margin-bottom: 6px;

                .help_icon {
                  width: 20px;
                  height: 20px;

                  margin-left: 5px;
                }
              }
            }
            &.priority_container {
              // Temp To neglect 24px of button bottom
              margin-bottom: 12px;
              .priority_buttons_container {
                text-align: left;
                .btn_wrapper {
                  margin: 0 -12px;

                  @include media_query(M) {
                    margin: 0 -8px;
                  }
                }
                .priority_button {
                  // min-width: 154px;
                  width: calc(33.3% - 24px);
                  height: 56px;
                  border-radius: 8px;
                  margin: 0px 12px 24px 12px;
                  font-size: 16px;
                  display: inline-flex;
                  text-transform: capitalize;

                  &.urgent {
                    border: solid 1px #ff5656;
                    color: #ff5656;
                    &.active {
                      background-color: #ff5656;
                      color: $white;
                    }
                  }
                  &.high {
                    border: solid 1px #ff5c00;
                    color: #ff5c00;
                    &.active {
                      background-color: #ff5c00;
                      color: $white;
                    }
                  }
                  &.medium {
                    border: solid 1px #50e3c2;
                    color: #50e3c2;
                    &.active {
                      background-color: #50e3c2;
                      color: $white;
                    }
                  }
                  &.low {
                    border: solid 1px #017c1c;
                    color: #017c1c;
                    &.active {
                      background-color: #017c1c;
                      color: $white;
                    }
                  }
                  &.health_safety {
                    border: solid 1px #0096eb;
                    color: #0096eb;
                    &.active {
                      background-color: #0096eb;
                      color: $white;
                    }
                  }
                  &:last-child {
                    @include media_query(M) {
                      width: calc(100% - 33.3% - 16px);
                    }
                  }

                  @include media_query(M) {
                    width: calc(33.3% - 16px);
                    height: 48px;
                    font-size: 14px;
                    margin: 0px 8px 16px 8px;
                  }
                }
              }
              @include media_query(M) {
                margin-bottom: 20px;
              }
            }
            &.add_photo_container {
              .added_photos_container {
                display: flex;
                overflow-x: auto;
                .image_container {
                  height: 75px;
                  width: 75px;
                  min-width: 75px;
                  margin-right: 12px;
                  .thumbnail_img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                  }
                }
              }
            }
            // Input Label before and after focus
            .MuiFormControl-root {
              display: flex;
              .MuiInputLabel-outlined {
                font-size: 16px;
                @include media_query(M) {
                  font-size: 14px;
                }
              }
              .MuiInputLabel-outlined.MuiInputLabel-shrink {
                background-color: $white;
                padding-right: 10px;
              }
              .MuiInputLabel-asterisk {
                color: #f86868;
              }
            }
            @include media_query(M) {
              margin-bottom: 24px;
            }
          }
        }
      }
      .add_store_visit_container {
        .repairs_form {
          .field_wrapper {
            margin-bottom: 0px;
          }
        }
      }
      .resubmit_table_container {
        .mui_table_container {
          max-height: 290px;
          .MuiTableHead-root {
            .MuiTableCell-stickyHeader {
              background-color: #fff;
              font-size: 14px;
              font-weight: 500;
              color: #616161;
            }
          }
          .MuiTableBody-root {
            .MuiTableRow-root {
              // &:hover {
              //   background-color: #fff;
              // }
              .MuiTableCell-body {
                font-size: 14px;
                color: #212121;
                max-width: 110px;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
              }
            }
          }
        }
        .placeholder_label {
          font-style: italic;
        }
      }
      .export_brand_form {
        text-align: left;
        height: 300px;
        .custom_search_dropdown_container {
          margin: 0px;
          height: 100%;
          .common_dropdown_list_wrapper {
            top: 0px;
            box-shadow: none;
            height: 100%;
            .search_container {
              padding: 15px 0px;
              .search_icon {
                margin-left: 12px;
              }
            }
            .list_wrapper {
              max-height: 250px;
              .list_item {
                padding: 20px 0px;
              }
            }
          }
        }
        .checkbox_wrapper {
          margin-bottom: 10px;
          &:last-child {
            margin-bottom: 0px;
          }
          .MuiFormControlLabel-label {
            font-weight: 400;
            font-size: 16px;
            letter-spacing: 0.15px;

            color: #666666;
          }
        }
      }
      @include media_query(M) {
        padding: 16px;
      }
    }

    // Repair Mark Completed Popup
    .repair_mark_completed_container {
      text-align: left;
      .primary_text,
      .secondary_text {
        text-align: center;
        font-weight: 400;
      }
      .primary_text {
        @include media_query(M) {
          font-size: 16px;
        }
      }
      .form_wrapper {
        width: 80%;
        margin: auto;
        margin-top: 20px;
        .textarea_autosize_label_wrapper {
          .comment_textarea {
            margin: auto;
            border: 1px solid #b5b5b5 !important;
            margin-top: 25px;
          }
        }
        .add_photo_container {
          .added_photos_container {
            display: flex;
            align-items: center;
            overflow: auto;
            .image_container {
              height: 100px;
              width: 100px;
              min-width: 100px;
              margin-right: 10px;
              &:last-child {
                margin-right: 0px;
              }
            }
          }
        }
        @include media_query(M) {
          width: 100%;
        }
      }
    }
  }
  &.list_dialog {
    &.vendor_list {
      .MuiDialog-paper {
        min-width: 35%;
      }
    }
    .MuiDialog-paper {
      background-color: $white !important;
      max-height: 400px;
      // min-width: 35%;
      .MuiDialogTitle-root {
        border-bottom: 1px solid #eeeeee;
      }
      @include media_query(M) {
        min-width: 100%;
      }
    }
    // No action button
    .dialog_action_btn_wrapper {
      display: none;
    }
    // Vendor list
    .vendor_details_container {
      .list_wrapper {
        padding: 15px 0px;
        .list_item {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          margin-bottom: 15px;
          &:last-child {
            margin-bottom: 0px;
          }
          .index {
            font-size: 14px;
            font-weight: 500;
            color: #9e9e9e;
            margin-right: 12px;
          }
          .value {
            font-size: 14px;
            color: $primary_dark;
          }
        }
      }
    }
    .program_deatils_container {
      padding: 24px 0px;
      min-height: 350px;
      // Program Details Card with variation
      .program-details-card {
        padding: 0px 10px;
        background: #ffffff;
        border-radius: 4px 8px 8px 4px;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
        border-left: 3px solid #50a9b5;
        margin-bottom: 10px;
        cursor: pointer;
        z-index: 1;

        // Small Info Card For Text Only
        &.small {
          width: 100%;
          height: 30px;
          .content-wrapper {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            .info-area {
              width: 100%;
              .prog-name {
                text-align: left;
                max-width: 90%;
              }
            }
          }
        }
        &.active {
          border-left: 5px solid #62b536;
        }
        &.in-active {
          border-left: 5px solid #f7a2a4;
        }
        &.future {
          border-left: 5px solid #fbba50;
        }
      }
      .search_container {
        position: relative;
        margin-bottom: 25px;

        .search_input {
          width: 100%;
          height: 40px;
          background: #ffffff;
          border: 1px solid #00436d;
          box-sizing: border-box;
          box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
          border-radius: 8px;
          padding-left: 40px;
        }
        .search_icon {
          background-image: url('/icons/icon-search.svg');
          height: 20px;
          width: 20px;
          background-repeat: no-repeat;
          background-size: contain;
          position: absolute;
          margin-left: 15px;
          cursor: pointer;
        }
      }
    }
    .sales_flash_container {
      .MuiCard-root {
        box-shadow: none;
      }
    }
  }

  &.table_dialog {
    .MuiDialog-paper {
      .MuiDialogTitle-root {
        padding: 30px 40px;
      }
    }
  }

  &.privacy_dialog {
    .MuiDialog-paper {
      min-width: 60%;
      @include media_query(M) {
        min-width: 100%;
      }
    }
    .content_wrapper {
      padding: 0px;
      iframe {
        width: 100%;
        min-height: 60vh;
      }
    }
    .privacy_content {
      text-align: left;
      font-size: 16px;
      color: #9e9e9e;
      .media_document_wrapper {
        .MuiCard-root {
          box-shadow: none;
        }
      }
      @include media_query(M) {
        font-size: 14px;
      }
    }

    .dialog_action_btn_wrapper {
      display: flex;
      flex-direction: column;
      padding: 24px;
      .privacy_instruction_wrapper {
        width: 100%;
        .instruction_txt {
          font-size: 16px;
          font-weight: 500;
          color: #212121;
          margin-bottom: 16px;
          @include media_query(M) {
            font-size: 14px;
          }
        }
        .checkbox_wrapper {
          &:last-child {
            margin-bottom: 24px;
          }
          .MuiTypography-body1 {
            font-size: 14px;
            color: #212121;
            font-weight: normal;
            @include media_query(M) {
              font-size: 12px;
            }
          }
        }
      }
      @include media_query(M) {
        padding: 16px;
      }
    }
  }
  &.brand_store_dialog {
    .MuiDialog-paper {
      width: 720px;
      max-width: 720px;
      height: 545px;
      .content_wrapper.form_fields_wrapper {
        height: 100%;
        // padding: 0;
        // padding-top: 16px;
        text-align: left;
        overflow: auto;
      }
      .dialog_action_btn_wrapper {
        padding: 0;
        padding-bottom: 24px;
      }
    }
    .brand_store_sizes_popup {
      height: auto;
      .brand_name {
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 10px;
        @include media_query(M) {
          font-size: 16px;
        }
      }
      .html_wrapper {
        padding-left: 20px;
        margin-bottom: 10px;
        font-size: 14px;
      }
      .seperator {
        border: 0.5px solid rgba(0, 0, 0, 0.08);
        margin: 20px 0px;
      }
      ul {
        li {
          list-style-type: initial;
          ul {
            padding-left: 20px;
          }
          &:last-child {
            margin-bottom: 0px;
          }
        }
      }
      .MuiCardMedia-media,
      .media_document_wrapper {
        height: 100%;
      }
      .card.MuiPaper-elevation1 {
        box-shadow: none;
        height: 100%;
      }
      &:last-child {
        .seperator {
          display: none;
        }
      }
    }
  }
  &.on_boarding_dialog {
    padding: 0;
    .MuiDialog-paper {
      min-width: 50%;
      @include media_query(M) {
        min-width: 70%;
      }
      // @include media_query(M2L) {
      //   min-width: 70%;
      // }
      @include media_query(S) {
        min-width: 95%;
      }
    }

    &.hide_backdrop {
      .MuiBackdrop-root {
        background-color: transparent;
      }
    }

    .content_wrapper.form_fields_wrapper {
      @include media_query(M) {
        padding: 16px;
      }
    }
    .top_btns {
      .tabs_section {
        .tabs {
          margin: 0;
          margin-bottom: 35px;
          .segment_tab {
            height: 65px;
            margin: 0px 18px;
            text-transform: capitalize;
            @include media_query(M) {
              height: 40px;
              min-height: 40px;
              margin: 0px 5px;
            }
          }
          @include media_query(M) {
            margin-left: -5px;
            margin-right: -5px;
            margin-bottom: 24px;
          }
        }
      }
    }
    // .MuiInputLabel-formControl {
    //   left: 12px;
    // }
    // .tabs_content_container {
    //   margin: 0 -12px;
    // }
    .save_checkbox {
      color: #666;
      text-align: left;
      padding: 0 18px;
      .MuiTypography-body1 {
        font-size: 14px;
      }
      .MuiSvgIcon-root {
        width: 18px;
        height: 18px;
      }
      @include media_query(M) {
        padding: 0;
      }
    }
    .input_area {
      margin: 0 18px;
      @include media_query(M) {
        margin: 0;
      }
    }
    .input_field {
      width: 100%;
      // padding: 0 12px;

      .MuiInputBase-input {
        cursor: pointer !important;
        font-size: 16px;
      }

      &.block_input {
        .MuiInputBase-input {
          cursor: not-allowed !important;
        }
      }
    }
    .MuiDialog-paper .MuiDialogContent-root.dialog_body_wrapper .MuiInputBase-root {
      min-width: 100%;
      margin-bottom: 12px;
      @include media_query(M) {
        margin-bottom: 16px;
      }
    }
    .dialog_body_wrapper {
      padding: 0 24px;
      @include media_query(M) {
        padding: 0 16px;
      }
    }
    .dialog_action_btn_wrapper {
      flex-direction: column;
      .btn_area {
        margin-top: 0px;
        margin-bottom: 24px;
        @include media_query(M) {
          margin-bottom: 16px;
        }
      }
      .lbl {
        font-size: 14px;
        margin-bottom: 0;
      }
      @include media_query(M) {
        padding: 16px 0;
      }
    }
    .map_list_container {
      margin-bottom: 24px;
      .image_text_container {
        .square_image_container {
          @include media_query(L) {
            width: 110px;
            height: 80px;
          }
          @include media_query(S) {
            width: 95%;
            height: 45px;
          }
        }
      }
      @include media_query(M) {
        margin-bottom: 16px;
      }
    }
    .location_tab_icon {
      background-image: url('/images/img-location.svg');
      background-position: left;
      background-repeat: no-repeat;

      &.Mui-selected {
        background-image: url('/images/img-location-active.svg');
        // fill: '#19567C';
      }
    }
    .region_tab_icon {
      background-image: url('/images/img-region.svg');
      background-position: left;
      background-repeat: no-repeat;

      &.Mui-selected {
        background-image: url('/images/img-region-active.svg');
        // fill: '#19567C';
      }
    }

    .search_area {
      padding: 35px 25px;
      height: 520px;
      .top_area {
        display: flex;
        flex-direction: row;
        align-items: center;

        margin-bottom: 25px;

        .back_icon {
          height: 24px;
          width: 24px;

          margin-right: 12px;

          cursor: pointer;
        }

        .title {
          font-size: 18px;

          letter-spacing: 0.15px;
        }
      }

      .autocomplete_area {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 360px;
        margin: 0 auto;
        // width: 100%;

        .autocomplete {
          // padding: 0 12px;
          width: 100%;
          height: 35px;
          background-color: $white;
          border-radius: 4px;
          .MuiInputBase-root,
          .MuiFormControl-root,
          .MuiInput-underline:before,
          .MuiInput-underline:after {
            font-size: 12px !important;

            border: 0;
            outline: 0;
            padding: 0;
            height: 100%;
            margin-bottom: 0;
          }
          .MuiInputBase-root {
            padding: 0 12px;
          }
        }

        @include media_query(M) {
          width: 100%;
        }
      }
      @include media_query(M) {
        padding: 35px 0;
      }
    }
  }

  &.MOB_popup {
    .MuiDialog-paper {
      min-height: 580px;
      width: 735px;
      min-width: 735px;
      // height: 615px;
      background-color: $white !important;
      .dialog_title_wrapper {
        .title_text {
          text-align: left;
        }
      }
      .dialog_body_wrapper {
        text-align: left;
        padding: 0;
        .categoryname_text {
          display: flex;
          align-items: center;
          margin: 25px 15px;
          margin-top: 0px;
          .MuiSvgIcon-root {
            height: 30px;
            width: 30px;
            margin-right: 10px;
            cursor: pointer;
          }
        }
        .search_area {
          display: flex;
          position: relative;
          width: 80%;
          margin: auto;
          margin-bottom: 25px;
          .search_icon {
            position: absolute;
            left: 10px;
            top: 10px;
            bottom: 5px;
          }
          .MuiInputBase-root {
            width: 100%;
          }
          .MuiInputBase-input {
            width: 100%;
            padding: 10px;
            border: 1px solid #ccc;
            font-size: 14px;
            border-radius: 4px;
            padding: 15px;
            padding-left: 40px;
          }
        }
        .MuiTabs-root {
          margin-bottom: 20px;
          .MuiTabs-scroller {
            .MuiTabs-flexContainer {
              padding: 1px 24px;
              overflow-x: auto;
              margin: 0 -4px;
              button {
                width: 108px;
                height: 50px;
                padding: 8px 12px;
                border-radius: 4px;
                box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
                background-color: $white;
                text-transform: capitalize;
                min-width: 108px;
                margin: 0 4px;
                font-size: 14px;
                color: #212121;
                text-align: left;
                line-height: inherit;
                .MuiTab-wrapper {
                  align-items: initial;
                }
                .sub_title {
                  font-size: 12px;
                  color: #9e9e9e;
                  @include media_query(M) {
                    font-size: 10px;
                  }
                }

                &.Mui-selected {
                  background-color: $primary_main;
                  color: $white;
                  .sub_title {
                    color: $white;
                  }
                }
                @include media_query(M) {
                  min-width: calc(33.3% - 8px);
                  width: calc(33.3% - 8px);
                  flex-grow: 0;
                  max-width: calc(33.3% - 8px);
                  flex-basis: calc(33.3% - 8px);
                  padding: 8px;
                  margin-bottom: 8px;
                }
              }
              @include media_query(M) {
                flex-wrap: wrap;
                overflow-x: hidden;
              }
            }
            .PrivateTabIndicator-root-2 {
              display: none;
            }
          }
        }
        .tab_panel_area {
          .MuiBox-root {
            padding: 0;
          }
        }
      }
      .listing_container {
        .listing_header {
          &.left_spacing {
            padding-left: 40px;
          }
        }
      }
      @include media_query(M) {
        width: 100%;
        min-width: 100%;
      }
      @media (max-device-width: 823px) and (orientation: landscape) {
        min-height: auto;
      }
    }
  }
  &.list_popup {
    .listing_container {
      .listing_header {
        height: 60px;
        line-height: 35px;
      }
      .inner_wrapper {
        max-height: 455px;
      }
    }
  }
  &.sales_popup {
    .MuiDialog-paper {
      background-color: $white !important;
      &.MuiDialog-paperScrollPaper {
        flex-direction: row;
        align-items: center;
      }
      .rotate {
        transform: rotate(90deg) scale(1.2);
        @media (max-device-width: 823px) and (orientation: landscape) {
          transform: none;
          width: 100%;
        }
      }

      .dialog_title_wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #ccc;
        .title_text {
          text-align: left;
        }
        .custom_toggle_button {
          margin-right: 15px;
        }
      }
      .dialog_body_wrapper {
        .panal-chart-button-container {
          display: flex;
          justify-content: flex-end;
          .sales_wrapper {
            margin: 10px;
          }
          .panal-chart-button {
            width: 120px;
            border-radius: 4px;
            display: flex;
            margin-right: 5px;
            font-size: 10px;
            justify-content: left;
            align-items: center;
            cursor: pointer;
            text-align: left;
            @include media_query(M) {
              margin-bottom: 10px;
              display: inline-flex;
              padding: 4px 10px;
            }

            @include media_query(S) {
              // height: 40px;
              width: 112px;
              margin-right: 15px;
              margin-bottom: 10px;
              padding: 4px 10px;
            }

            .grey-text {
              color: #9e9e9e;
              font-size: 10px;
              display: flex;
              flex-direction: row;
              align-items: center;
              .percentage_growth {
                display: flex;
                align-items: center;
              }
              .seperator {
                padding: 0px 4px;
              }
            }

            .legend-text-wrap {
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
              width: 65px;
              color: #666;
              font-size: 12px;
              @include media_query(S) {
                // width: 58px;
                width: 60px;
                font-size: 10px;
              }
            }
            .darkBlue-sqaure {
              width: 12px;
              height: 12px;
              border-radius: 1px;
              background-color: #373f51;
              margin-right: 7px;
              margin-left: 11px;
            }
          }
          .panal-chart-button-active {
            background: #f0f1f9;
          }
        }
      }
    }
  }
  &.full_screen {
    padding: 0;

    .MuiDialog-paper {
      border-radius: 0;

      .MuiDialogContent-root {
        padding: 0;
      }
    }
  }

  //
  .map_list_container {
    margin-bottom: 44px;
  }
  @include media_query(M) {
    padding: 16px;
  }
}

.autocomplete_searcharea_popper {
  width: 100%;
  height: 48px;
  border-radius: 8px;
  background-color: #f6f6fa;
  top: 10px !important;
  z-index: 99999;
  .MuiPaper-elevation1 {
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  }
  .MuiAutocomplete-listbox {
    padding: 0;
    .MuiAutocomplete-option {
      height: 48px;
      max-width: 430px;
      justify-content: space-between;
      .MuiTypography-root {
        font-size: 12px;
      }
      .check_icon {
        width: 20px;
        height: 20px;
        .MuiSvgIcon-root {
          width: 20px;
          height: 20px;
        }
      }
    }
  }
}

//TEMP MUI Autocomplete-popper
// This Code is applying to all .MuiAutocomplete-popper [My View]

// Styling for Popup
// .MuiAutocomplete-popper {
//   top: 10px !important;
//   left: 0px !important;
//   width: 38% !important;
//   margin: auto;
// }
.end_adornment {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;

  cursor: pointer;

  .adornment_text {
    font-size: 12px;
    font-weight: 500;

    line-height: 2;
    letter-spacing: 0.15px;
  }
  .adornment_icon {
    width: 18px;
    height: 18px;
    cursor: pointer;
    background-size: contain;
    background-image: url('/icons/icon-clear-all.svg');
  }
}
