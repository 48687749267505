.store_visit_checklist_container {
  .page_inner_wrapper {
    .checklist_form_section {
      .checklist_wrapper {
        @include media_query(M) {
          background: $white;
          margin-left: -20px;
          margin-right: -20px;
        }
      }
    }
    // Mobile Visible Section Title
    .mob_section_title {
      display: none;
      @include media_query(M) {
        display: block;
        font-size: 14px;
      }
    }

    // UI Components
    // Store Details List
    .store_details_list_container {
      width: 100%;
      background-color: $white;

      .horizontal_list_loader {
        width: 100%;
        height: 116px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .horizontal_list {
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: space-between;
        border-radius: 8px;
        padding: 15px 100px 15px 45px;

        .list_item_with_divider {
          &:last-child {
            .divider {
              display: none;
            }
          }
          &:nth-child(3),
          &:nth-child(7) {
            @include media_query(M) {
              .divider {
                display: none;
              }
            }
          }
          &.date {
            @include media_query(M) {
              display: none;
            }
          }
          // .divider {
          //   display: flex;
          //   margin: 12px 10px 12px 10px;
          // }
          .subsection_wrapper {
            height: 100%;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            .icon {
              width: 30px;
              height: 30px;
              margin-bottom: 6px;
              .MuiSvgIcon-root {
                width: 30px;
                height: 30px;
              }
              &.employee_icon {
                width: 35px;
                height: 25px;
                background-image: url('/icons/icon-employee.svg');
              }
              &.maneger_icon {
                background-image: url('/icons/icon-maneger.svg');
              }
              &.door_icon {
                background-image: url('/icons/icon-door-filled.svg');
              }
              &.brand_icon {
                background-image: url('/icons/icon-door-brand.svg');
              }
              &.sales_growth_icon {
                background-image: url('/icons/icon-sales-growth.svg');
              }
              &.submitted_person_icon {
                background-image: url('/icons/icon-profile.svg');
              }
              &.date_icon {
                background-image: url('/icons/icon-time.svg');
              }
            }
            .edit_text_wrapper {
              position: relative;
              .edit_input {
                width: 80px;
              }
              .icon {
                width: 12px;
                height: 12px;
                cursor: pointer;
                margin-left: 5px;
                margin-bottom: 4px;
                // &.edit_icon {
                //   background-image: url('/icons/icon-edit.svg');
                // }
                // &.tick_icon {
                //   width: 14px;
                //   height: 14px;
                //   background-image: url('/icons/icon-tick.svg');
                // }
              }
            }
            .primary_text {
              font-size: 12px;
              font-weight: 500;
              color: #212121;
              max-width: 140px;
              @include media_query(L) {
                max-width: 120px;
              }
              @include media_query(M) {
                max-width: 90px;
              }
            }
            .secondary_text {
              font-size: 12px;
              font-weight: 500;
              color: #9e9e9e;
            }
            .error_text {
              font-size: 12px;
              font-weight: 500;
              color: #f86868;
              position: absolute;
              bottom: -12px;
            }
          }
          // @include media_query(M) {
          //   min-width: 30%;
          // }
        }

        .mob_divider {
          display: none;
          @include media_query(M) {
            height: 1px;
            width: 100%;
            display: flex;
            margin: 20px 0px;
            background-color: rgba(0, 0, 0, 0.08);
          }
        }
        @include media_query(M) {
          flex-wrap: wrap;
          padding: 25px 15px;
        }
      }
      .mob_date {
        display: none;
        @include media_query(M) {
          display: flex;
          align-items: center;
          border-top: 1px solid #e0e0e0;
          padding: 16px 24px;
          .icon {
            width: 16px;
            height: 16px;
            margin-right: 8px;
            &.date_icon {
              background-image: url('/icons/icon-time.svg');
            }
          }
          .primary_text {
            font-size: 12px;
            font-weight: 500;
            color: #212121;
            max-width: 140px;
            margin: 0px 4px;
          }
          .secondary_text {
            font-size: 12px;
            font-weight: 500;
            color: #9e9e9e;
            margin: 0px 4px;
          }
        }
      }
    }

    // Reference Document List
    .ref_document_list_container {
      width: 100%;
      border-radius: 8px;
      box-shadow: 0 1px 4px 0.5px rgba(0, 0, 0, 0.08);
      background-color: $white;
      .horizontal_list {
        display: flex;
        align-items: center;
        padding: 15px 25px;

        .title_text {
          font-size: 14px;
          color: #212121;
          margin-right: 30px;
          @include media_query(M) {
            display: none;
          }
        }
        .list_item_wrapper {
          width: 60%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: space-around;
          .list_item {
            position: relative;
            .link_wrapper {
              display: flex;
              align-items: center;
              cursor: pointer;
              .icon {
                width: 18px;
                height: 18px;
                margin-right: 10px;
                &.leading_at_counter_icon {
                  background-image: url('/icons/icon-Leading-at-Counter.svg');
                  width: 20px;
                  height: 20px;
                }
                &.guideline_icon {
                  background-image: url('/icons/icon-guideline.svg');
                  width: 20px;
                  height: 20px;
                }
                &.planogram_icon {
                  background-image: url('/icons/icon-planogram.svg');
                }
                &.official_photos_icon {
                  background-image: url('/icons/icon-official-photos.svg');
                }
                @include media_query(M) {
                  display: none;
                }
              }
              .link_text {
                font-size: 14px;
                color: $primary_main;
              }
              @include media_query(M) {
                margin-right: 22px;
                &:last-child {
                  margin-right: 0px;
                }
              }
            }
            .divider {
              display: none;
              @include media_query(M) {
                display: flex;
                position: absolute;
                right: 0;
                top: 0;
                bottom: 0;
                margin: auto;
                width: 1px;
                height: 80%;
                background-image: linear-gradient(to top, #eeeeee, #bdbdbd, #eeeeee);
              }
            }
            @include media_query(M) {
            }
          }
          @include media_query(M) {
            width: 100%;
            justify-content: space-between;
          }
        }
      }
    }

    //Store feedback Form
    .feedback_form_container {
      width: 100%;
      min-height: 640px;
      // flex-grow: 1;
      display: flex;
      border-radius: 8px;
      box-shadow: 0 1px 4px 0.5px rgba(0, 0, 0, 0.08);
      background-color: $white;
      position: relative;
      // Desktop Vertical default tabs view
      .desktop_visible_tabs {
        @include media_query(M) {
          display: none;
        }
        .MuiTabs-root {
          @include media_query(M) {
            display: none;
          }
        }
      }
      // Mobile customised Tab view
      .mob_visible_tabs {
        width: 90%;
        height: auto;
        position: absolute;
        left: 0px;
        right: 0px;
        margin: auto;
        // Selected Value
        .mob_tab_btn {
          display: none;
          @include media_query(M) {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0px 16px;
            width: 100%;
            height: 48px;
            background-color: #00446d;
            color: $white;
            max-width: 100%;
            border-radius: 8px;
          }
        }
        .tab_mob {
          z-index: 10;
        }
        .MuiTabs-root {
          display: none;
          @include media_query(M) {
            display: block;
            position: absolute;
            // z-index: 1;
          }
        }
      }
      // [Vertical-Tabs] MUI Overrided Styling
      .MuiAccordionSummary-root,
      .MuiAccordionDetails-root {
        padding: 0px;
      }
      .MuiTab-wrapper {
        align-items: flex-start;
      }
      .MuiTabs-root {
        width: 225px;
        height: 100%;
        .MuiTabs-scroller {
          .MuiTab-root {
            height: 64px;
            font-size: 14px;
            font-weight: normal;
            color: #212121;
            text-transform: capitalize;
            padding: 0px 24px;
            @include media_query(M) {
              // border-bottom: 1px solid rgba(0, 0, 0, 0.08);
              height: 45px;
              padding: 0px 16px;
            }
          }
          .MuiTab-textColorInherit.Mui-selected {
            background-color: #e7eef3;
            @include media_query(M) {
              background-color: $white;
            }
          }
          .MuiTabs-indicator {
            display: none;
          }
          .MuiTabs-flexContainerVertical {
            @include media_query(M) {
              align-items: flex-start;
            }
          }
        }
        @include media_query(M) {
          width: 100%;
          height: auto;
          margin: auto;
          position: absolute;
          left: 0px;
          right: 0px;
          top: 50px;
          box-shadow: 0 1px 4px 0.5px rgba(0, 0, 0, 0.08);
          background-color: $white;
        }
      }

      //Tab Panel Custom
      .tabpanel_container {
        width: calc(100% - 225px);
        border-left: 1px solid rgba(0, 0, 0, 0.08);
        .panel_wrapper {
          .tab_panel_loader {
            display: flex;
            align-items: center;
            justify-content: center;
            min-height: 640px;
          }
          .tabpanel_header {
            width: 100%;
            border-bottom: 1px solid rgba(0, 0, 0, 0.08);
            font-size: 20px;
            font-weight: 500;
            text-align: left;
            color: rgba(0, 0, 0, 0.87);
            padding: 18px;
            @include media_query(M) {
              display: none;
            }

            // For delete all
            .tabpanel_header_inner_section {
              width: 100%;
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              align-items: center;

              .delete_all {
                color: #00446d;
                font-size: 14px;
                font-weight: 500;
                letter-spacing: 0.5px;
                cursor: pointer;
              }
            }
          }
          .form_wrapper {
            width: 100%;
            height: 100%;
            padding: 20px;
            .instruction_text {
              font-size: 14px;
              color: rgba(0, 0, 0, 0.87);
              margin-bottom: 20px;
            }
          }
          &.people_management {
            height: 100%;
            width: 100%;
          }
          &.store_management {
          }
          &.store_photograph,
          &.competitor_photograph {
            .form_wrapper {
              .top_action_container {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 24px;
                .download_icon_container {
                  .download_icon {
                    width: 24px;
                    height: 24px;
                    background-image: url('/icons/icon-download.svg');
                    cursor: pointer;
                    @include media_query(M) {
                      width: 18px;
                      height: 18px;
                    }
                  }
                }
              }

              // Section 2 View Photo Gallery
              .carousel_with_thumbnail_section {
              }
            }
          }

          // Comment Text Area Style
          &.store_photograph,
          &.competitor_photograph,
          &.comment {
            .comment_textarea {
              background-color: $white;
              border-radius: 8px;
              border: solid 1px #b5b5b5;
            }
          }
        }
        @include media_query(M) {
          width: 100%;
          padding-top: 50px;
        }
      }
      @include media_query(M) {
        padding: 24px 0px;
      }
    }
    @include media_query(M) {
      padding-top: 45px;
    }
  }
}
