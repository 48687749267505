.reference_document_page {
  .title_bar {
    top: 0px;
    padding-right: 20px;
    .back_btn_area {
      .inner_wrapper {
        .title_back {
          margin-right: 7px;
        }
        .page_title {
          @include media_query(M) {
            max-width: 230px;
            display: block;
          }
        }
      }
    }
    @include media_query(M) {
      width: 100% !important;
    }
  }
  .reference_document_container {
    margin: 70px 5px;
    padding: 15px;
    .image_cards_wrapper {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin: 0 -12px;
      .image_card_container {
        margin: 0 12px;
        margin-bottom: 24px;
        width: calc(20% - 24px);
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        @include media_query(L) {
          width: calc(33.3% - 24px);
        }
        @include media_query(M) {
          width: calc(50% - 24px);
          img {
            object-fit: contain;
          }
        }
      }
    }
  }
}
