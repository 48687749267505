@import '../elc-components/styles/style';
@import './common/all';
@import './components/all';
@import './pages/all';

body {
  // background-color: #f6f6fa;
}
.link {
  color: $primary_main;
  font-weight: 500;
  cursor: pointer;
}
.pd_main {
  padding-left: 30px;
  padding-right: 85px;
  @include media_query(XXL) {
    padding-left: 240px;
    padding-right: 240px;
  }
  @include media_query(M) {
    padding-left: 24px;
  }
  @include media_query(M) {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.tabs {
  margin-bottom: 24px;
  .MuiTab-labelIcon {
    min-height: 64px;
  }
}
// Textfield
.textfield_area {
  .input_field {
    width: 100%;
    display: block;
  }
  .MuiInputBase-formControl {
    height: 55px;
    border-radius: 8px;
    font-size: 14px;
    margin-bottom: 35px;
    width: 100%;
  }
  .MuiFormLabel-root {
    font-size: 16px;
  }
}
//
.save_checkbox {
  color: #666;
  text-align: left;
  padding: 0 18px;
  .MuiTypography-body1 {
    font-size: 14px;
  }
  .MuiSvgIcon-root {
    width: 18px;
    height: 18px;
  }
  @include media_query(M) {
    padding: 0;
  }

  // For saved view button
  &.gutter_space {
    margin: 20px 0;
  }
}
.layout_container {
  display: flex;
  flex-direction: column;

  .main_container {
    display: flex;
    flex-direction: row;

    justify-content: space-between;
    padding: 60px 0;
    width: 100%;
    padding-top: 260px;
    &.mini_header_spacing {
      padding-top: 120px;
    }
    .middle_area {
      width: 100%;
      .section {
        &:not(:last-child) {
          margin-bottom: 24px;
        }
      }
    }
    &.vm_details_main_container,
    &.photo_gallery_details_main_container,
    &.store_comments_main_container {
      padding-top: 56px;
      padding-bottom: 0px;
    }
    &.profile_main_container {
      padding-top: 120px;
      padding-bottom: 0px;
    }
    @include media_query(M) {
      padding: 45px 0;
      padding-bottom: 70px;
      padding-top: 165px;
    }
  }
}

.side_navigation {
  width: 55px;
  height: 100vh;
  position: fixed;
  right: 0;
  z-index: 1200;
  .header_lg {
    // height: calc(100vh);
  }
  @include media_query(M) {
    width: 0;
  }
}

.large_header_right_section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.page_title_container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  margin-bottom: 24px;

  .page_title {
    font-size: 22px;
    font-weight: 500;
    letter-spacing: 0.15px;
    color: $primary_dark;

    @include media_query(M) {
      font-size: 16px;
    }
    &.filter_action_container {
      .action_text {
        display: flex;
        align-items: baseline;
        cursor: pointer;
        .primary_text {
          font-weight: 500;
          font-size: 20px;
          margin-right: 7px;
        }
        .secondary_text {
          font-weight: normal;
          font-size: 16px;
          margin-right: 7px;
        }
        .down_arrow {
          background-image: url('/icons/icon-down-arrow.svg');
          width: 21px;
          height: 11px;
        }
      }
      .popover_header_container {
        position: relative;
        // display: flex;
        // align-items: center;
        .abbreviation_text {
          // font-size: 20px;
          // margin-left: 10px;
          font-weight: 500;
          font-size: 16px;
          color: #00436d;
          color: #002338;
          @include media_query(L) {
            font-size: 14px;
          }
          @include media_query(M) {
            font-size: 12px;
          }
        }
      }
    }
  }
  .help_icon_area {
    width: 24px;
    height: 24px;
    .MuiSvgIcon-root {
      color: $primary_dark;
      width: 100%;
      height: 100%;
      cursor: pointer;
    }
    @include media_query(M) {
      width: 20px;
      height: 20px;
    }
  }
  .filter_icon_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 46px;
    height: 46px;
    border-radius: 23px;

    background-color: $primary_main;

    &:hover {
      cursor: pointer;
    }

    .export_icon {
      background-image: url('/icons/icon-export.svg');
      background-position: center;
      background-repeat: no-repeat;

      width: 22px;
      height: 24px;
      position: relative;
      @include media_query(M) {
        background-image: url('/icons/mobile-icons/icon-mob-export.svg');
      }
    }

    .calendar_icon {
      background-image: url('/icons/icon-white-calendar.svg');
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      width: 20px;
      height: 20px;
      position: relative;

      @include media_query(M) {
        background-image: url('/icons/mobile-icons/icon-mob-calendar.svg');
      }
    }

    .filter_icon {
      background-image: url('/icons/icon-filter.svg');
      background-position: center;
      background-repeat: no-repeat;

      width: 24px;
      height: 24px;
      position: relative;
      @include media_query(M) {
        background-image: url('/icons/icon-mob-filter.svg');
      }
      .active {
        width: 6px;
        height: 6px;
        border-radius: 100%;
        flex-grow: 0;
        background-color: #f86868;
        position: absolute;
        top: 0px;
        right: -5px;
      }
    }
    @include media_query(M) {
      width: 20px;
      height: 20px;
      background-color: transparent;
    }
  }

  .right_toggle_filter {
    display: flex;
    align-items: center;
    .custom_toggle_button {
      margin-right: 20px;
      margin-bottom: 0px;
      .MuiToggleButton-root {
        width: 56px;
        @include media_query(M) {
          width: 36px;
        }
      }
    }
  }
  .capitalized_month {
    text-transform: capitalize;
  }
  &.sticky_bar {
    position: sticky;
    top: 120px;
    padding: 0px 10px 12px 10px;
    background-color: #f6f6fa;
    z-index: 1199;
    margin: 0 -10px;
    height: 78px;
    @include media_query(M) {
      height: 110px;
    }
  }
}
//Page Title Area
.title_bar {
  width: 100%;
  height: 56px;
  flex-grow: 0;
  padding: 14px 24px 14px 30px;
  box-shadow: 0 1px 4px 0.5px rgba(0, 0, 0, 0.08);
  background-color: $white;
  position: fixed;
  left: 0;
  top: 64px;
  z-index: 1202;
  display: flex;
  align-items: center;
  padding-left: 30px;
  padding-right: 85px;

  .back_btn_area {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 50%;
    height: 18px;
    .inner_wrapper {
      display: flex;
      align-items: center;
      .back_btn {
        background-image: url('/icons/icon-back.svg');
        width: 13px;
        height: 15px;
        margin-right: 15px;
        cursor: pointer;
        @include media_query(M) {
          width: 10px;
          height: 13px;
        }
      }
      .cross_icon {
        .MuiSvgIcon-root {
          width: 24px;
          height: 24px;
        }
      }
      .page_title {
        font-size: 18px;
        color: $primary_dark;
        display: flex;
        align-items: center;
        cursor: pointer;
        .status {
          min-width: 105px;
          height: 22px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 3px;
          font-size: 12px;
          font-weight: 500;
          margin-left: 15px;
          &.in_progress {
            background-color: #fff2d8;
            color: #ff9900;
            @include media_query(M) {
              background-color: #ff9900;
            }
          }
          &.submitted {
            background-color: #ddefbf;
            color: #017c1c;
            @include media_query(M) {
              background-color: #017c1c;
            }
          }
          &.new {
            background-color: #bfd2ef;
            color: #4e61c9;
            @include media_query(M) {
              background-color: #4e61c9;
            }
          }
          @include media_query(M) {
            min-width: 8px;
            width: 8px;
            height: 8px;
            font-size: 0px;
            padding: 0px;
          }
        }
        @include media_query(M) {
          font-size: 16px;
        }
      }
      .date_text {
        font-size: 14px;
        color: #616161;
      }
    }
  }
  .action_btn_section {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 50%;
    .btn_area {
      display: flex;
      align-items: center;
      .MuiIconButton-root {
        @include media_query(M) {
          padding: 8px;
        }
      }
      .download_icon {
        width: 24px;
        height: 24px;
        background-image: url('/icons/icon-download.svg');
        cursor: pointer;
        // @include media_query(M) {
        //   width: 18px;
        //   height: 18px;
        // }
      }
      .remark_icon {
        width: 24px;
        height: 24px;
        background-image: url('/icons/icon-remark.svg');
        cursor: pointer;
        // @include media_query(M) {
        //   width: 18px;
        //   height: 18px;
        // }
      }
      .trotm_btn {
        padding: 3px 24px;
        &:last-child {
          margin-left: 20px;
        }
        @include media_query(M) {
          min-width: 85px;
        }
      }
      // &.cancel_btn {
      //   margin-right: 20px;
      //   @include media_query(M) {
      //     min-width: 85px;
      //   }
      // }
    }
  }
  // @media only screen and (max-width: 1920px) {
  //   padding-left: 25px;
  //   padding-right: 25px;
  // }
  @include media_query(M) {
    top: 105px;
    padding-left: 15px;
    padding-right: 15px;
  }
}
.download_icon {
  width: 24px;
  height: 24px;
  background-image: url('/icons/icon-download.svg');
  cursor: pointer;
  // @include media_query(M) {
  //   width: 18px;
  //   height: 18px;
  // }
}
.remark_icon {
  width: 24px;
  height: 24px;
  background-image: url('/icons/icon-remark.svg');
  cursor: pointer;
  &.grey {
    background-image: url('/icons/icon-remark-grey.svg');
  }
  // @include media_query(M) {
  //   width: 18px;
  //   height: 18px;
  // }
}
//
.customized_tabs {
  .MuiTabs-root {
    box-shadow: 0 1px 4px 0.5px rgba(0, 0, 0, 0.08);
    background-color: $white;
    margin-bottom: 24px;
    .MuiTab-root {
      &.Mui-selected {
        font-weight: 500;
        color: $primary_main;
      }
    }
    .MuiTabs-scroller {
      .MuiTabs-indicator {
        background-color: $primary_main;
      }
    }
  }
  .MuiTab-root {
    text-transform: capitalize;
    color: #757575;
    font-weight: 300;
    font-size: 14px;
    letter-spacing: 0.15px;
  }
  .title_bar_xs {
    display: none;
    @include media_query(M) {
      display: flex;
      margin-bottom: 16px;
      .tab_title {
        display: flex;
        width: 50%;
      }
      .view_all {
        position: static;
        float: right;
        width: 50%;
        text-align: right;
        display: block;
      }
    }
  }
}

.tabs_area {
  .tabs {
    margin-bottom: 12px;
  }
}

.view_all {
  position: absolute;
  right: 20px;
  top: 0;
  bottom: 0;
  margin: auto;
  height: 26px;
  cursor: pointer;
  color: $primary_main;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.5px;
  // @include media_query(M) {
  //   display: none;
  // }
}
.view_all_link {
  position: relative;
  height: 20px;
  margin-bottom: 15px;
  .view_all {
    right: 3px;
    height: auto;
  }
}
//Align Middle with Overlay
.page_align_middle {
  width: 100%;
  height: 100vh;
  display: flex;
  // flex-direction: column;
  justify-content: center;
  align-items: center;
}
.filter_UI_container {
  .filter_UI {
    @include media_query(M) {
      max-width: 100%;
      max-height: 90%;
      // height: 100%;
      bottom: 0;
      left: 0 !important;
      right: 0;
      border-top-left-radius: 35px;
      border-top-right-radius: 35px;
      min-height: 455px;
      .custom-filter-wrapper {
        width: 100%;
        height: 100%;
      }
    }
    @media (max-device-width: 823px) and (orientation: landscape) {
      min-height: 340px;
    }
    &.location_list_container {
      display: none;
      padding: 24px;
      .title {
        font-weight: 500;
        font-size: 20px;

        color: #212121;
      }
      .card_holder {
        margin-bottom: 12px;
        .media_card_wrapper {
          .card_wrapper {
            .card.saved_view {
              width: 100%;
              height: 235px;
              font-size: 14px;
            }
          }
        }
      }

      @include media_query(M) {
        display: block;
      }
    }
  }
  @include media_query(M) {
    background-color: rgba(0, 0, 0, 0.5);
  }
}

// Add Photo File Input
.add_photo_section {
  .upload_button_container {
    position: relative;
    color: $primary_main;
    border: 1px solid $primary_main;
    padding: 3px 16px;
    // margin-bottom: 24px;
    input {
      display: none;
    }
    > div {
      margin-top: 0 !important;
    }
    label {
      color: $primary_main !important;
      font-size: 12px !important;
    }
    button {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: transparent;
      text-indent: -9000px;
      border-radius: 22px;
      cursor: pointer;
      border: 0;
      color: $primary_main;
      &:focus {
        outline: 0;
      }
      &:hover {
        background-color: transparent;
      }
    }
    .upload_button {
      font-size: 14px;
      font-weight: 500;
      color: $primary_main;
      @include media_query(M) {
        font-size: 12px;
      }
    }
    .MuiSvgIcon-root {
      height: 18px;
      width: 18px;
      @include media_query(M) {
        height: 16px;
        width: 16px;
      }
    }
    @include media_query(M) {
      text-align: right;
    }
  }
}

// MUI Input Label before and after focus
.MuiFormControl-root {
  display: flex;
  .MuiInputLabel-outlined {
    font-size: 16px;
    @include media_query(M) {
      font-size: 14px;
    }
  }
  .MuiInputLabel-outlined.MuiInputLabel-shrink {
    background-color: $white;
    padding-right: 5px;
  }
  .MuiInputLabel-asterisk {
    color: #f86868;
  }
}

//Custom dropdown with active state as a tick with primary text color
.custom_dropdown_container {
  width: 100%;
  max-height: 220px;
  border-radius: 8px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  background-color: $white;
  position: absolute;
  top: 60px;
  z-index: 2;
  &.store_comment_dropdown {
    top: 35px;
  }
  .list_wrapper {
    max-height: 195px;
    overflow-y: auto;
    padding: 0px 12px;
    .list_item {
      width: 100%;
      min-height: 48px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid rgba(33, 33, 33, 0.08);
      font-size: 12px;
      color: rgba(0, 0, 0, 0.87);
      cursor: pointer;
      .item_text {
        font-size: 14px;
      }
      .icon {
        .MuiSvgIcon-root {
          width: 20px;
          height: 20px;
        }
      }
      .date_text {
        color: #9e9e9e;
      }
      &.active {
        color: $primary_main;
      }
    }
  }
}
.is_loading,
.is_error {
  .no_content {
    height: 340px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.loader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}
.center_loader {
  display: flex !important;
  margin: auto;
}
.error_state_container {
  text-align: center;
  .error_img {
    width: 155px;
    height: 150px;
    margin-bottom: 30px;
    background-image: url('/images/img-empty-no-files.svg');
    background-repeat: no-repeat;
    background-size: contain;
  }
  .lbl {
    color: $brownish_gray;
    font-size: 16px;
    font-weight: 400;
  }
  &.full_page {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 400px;
    padding-right: 55px;
    background-color: #fff;
    .error_img {
      width: 180px;
      height: 175px;
      margin-bottom: 40px;
    }
    .lbl {
      font-size: 20px;
      font-weight: 500;
      @include media_query(M) {
        padding: 0 16px;
        font-size: 18px;
      }
    }
    @include media_query(M) {
      padding-right: 0;
    }
  }
  &.component_section {
    padding-right: 0px;
    .lbl {
      font-size: 16px;
    }
  }
}
.center_loader {
  display: flex;
  justify-content: center;
  align-items: center;
}
// Common Code for Remark section with Fixed top-right position
.remarks_right_section {
  width: 30%;
  height: 100%;
  position: fixed;
  right: 0;
  top: 0;
  background-color: #fff;
  .title_bar {
    width: inherit;
    top: 0px;
    right: 0px;
    left: auto;
    background-color: #f6f6fa;
    justify-content: space-between;
    font-size: 18px;
    color: #616161;
    padding: 0px 15px;
    .back_btn_area {
      .action_button {
        &.remark {
          @include media_query(M) {
            display: none;
          }
        }
        &.back {
          display: none;
          @include media_query(M) {
            display: block;
            padding-left: 0px;
          }
        }
      }
    }

    .action_btn_section {
      @include media_query(M) {
        display: none;
      }
    }
  }
  .remarks_wrapper {
    width: 100%;
    height: 100%;
    padding: 80px 24px;
    padding-bottom: 30px;
    overflow-y: auto;
    .image_thumbnail_container {
      display: none;
      @include media_query(M) {
        width: 240px;
        height: 160px;
        display: flex;
        margin: 0px auto 24px;
        .thumbnail_img {
          height: 100%;
          width: 100%;
        }
      }
    }
  }
  @include media_query(M) {
    width: 100%;
  }
}

// Common Border bottom input type
.edit_text_wrapper {
  display: flex;
  align-items: center;
  &.profile_edit_icon {
    justify-content: center;
    .icon {
      margin-bottom: 3px;
    }
  }
  &.store_comment_input {
    position: relative;
  }
  .edit_input {
    width: 130px;
    height: 18px;
    border-bottom: 1px solid #212121;
    font-size: 12px;
    padding: 0px;
  }
  .icon {
    width: 12px;
    height: 12px;
    cursor: pointer;
    margin: 0px 12px;
    &.edit_icon {
      background-image: url('/icons/icon-edit.svg');
    }
    &.tick_icon {
      width: 14px;
      height: 14px;
      background-image: url('/icons/icon-tick.svg');
    }
  }
}

// Image card with action items
.image_container {
  .close_button_container {
    height: 19px;
    width: 19px;
    position: absolute;
    top: 8px;
    right: 8px;
    cursor: pointer;
    overflow: hidden;
    .mui_icon_cancel {
      height: 19px;
      width: 19px;
    }
  }
}

.image_text_container .circle_image_container {
  .MuiAvatar-fallback {
    width: 100%;
    height: 100%;
    padding: 5px;
  }
}
.MuiAutocomplete-noOptions {
  font-size: 12px;
}

.add_photo_field {
  text-align: left;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  .add_photo_input {
    position: relative;
    .upload_button_container {
      margin-bottom: 0px;
      .upload_button_container_required {
        position: absolute;
        top: 0;
        right: 8px;
        color: #f86868;
      }
      .upload_button {
        padding-left: 0px;
      }
      &.store_comment {
        border-radius: 8px;
        background-color: rgba(255, 255, 255, 0.1);
      }
      label {
        justify-content: flex-end;
        cursor: pointer;
        top: 0;
        margin: 20px 16px 0 0px;
        color: #00436d;
        font-weight: 500;
      }

      div {
        margin-top: 0px !important;
      }

      input[type='file'] {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        opacity: 0;
        display: block !important;
        top: -6px;
        cursor: pointer;
        font-size: 0px;
      }

      button {
        display: none !important;
      }
      &.outlined_btn {
        border: 1px solid $primary_main;
        border-radius: 20px;
        padding: 5px 15px;
        text-transform: uppercase;
        font-size: 12px;
      }
      @include media_query(M) {
        text-align: left;
      }
    }
  }
  .validation_text {
    font-size: 14px;
    color: #9e9e9e;
    @include media_query(M) {
      font-size: 11px;
    }
  }
}

.privacy_popup_container {
}

/*React Custom AutoComplete Plugin Override CSS*/
.react-autocomplete-input {
  background-clip: padding-box;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  bottom: auto;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  display: block;
  font-size: 14px;
  list-style: none;
  padding: 1px;
  position: absolute;
  text-align: left;
  z-index: 20000;
  max-height: 200px;
  overflow: auto;
}

.react-autocomplete-input > li {
  cursor: pointer;
  padding: 10px;
  min-width: 100px;
}

.react-autocomplete-input > li.active {
  background-color: #337ab7;
  color: #fff;
}

// Listing UI
.listing_container {
  width: 100%;
  .listing_header {
    height: 80px;
    min-height: 45px;
    position: relative;
    background-color: #f5f5f5;
    padding: 12px 35px;
    font-size: 14px;
    font-weight: 500;
    color: #212121;
    .top_bar {
      font-size: 12px;
      color: #616161;
      font-weight: normal;
      margin-bottom: 10px;
      @include media_query(M) {
        font-size: 10px;
        margin-bottom: 14px;
      }
    }
    .switch_area {
      margin-right: 16px;
      .MuiSwitch-switchBase {
        color: #616161;
      }
      .MuiSwitch-root {
        @include media_query(M) {
          width: 54px;
          padding: 13px;
        }
      }
      .MuiSwitch-thumb {
        @include media_query(M) {
          width: 18px;
          height: 18px;
        }
      }
      .MuiTypography-body1 {
        font-size: 14px;
        color: #616161;
        @include media_query(M) {
          font-size: 10px;
          font-weight: normal;
        }
      }
      .MuiFormControlLabel-labelPlacementStart {
        @include media_query(M) {
          font-size: 10px;
          margin-left: 10px;
          height: 12px;
        }
      }
      @include media_query(M) {
        margin-right: 0px;
      }
    }
    .right_lbl {
      color: #616161;
      @include media_query(M) {
        font-size: 10px;
        margin-left: 5px;
      }
    }
    .back_btn {
      position: absolute;
      width: 25px;
      background-color: $primary_main;
      left: 0;
      top: 0;
      height: 100%;
      cursor: pointer;
      .left_arrow {
        color: $white;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
      }
    }
    @include media_query(M) {
      padding: 12px 16px;
      height: auto;
    }
  }
  .error_state_container {
    height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .inner_wrapper {
    // max-height: 305px;
    max-height: 365px;
    overflow-y: auto;
    &.franchise {
      .percentage_growth {
        .arrow_icon {
          top: 3px;
        }
      }
    }
    ul {
      // max-height: 402px;
      // overflow-y: auto;
      li {
        position: relative;
        height: 60px;
        padding: 0 35px;
        padding-right: 30px;
        font-size: 14px;
        color: #002338;
        border-bottom: 1px solid #eee;
        &:last-child {
          border-bottom: none;
        }
        .MuiGrid-container {
          align-items: center;
          height: 100%;
        }
        @include media_query(M) {
          padding: 0 16px;
        }
      }
    }
  }
  .left_col {
    .serial_no {
      position: absolute;
      font-size: 14px;
      font-weight: 500;
      color: #9e9e9e;
    }
    .product_details {
      padding-left: 30px;
      .name {
        font-size: 14px;
        color: #002338;
      }
      .count {
        font-size: 12px;
        color: #9e9e9e;
        display: flex;
        align-items: center;
        .arrow_icon {
          height: 15px;
          width: 15px;
          margin: 0px 3px;
        }
      }
    }
    .lbl_txt {
      @include media_query(M) {
        font-size: 12px;
      }
    }
  }
  .right_col {
    text-align: right;
    .growth_area {
      .percentage {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        .right_arrow_icon {
          color: gray;
        }
        .arrow_icon {
          width: 15px;
          height: 15px;
          margin-left: 5px;
          .MuiSvgIcon-root {
            width: 15px;
            height: 15px;
          }
        }
      }
      &.upwards {
        color: #2ebe34;
      }
      &.downwards {
        color: #f86868;
      }
    }
    .lbl_txt {
      @include media_query(M) {
        font-size: 12px;
      }
    }
  }
}
// Overrided Material Classes
.MuiPaper-root {
  // Added Popover scroll
  .MuiCard-root {
    overflow: auto;
  }
}
.MuiInputBase-root {
  .MuiInputBase-input {
    color: $black;
  }
}

// Multiselect Popper
.autocomplete_searcharea_popper {
  &.multiselect_popper {
    .action_btn_container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      // justify-content: flex-end;
      padding: 5px 15px;
      .btn_text {
        font-size: 12px;
        color: #00436d;
        cursor: pointer;
      }
    }
    .chips_wrapper {
      padding: 5px 15px;
      max-height: 100px;
      overflow-y: auto;
      &.my_view {
        max-height: 70px;
      }
    }
    .MuiChip-root {
      height: auto;
      padding: 3px 0px;
    }
    .MuiChip-outlined {
      background-color: #00436d;
      color: #fff;
      font-size: 10px;
      margin-right: 8px;
      margin-bottom: 8px;
    }
    .MuiChip-deleteIconOutlinedColorPrimary {
      height: 16px;
      width: 16px;
      color: #fff;
      &:hover,
      &:active {
        color: #fff;
      }
    }
  }
  &.calendar_filter_popper {
    .MuiAutocomplete-option {
      padding: 15px;
      font-size: 12px;
      border-bottom: 1px solid rgba(33, 33, 33, 0.08);
    }
  }
}

.autocomplete_multiple_container {
  width: 405px;
  min-height: 230px;
  border-radius: 8px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  background-color: #ffff;

  ul {
    li {
      display: flex;
      justify-content: space-between;
    }
  }
}

.form_control_autocomplete_multiple {
  font-size: 12px;
  color: #9e9e9e;
}

.MuiDialog-paper {
  &.MuiPaper-root {
    min-width: 30%;
    .location-group-dialog-btn-wrapper {
      &.dialog_action_btn_wrapper {
        padding: 20px 0px;
      }
    }
  }
}

.disabled {
  pointer-events: none;
  opacity: 0.6;
  cursor: not-allowed;
}

.asterisk {
  color: #f86868;
  font-size: 16px;
}
