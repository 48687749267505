.vm_details_container {
  .title_bar {
    top: 0px;
    .back_btn_area {
      .inner_wrapper .page_title {
        @include media_query(M) {
          font-size: 14px;
        }
      }
      @include media_query(M) {
        width: 100%;
      }
    }
  }
  .page_inner_wrapper {
    // Desktop view for Tabs and Panels
    .vertical_tabpanel_container {
      width: 100%;
      display: flex;
      // [Vertical-Tabs] MUI Overrided Styling
      .MuiAccordionSummary-root,
      .MuiAccordionDetails-root {
        padding: 0px;
      }
      .MuiTab-wrapper {
        // align-items: flex-start;
        display: inline-flex;
        align-items: center;
        flex-direction: row-reverse;
        justify-content: space-between;
        font-size: 14px;
        color: #212121;
        word-break: break-all;
      }

      .MuiTabs-root {
        width: 320px;
        height: 100vh;
        background-color: #fff;
        // Desktop Vertical List View
        .desktop_list {
          @include media_query(M) {
            display: none;
          }
        }
        .MuiTabs-scroller {
          .MuiTab-root {
            min-width: 100%;
            height: 64px;
            font-size: 14px;
            font-weight: normal;
            color: #212121;
            text-transform: capitalize;
            padding: 0px 24px;
            margin-bottom: 3px;
            border-left: 3px solid #f86868;
            opacity: 1;
            &.active {
              border-left: 3px solid #009b3e;
            }
            .MuiTab-labelIcon {
              width: 24px;
              height: 24px;
              background-image: url('/icons/icon-arrow-right.svg');
            }
            @include media_query(M) {
              // border-bottom: 1px solid rgba(0, 0, 0, 0.08);
              height: 45px;
              padding: 0px 16px;
            }
          }
          .MuiTab-textColorInherit.Mui-selected {
            background-color: #e7eef3;

            @include media_query(M) {
              background-color: #fff;
            }
          }
          .MuiTabs-indicator {
            display: none;
          }
          .MuiTabs-flexContainerVertical {
            @include media_query(M) {
              align-items: flex-start;
            }
          }
        }
        @include media_query(M) {
          display: none;
        }
      }
      // Custom Class for Tab Panel Content
      .tab_panel_view {
        width: 100%;
        .tabpanel_container {
          width: 100%;
          height: 100%;
          background-color: #f6f6fa;
          padding: 30px;
          .tab_panel_content {
            height: 100%;
          }
          @include media_query(M) {
            padding: 16px;
          }
        }
        @include media_query(M) {
          height: 100vh;
          // Remove this while developing mobile view
          // display: none;
        }
      }
    }

    // Mobile Card List View
    .mob_list {
      display: none;
      @include media_query(M) {
        display: block;
        width: 100%;
        height: 100%;
        padding: 16px;
        .cards_wrapper {
          display: flex;
          flex-wrap: wrap;
        }
      }
    }

    // Details Of attached documnet
    .media_document_wrapper {
      width: 100%;
      height: 100%;
      background-color: #fff;
      .card {
        width: 100%;
        height: 100%;
        background-color: #fff;
        .card_media {
          height: 100%;
          iframe {
            height: 100%;
            width: 100%;
          }
        }
      }
    }
  }
}
