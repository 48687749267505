.photo_gallery_page {
  // .container {
  //   padding: 0 30px;
  // }
  // .private_tabs_container {
  //   margin-left: 30px;
  //   margin-right: 30px;
  //   margin-bottom: 4px;
  // }

  // .month_content {
  //   display: flex;
  //   flex-direction: row;
  //   flex-wrap: wrap;

  //   margin-top: -24px;

  //   .card_container {
  //     margin-right: 24px;
  //     margin-top: 24px;
  //   }
  // }
  .MuiBox-root {
    width: 100%;
  }

  .image_cards_wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0 -12px;
    .image_card_container {
      margin: 0 12px;
      margin-bottom: 24px;
      width: calc(20% - 24px);
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      @include media_query(L) {
        width: calc(33.3% - 24px);
      }
      @include media_query(M) {
        width: calc(50% - 24px);
        img {
          object-fit: contain;
        }
      }
    }
  }
}
.image_container {
  width: 231px;
  height: 233px;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  @include media_query(L) {
    width: 208px;
    height: 208px;
  }
  @include media_query(M) {
    width: 100%;
    height: 215px;
    text-align: center;
  }
}
