.dashboard_container {
  .news_feed_container {
    min-height: 265px;
    height: 265px;
    padding: 24px;
    position: relative;
    .mb_stepper {
      position: absolute;
      bottom: 0;
      width: 100%;
      background: $white;
      .MuiMobileStepper-dots {
        width: 30px;
        height: 4px;
        margin: 0 2px;
        border-radius: 0%;
        display: none;
      }
    }
    .slick-slider {
      height: 100%;
    }
    .slick-slide {
      // padding-left: 2px;
      // padding-right: 2px;
      height: 265px;
      position: relative;
      .MuiGrid-container {
        display: flex !important;
      }
      .left_section {
        border-left: 1px solid transparent;
        padding-right: 24px;
        .product_name {
          font-size: 18px;
          font-weight: 500;
          margin-bottom: 20px;
          line-height: 1.56;
          letter-spacing: 0.25px;
          color: #212121;
          max-height: 85px;
          overflow: hidden;
          &.product_name_only {
            max-height: initial;
            height: 100%;
            display: flex;
            align-items: center;
            margin-bottom: 0;
            font-size: 32px;
            letter-spacing: 0.25px;
            line-height: normal;
            @include media_query(M) {
              font-size: 22px;
            }
          }
          @include media_query(M) {
            font-size: 14px;
          }
        }
        .desc {
          font-size: 11px;
          font-style: italic;
          line-height: 1.45;
          letter-spacing: 0.4px;
          color: #616161;
          margin-bottom: 24px;
          max-height: 75px;
          overflow: hidden;
          @include media_query(M) {
            font-size: 11px;
          }
        }
        .time {
          font-size: 12px;
          font-weight: 300;
          color: #212121;
          letter-spacing: 0.4px;
          position: absolute;
          bottom: 0;
        }
        &.left_section_product_name {
          @include media_query(M) {
            padding-right: 0;
          }
        }
        @include media_query(M) {
          padding-right: 35px;
        }
      }
      // News Card Image Change
      .right_section {
        // padding-left: 24px;
        border-radius: 8px;
        height: 217px;
        max-width: 217px;
        // margin-left: 24px;
        // margin: auto;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 8px;
        }
        @include media_query(M) {
          height: 175px;
          max-width: 175px;
        }
      }
    }
    .slick-dots {
      height: 8px;
      bottom: 35px;
      li {
        width: 8px;
        height: 8px;

        &.slick-active button::before {
          opacity: 1;
          // background-color: $primary_dark;
          background-color: #757575;
          border-radius: 50px;
        }
        button {
          width: 100%;
          height: 100%;
          border-radius: 50px;
          padding: 0;
          // background-color: #e0e0e0;
          border: 1px solid #757575;
          &::before {
            content: ' ';
            width: 100%;
            height: 100%;
          }
        }
      }
      @include media_query(M) {
        bottom: 12px;
      }
    }
    &.MuiPaper-elevation1 {
      box-shadow: 0 1px 4px 0.5px rgba(0, 0, 0, 0.08);
    }
    @include media_query(M) {
      padding: 16px;
      height: 225px;
      min-height: 225px;
    }
    &.updated_news_feed_container {
      position: relative;
      padding: 0px;
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
      .image_wrapper {
        // height: 100%;
        height: 265px;
        width: 100%;
        .background_image {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }

      .sticky_bottom_details {
        width: 100%;
        position: absolute;
        bottom: 0px;
        left: 0px;
        right: 0px;
        background: #ffffff;
        border-top-left-radius: 24px;
        border-top-right-radius: 24px;
        min-height: 80px;
        padding: 15px;
        padding-bottom: 50px;
        .title_text {
          font-weight: 700;
          font-size: 16px;

          letter-spacing: 0.25px;

          color: #000000;
          max-height: 25px;
          overflow: hidden;
          @include media_query(M) {
            font-size: 14px;
            max-height: 45px;
          }
        }
        .info_details_container {
          display: flex;
          align-items: center;
          .info_box {
            // display: flex;
            // align-items: center;
            font-weight: 300;
            font-size: 12px;

            letter-spacing: 0.5px;

            color: #000000;
            max-height: 35px;
            overflow: hidden;
            .time_icon {
              width: 15px;
              height: 15px;
              background-image: url('/icons/icon-time-grey.svg');
              margin-right: 10px;
            }
          }
        }
      }
    }
  }

  .marketing_calendar_card {
    .slick-dots {
      display: flex;
      justify-content: center;
      margin: 0;
      padding: 1rem 0;
      bottom: -5px;
      list-style-type: none;
      li {
        margin: 0;
      }
      button {
        display: block;
        width: 8px;
        height: 8px;
        padding: 0;
        border: none;
        border-radius: 100%;
        background-color: #757575;
        text-indent: -9999px;
      }
      li.slick-active button {
        background-color: #e0e0e0;
      }
    }
  }

  .bottom_tabs_area {
    background-color: $white;
    padding-top: 24px;
    padding-bottom: 30px;
    // margin: 0 -8px;
    .tabs_area {
      .icon_container {
        @include media_query(M) {
          display: none;
        }
      }
      .tabs {
        margin-bottom: 15px;
      }
    }

    .segment_tab_icon {
      width: 24px;
      height: 24px;

      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }

    .store_visit_tab_icon {
      background-image: url('/icons/tab-icons/icon-store-visit.svg');
    }

    .repair_request_tab_icon {
      background-image: url('/icons/tab-icons/icon-repairs.svg');
    }

    .photo_gallery_tab_icon {
      background-image: url('/icons/tab-icons/icon-gallery.svg');
    }

    .vm_tab_icon {
      background-image: url('/icons/tab-icons/icon-vm.svg');
    }
    .Mui-selected {
      .store_visit_tab_icon {
        background-image: url('/icons/tab-icons/icon-store-visit-active.svg');
      }
      .repair_request_tab_icon {
        background-image: url('/icons/tab-icons/icon-repairs-active.svg');
      }
      .photo_gallery_tab_icon {
        background-image: url('/icons/tab-icons/icon-gallery-active.svg');
      }
      .vm_tab_icon {
        background-image: url('/icons/tab-icons/icon-vm-active.svg');
      }
    }
  }

  .center_loader {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
