.image_text_container {
  width: 100%;
  text-align: center;

  .square_image_container {
    width: 132px;
    height: 96px;
    margin: 0 auto 8px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    border-radius: 4px;
    overflow: hidden;
    border: solid 1px #9e9e9e;

    @include media_query(S) {
      width: 100%;
      height: 50px;
    }
    .image {
      height: 100px;
      width: 100px;
      @include media_query(M) {
        width: 100%;
        height: 100%;
        padding: 8px;
      }
    }
    &.active {
      border: solid 2px #00436d;
    }
  }
  .text {
    font-size: 16px;
    @include media_query(M) {
      font-size: 10px;
    }
  }
  .circle_image_container {
    width: 54px;
    height: 54px;
    margin: 0 auto 8px;
    cursor: pointer;

    .MuiAvatar-img {
      resize: contain;
      object-fit: contain;
    }
  }
}
