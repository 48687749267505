.repair_details_container {
  padding: 30px 0px;
  .page_inner_wrapper {
    .section {
      .white_box {
        border-radius: 8px;
        box-shadow: 0 1px 4px 0.5px rgba(0, 0, 0, 0.08);
        background-color: $white;
        padding: 24px;
        &.remarks_container {
          .add_photo_field {
            display: inline-flex;
            margin-left: 16px;
            .add_photo_input .upload_button_container input[type='file'] {
              top: 0;
            }
            .add_photo_input .upload_button_container label {
              font-size: 12px !important;
              margin: 0;
            }
            .add_photo_input .upload_button_container.outlined_btn {
              border: 1px solid rgba(0, 67, 109, 0.5);
              padding: 6px 16px;
            }
          }
        }
        &.details_info_container {
          padding: 24px 16px;
          .inner_wrapper {
            .fields_wrap_container {
              display: flex;
              flex-wrap: wrap;
              .field_wrapper {
                width: 25%;
                margin-bottom: 60px;
                padding: 0px 8px;
                word-break: break-word;
                .lbl {
                  font-size: 14px;
                  font-weight: bold;
                  color: #9e9e9e;
                  margin-bottom: 5px;
                  @include media_query(M) {
                    font-size: 12px;
                  }
                }
                .value {
                  font-size: 16px;
                  color: #212121;
                  @include media_query(M) {
                    font-size: 14px;
                  }
                }
                .added_photos_container {
                  display: flex;
                  overflow-x: auto;
                  .image_container {
                    height: 125px;
                    width: 125px;
                    min-width: 125px;
                    margin-right: 18px;
                    .thumbnail_img {
                      width: 100%;
                      height: 100%;
                      object-fit: cover;
                    }
                    @include media_query(M) {
                      height: 95px;
                      width: 95px;
                      min-width: 95px;
                      margin-right: 10px;
                    }
                  }
                }
                &.full_width {
                  width: 100%;
                }
                .link_container {
                  display: flex;
                  align-items: center;
                  justify-content: flex-start;
                  .seperator {
                    margin: 0px 4px;
                    @include media_query(M) {
                      margin: 0px 2px;
                    }
                  }
                  .link {
                    font-size: 14px;
                    font-weight: 500;
                    color: $primary_main;
                    cursor: pointer;
                    &.disabled {
                      color: #666666;
                      cursor: default;
                    }
                    @include media_query(M) {
                      font-size: 12px;
                    }
                  }
                }
                @include media_query(M) {
                  width: 50%;
                  margin-bottom: 24px;
                }
              }
            }
          }
          @include media_query(M) {
            padding: 16px 8px;
          }
        }
        &.timeline_container {
          // MUI overrided classes
          .step_date {
            font-size: 12px;
            font-weight: 300;
            color: #616161;
            @include media_query(M) {
              text-align: left;
            }
          }
          // Desktop Horizontal + Mobile Vertical Stepper style
          .horizontal_stepper_container,
          .vertical_stepper_container {
            // Stepper Main Class
            .MuiStepper-root {
              padding: 0px;
            }

            // Stepper Label:  of curent steeper
            // Container color
            .MuiStepLabel-labelContainer {
              .MuiStepLabel-label.MuiStepLabel-alternativeLabel {
                margin-top: 5px;
              }
              // Text
              .MuiTypography-body2 {
                font-size: 12px;
                text-align: center;
                color: #212121;
                @include media_query(M) {
                  text-align: left;
                }
              }
            }

            // Stepper Circle : Initial and after complete
            // Circle dimensions
            .MuiSvgIcon-root {
              width: 32px;
              height: 32px;
              .MuiStepIcon-text {
                display: none;
              }
              @include media_query(M) {
                width: 16px;
                height: 16px;
              }
            }
            //Incompleted step style
            .MuiStepIcon-root {
              border-radius: 50%;
              border: solid 0.5px #757575;
              color: $white;
              // Completed step style
              &.MuiStepIcon-completed {
                color: #017c1c;
                background-color: #017c1c;
                border: none;
              }
            }

            // Stepper Line: connecting line classes
            // Line Style
            .MuiStepConnector-lineHorizontal {
              border-top-style: dashed;
              border-top-width: 0.5px;
            }
            // Line position
            .MuiStepConnector-alternativeLabel {
              top: 16px;
              left: calc(-50% + 16px);
              right: calc(50% + 16px);
            }
            // Line after complete the step
            .MuiStep-completed {
              .MuiStepConnector-line {
                border-color: $primary_main;
              }
            }

            @include media_query(M) {
              display: none;
            }
          }
          // Vertical Stepper for Mobile Specific
          .vertical_stepper_container {
            display: none;
            @include media_query(M) {
              display: block;
              .MuiStepper-root {
                padding: 0px;
                position: relative;
              }
              // Label Style
              .MuiStepLabel-labelContainer {
                width: auto;
                .MuiTypography-body2 {
                  position: absolute;
                }
              }
              .MuiStepLabel-root {
                align-items: flex-start;
              }

              // Mobile Vertical Stepper style
              .MuiStepConnector-vertical {
                padding: 0px;
                margin-left: 8px;
                &.MuiStepConnector-completed {
                  .MuiStepConnector-line {
                    border-color: $primary_main;
                  }
                }
              }
              .MuiStepConnector-lineVertical {
                min-height: 70px;
                border-left-style: dashed;
              }
            }
          }
        }
        @include media_query(M) {
          padding: 16px;
        }
      }
    }
  }
  @include media_query(M) {
    padding-top: 55px;
  }
}
