//Label with textarea field
.textarea_autosize_label_wrapper {
  margin-bottom: 24px;
  .label_text {
    font-size: 16px;
    color: rgba(0, 0, 0, 0.87);
    @include media_query(M) {
      font-size: 12px;
    }
  }
  .textarea_field {
    width: 100%;
    padding: 15px;
    border-radius: 8px;
    background-color: #f5f5f5;
    border: none;
    font-size: 14px;
    // color: #9e9e9e;
    font-family: inherit;
    margin-bottom: 12px;
    resize: none;
    color: #000 !important;
  }
  .button {
    font-size: 12px;
    border-radius: 22px;
  }
  .button_loader {
    width: 110px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    .MuiCircularProgress-root {
      width: 30px !important;
      height: 30px !important;
    }
  }
}
