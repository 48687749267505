.login_error_page_container {
  width: 100vw;
  height: 100vh;

  .login_error_page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 100%;

    .main_message {
      font-size: 18px;
      margin-bottom: 24px;
      text-align: center;
    }
    .sub_message {
      font-size: 14px;
      text-align: center;
    }
  }
}
