.profile_details_container {
  .title_bar {
    @include media_query(M) {
      top: 58px;
    }
  }
  .page_inner_wrapper {
    padding: 30px;
    .profile_info_wrapper {
      .card {
        &.profile_card {
          display: flex;
          align-items: center;
          flex-direction: column;
          justify-content: center;
          box-shadow: 0 1px 4px 0.5px rgba(0, 0, 0, 0.08);
          .content_wrapper {
            &.profile_img {
              position: relative;
              .img {
                width: 124px;
                height: 124px;
                background-color: #c4c4c4;
                @include media_query(M) {
                  width: 100px;
                  height: 100px;
                }
              }
              .add_photo_section {
                position: absolute;
                right: 22px;
                bottom: 22px;
                .upload_button_container {
                  label {
                    display: none !important;
                  }
                  &.file_btn {
                    min-width: 22px;
                    width: 22px;
                    height: 22px;
                    padding: 0px;
                    border-radius: 100%;
                    color: $white;
                    background-color: $primary_main;
                  }
                  .camera_icon {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 100%;
                    .MuiSvgIcon-root {
                      width: 14px;
                      height: 14px;
                    }
                  }
                }
              }
            }
            &.profile_info {
              .name,
              .designation {
                font-size: 16px;
                font-weight: 500;
                color: #212121;
                margin-bottom: 0px;
              }
              .designation {
                font-weight: normal;
                color: #616161;
                min-width: 110px;
                max-width: 295px;
                text-align: center;
                padding-right: 16px;
                @include media_query(L) {
                  max-width: 200px;
                }
                @include media_query(M) {
                  min-width: 110px;
                  max-width: 110px;
                }
              }
              .profile_edit_icon {
                position: relative;
                height: 30px;
                .edit_icon {
                  position: absolute;
                  right: 0;
                  margin: 0;
                  margin-bottom: 3px;
                }
                @include media_query(M) {
                  justify-content: flex-start;
                }
              }
              .edit_input {
                height: 30px;
              }
            }
          }
          @include media_query(M) {
            flex-direction: row;
            justify-content: flex-start;
          }
        }
      }
    }
    .right_tabs_container {
      @include media_query(M) {
        padding: 12px 0;
      }
    }
    .right_tabs_area {
      background-color: #fff;
      padding: 24px 20px;
      // .MuiTab-labelIcon {
      //   min-height: 64px;
      // }
      .profile_store_comments {
        .page_title_container {
          @include media_query(M) {
            padding: 0;
          }
        }
        @include media_query(M) {
          padding: 0 20px;
        }
      }
      .cards_area .card_container {
        width: calc(25% - 24px);
        min-width: calc(25% - 24px);
        @include media_query(L) {
          width: calc(33.3% - 24px);
          min-width: calc(33.3% - 24px);
        }
        @include media_query(M) {
          width: calc(50% - 16px);
          min-width: calc(50% - 16px);
        }
      }
      .repair_request_container .repair_cards_wrapper {
        margin-left: 0;
        margin-right: 0;
        width: 100%;
      }
      .monthly_timeline_component {
        width: 100%;
        .month_section.pd_main {
          padding-left: 12px;
          padding-right: 12px;
          @include media_query(M) {
            padding-left: 8px;
            padding-right: 8px;
          }
        }
        @include media_query(M) {
          width: 100%;
        }
      }
      .tabs_area {
        .icon_container {
          @include media_query(M) {
            display: none;
          }
        }
        .tabs {
          margin-bottom: 15px;
        }
      }
      .segment_tab_icon {
        width: 24px;
        height: 24px;

        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
      }
      .store_visit_tab_icon {
        background-image: url('/icons/tab-icons/icon-store-visit.svg');
      }
      .repair_request_tab_icon {
        background-image: url('/icons/tab-icons/icon-repairs.svg');
      }
      .store_comments_tab_icon {
        background-image: url('/icons/tab-icons/icon-store-comments.svg');
      }
      .Mui-selected {
        .store_visit_tab_icon {
          background-image: url('/icons/tab-icons/icon-store-visit-active.svg');
        }
        .repair_request_tab_icon {
          background-image: url('/icons/tab-icons/icon-repairs-active.svg');
        }
        .store_comments_tab_icon {
          background-image: url('/icons/tab-icons/icon-store-comments-active.svg');
        }
      }
      .repair_request_container .repair_cards_wrapper .repair_card_container {
        @include media_query(M) {
          margin-left: 12px;
          margin-right: 12px;
          margin-bottom: 16px;
          padding-left: 12px;
          padding-right: 12px;
        }
      }
      .tabs_section {
        @include media_query(M) {
          padding: 0 16px;
        }
      }
      .page_title_container {
        @include media_query(M) {
          padding: 0 16px;
        }
      }
      .monthly_timeline_component .month_section .title_view {
        @include media_query(M) {
          padding: 0 16px;
          padding-top: 8px;
        }
      }
      .monthly_timeline_component .month_section .content .cards_area {
        @include media_query(M) {
          padding: 0 16px;
        }
      }
      @include media_query(M) {
        padding: 24px 0;
      }
    }
    @include media_query(M) {
      padding: 16px;
    }
  }
}
