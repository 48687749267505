/* FONT IMPORTS */

// @import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,700);
// @import url(https://fonts.googleapis.com/icon?family=Material+Icons);

// .icon {
//   font-family: 'Material Icons', serif;
//   font-style: normal;
//   display: inline-block;
//   vertical-align: middle;
//   line-height: 1;
//   text-transform: none;
//   letter-spacing: normal;
//   word-wrap: normal;
//   white-space: nowrap;
//   direction: ltr;

//   -webkit-font-smoothing: antialiased;
//   text-rendering: optimizeLegibility;
//   -moz-osx-font-smoothing: grayscale;
//   font-feature-settings: 'liga';
// }

/* VARIABLES */

:root {
  --main-color: #1a8fff;
  --text-color: #777;
  --text-color-light: #ccc;
  --border-color: #eee;
  --bg-color: #f9f9f9;
  --neutral-color: #fff;
}

/* GENERAL */

// * {
//   box-sizing: border-box;
// }

// body {
//   font-family: 'Open Sans', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
//   font-size: 1em;
//   font-weight: 300;
//   line-height: 1.5;
//   color: var(--text-color);
//   background: var(--bg-color);
//   position: relative;
// }

// header {
//   display: block;
//   width: 100%;
//   padding: 1.75em 0;
//   border-bottom: 1px solid var(--border-color);
//   background: var(--neutral-color);
// }

// header #logo {
//   font-size: 175%;
//   text-align: center;
//   color: var(--main-color);
//   line-height: 1;
// }

// header #logo .icon {
//   padding-right: 0.25em;
// }

// main {
//   display: block;
//   margin: 0 auto;
//   margin-top: 5em;
//   max-width: 50em;
// }

/* GRID */

.row {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

// .row-middle {
//   align-items: center;
// }

// .col {
//   flex-grow: 1;
//   flex-basis: 0;
//   max-width: 100%;
// }

// .col-start {
//   justify-content: flex-start;
//   text-align: left;
// }

// .col-center {
//   justify-content: center;
//   text-align: center;
// }

// .col-end {
//   justify-content: flex-end;
//   text-align: right;
// }

/* Calendar */

// .calendar {
//   display: block;
//   position: relative;
//   width: 100%;
//   background: var(--neutral-color);
//   border: 1px solid var(--border-color);
// }

// // .calendar .header {
// //   text-transform: uppercase;
// //   font-weight: 700;
// //   font-size: 115%;
// //   padding: 1.5em 0;
// //   border-bottom: 1px solid var(--border-color);
// // }

// // .calendar .header .icon {
// //   cursor: pointer;
// //   transition: 0.15s ease-out;
// // }

// // .calendar .header .icon:hover {
// //   transform: scale(1.75);
// //   transition: 0.25s ease-out;
// //   color: var(--main-color);
// // }

// // .calendar .header .icon:first-of-type {
// //   margin-left: 1em;
// // }

// // .calendar .header .icon:last-of-type {
// //   margin-right: 1em;
// // }

// .calendar .days {
//   text-transform: uppercase;
//   font-weight: 400;
//   //   color: var(--text-color-light);
//   color: #00436d;
//   font-weight: bold;
//   font-size: 16px;
//   padding: 0.75em 0;
//   border-bottom: 1px solid var(--border-color);
// }

// .calendar .body .cell {
//   position: relative;
//   height: 5em;
//   border-right: 1px solid var(--border-color);
//   overflow: hidden;
//   cursor: pointer;
//   background: var(--neutral-color);
//   transition: 0.25s ease-out;
// }

// .calendar .body .cell:hover {
//   background: var(--bg-color);
//   transition: 0.5s ease-out;
// }

// // .calendar .body .selected {
// //   border-left: 10px solid transparent;
// //   border-image: linear-gradient(45deg, #1a8fff 0%, #53cbf1 40%);
// //   border-image-slice: 1;
// // }
// .calendar .body .cell_selected {
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   width: 65px;
//   height: 65px;
//   border-radius: 50%;
//   background: #3f8cff;
//   color: #fff;
// }

// .calendar .body .row {
//   border-bottom: 1px solid var(--border-color);
// }

// .calendar .body .row:last-child {
//   border-bottom: none;
// }

// .calendar .body .cell:last-child {
//   border-right: none;
// }

// .calendar .body .cell .number {
//   /* position: absolute; */
//   font-size: 20px;
//   line-height: 1;
//   /* top: 0.95em; */
//   /* right: 1.65em; */
//   //   font-weight: 700;
//   font-weight: normal;
// }

// .calendar .body .disabled {
//   color: var(--text-color-light);
//   pointer-events: none;
// }

// .calendar .body .cell .bg {
//   font-weight: 700;
//   line-height: 1;
//   color: var(--main-color);
//   opacity: 0;
//   font-size: 8em;
//   position: absolute;
//   top: -0.2em;
//   right: -0.05em;
//   transition: 0.25s ease-out;
//   letter-spacing: -0.07em;
// }

// .calendar .body .cell:hover .bg,
// .calendar .body .selected .bg {
//   opacity: 0.05;
//   transition: 0.5s ease-in;
// }

// .calendar .body .col {
//   flex-grow: 0;
//   flex-basis: calc(100% / 7);
//   width: calc(100% / 7);
//   display: flex;
//   justify-content: center;
//   align-items: center;
// }

// Sass
.month_calendar_container {
  display: block;
  position: relative;
  // width: 100%;
  background: var(--neutral-color);
  border: 1px solid var(--border-color);
  .days {
    text-transform: uppercase;
    font-weight: 400;
    //   color: var(--text-color-light);
    color: #00436d;
    font-weight: bold;
    font-size: 16px;
    padding: 0.75em 0;
    border-bottom: 1px solid var(--border-color);
    border-top: 1px solid var(--border-color);
    &.row {
      .col {
        flex-grow: 1;
        flex-basis: 0;
        max-width: 100%;
        &.col_start {
          justify-content: flex-start;
          text-align: left;
        }

        &.col_center {
          justify-content: center;
          text-align: center;
        }

        &.col_end {
          justify-content: flex-end;
          text-align: right;
        }
      }
    }
    @include media_query(M) {
      background-color: #f6f6fa;
    }
  }
  .body_wrapper {
    .row {
      border-bottom: 1px solid var(--border-color);
      &:last-child {
        border-bottom: none;
      }
      .col {
        flex-grow: 0;
        flex-basis: calc(100% / 7);
        width: calc(100% / 7);
        display: flex;
        justify-content: center;
        align-items: center;
        &.cell {
          position: relative;
          height: 5em;
          border-right: 1px solid var(--border-color);
          overflow: hidden;
          cursor: pointer;
          background: var(--neutral-color);
          transition: 0.25s ease-out;
          text-align: center;
          &:last-child {
            border-right: none;
          }
          &:hover {
            // opacity: 0.05;
            background-color: #f4f7fc;
            transition: 0.5s ease-in;
          }
          .number {
            font-size: 20px;
            line-height: 1;
            font-weight: normal;
            @include media_query(M) {
              font-size: 18px;
              font-weight: bold;
            }
          }
          .cell_current {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 65px;
            height: 65px;
            border-radius: 50%;
            background: #3f8cff;
            color: #fff;
            @include media_query(M) {
              width: 40px;
              height: 40px;
              border-radius: 12px;
            }
          }
          .cell_selected {
            // display: flex;
            // align-items: center;
            // justify-content: center;
            width: 65px;
            height: 65px;
            line-height: 65px;
            border-radius: 50%;
            background: #fff;
            border: 1px solid #3f8cff;
            color: #3f8cff;
            @include media_query(M) {
              width: 40px;
              height: 40px;
              line-height: 40px;
              border-radius: 12px;
            }
          }
          .dots_container {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 10px 0px;
            position: absolute;
            bottom: 10px;
            left: 0px;
            right: 0px;
            text-align: center;
            .dot {
              height: 8px;
              width: 8px;
              border-radius: 50%;
              background-color: #3f8cff;
              margin: 0px 2px;
            }
            @include media_query(M) {
              bottom: 12px;
            }
          }
          @include media_query(M) {
            border: none;
          }
        }
        &.disabled {
          color: var(--text-color-light);
          pointer-events: none;
        }
        &.selected {
          // opacity: 0.05;
          // transition: 0.5s ease-in;
        }
      }
      @include media_query(M) {
        border: none;
      }
    }
  }
  @include media_query(M) {
    margin: 0px 0px;
  }
}
