.monthly_timeline_component {
  display: flex;
  flex-direction: column;

  .month_section {
    display: flex;
    flex-direction: column;
    padding-top: 24px;
    padding-bottom: 0;
    // padding: 20px 100px;
    &:first-child {
      padding-top: 0;
    }
    &:nth-child(odd) {
      background-color: #f6f6fa;
    }

    &:nth-child(even) {
      background-color: $white;
    }

    .title_view {
      font-size: 18px;
      font-weight: 500;
      line-height: 1.56;
      letter-spacing: 0.5px;
      color: #212121;
      margin-bottom: 12px;
      .month_title {
        text-transform: capitalize;
      }
      @include media_query(M) {
        font-size: 14px;
      }
    }
    @include media_query(M) {
      padding-top: 16px;
    }
  }
}
