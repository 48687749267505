.repair_request_container {
  .repair_cards_wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    height: 100%;
    margin-left: -12px;
    margin-right: -12px;
    // margin-top: -9px; // (repair_card top) 24px - 35px (UX gap)

    .repair_card_container {
      cursor: pointer;
      margin-left: 12px;
      margin-right: 12px;
      margin-bottom: 24px;
      @include media_query(S) {
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 16px;
      }
    }
    @include media_query(S) {
      margin-left: 0;
      margin-right: 0;
    }
  }
}
