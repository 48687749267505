.question_answer_card {
  width: 100%;
  min-height: 80px;
  border-radius: 8px;
  background-color: #f5f5f5;
  box-shadow: none;
  margin-bottom: 12px;
  .content_wrapper {
    width: 100%;
    .question_text {
      font-size: 14px;
      color: #212121;
      @include media_query(M) {
        font-size: 12px;
      }
    }
    .answer_wrapper {
      // MUI overrided Classes for Radio buttons
      .MuiFormGroup-root {
        flex-wrap: nowrap;
        flex-direction: row;
      }
      .MuiSvgIcon-root {
        width: 16px;
        height: 16px;
        color: $primary_main;
      }
      .MuiFormControlLabel-label {
        font-size: 14px;
        color: #666666;
      }
    }
  }
  .MuiCardContent-root:last-child {
    padding-bottom: 16px;
  }
}
