.login_page {
  // width: 100%;
  // height: 100vh;
  // display: flex;
  // flex-direction: column;
  // justify-content: center;
  // align-items: center;
  // opacity: 0.8;
  // background-color: $primary_dark;
  background-image: url(/images/img-app-background.svg);
  background-repeat: no-repeat;
  background-size: cover;

  .card_container {
    // width: 470px;
    // height: 365px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    background-color: #f6f6fa;
    z-index: 1;
    position: relative;

    .inner_wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;

      .app_logo_wrapper {
        width: 300px;
        height: 100px;
        margin-bottom: 70px;

        .app_logo {
          width: 100%;
          height: 100%;
        }
      }

      .login_button {
        width: 150px;
        border-radius: 22px;
      }
    }
  }
}
