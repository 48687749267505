.trotm_btn {
  &.MuiButton-root {
    min-width: 105px;
    border-radius: 22px;
    font-size: 12px;
    font-weight: 500;
    padding: 9px 24px;
    @include media_query(M) {
      padding: 6px 14px;
      min-width: 86px;
    }
  }
  &.white_btn {
    font-size: 14px;
    font-weight: 500;
    background-color: $white;
    color: $primary_main;
    &:hover {
      background-color: $white;
    }
  }
  &.btn_sm {
    padding: 3px;
  }
}
