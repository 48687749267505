.comment_container {
  width: 100%;
  margin-bottom: 24px;
  .top_bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .date_text {
      font-size: 14px;
      color: #9e9e9e;
      margin-bottom: 12px;
    }
    .action_icon {
      cursor: pointer;
    }
  }

  .comment_text,
  .name_text {
    font-size: 14px;
    color: #212121;

    .date_text {
      font-size: 14px;
      color: #9e9e9e;
    }
  }

  .image_preview_wrapper {
    display: flex;
    align-items: center;
    overflow-x: auto;
    margin-top: 20px;
    .image_box {
      height: 50px;
      width: 50px;
      min-width: 50px;
      margin-right: 15px;
      border-radius: 4px;
      .image_thumbnail {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 4px;
      }
    }
  }
}

//Dropdwon
.action_dropdown_container {
  .dropdown_wrapper {
    width: 125px;
    border-radius: 8px;
    box-shadow: 0 1px 4px 0.5px rgba(0, 0, 0, 0.15);
    background-color: $white;
    position: relative;

    .list_wrapper {
      .list_item {
        display: flex;
        align-items: center;
        font-size: 12px;
        font-weight: 500;
        color: $primary_main;
        padding: 16px;
        border-bottom: 1px solid #bdbdbd;
        cursor: pointer;
        &:last-child {
          border: none;
        }
        .icon {
          width: 18px;
          height: 18px;
          cursor: pointer;
          margin-right: 12px;
          &.edit_icon {
            background-image: url('/icons/icon-edit.svg');
          }
          &.delete_icon {
            background-image: url('/icons/icon-delete.svg');
          }
        }
      }
    }
  }
}
